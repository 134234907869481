import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { ClickableLinkPlugin } from "@lexical/react/LexicalClickableLinkPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import InlineImagePlugin from "./plugins/InlineImagePlugin";
import { InlineImageNode } from "./nodes/InlineImageNode";
import RestoreEditorStatePlugin from "./plugins/RestoreEditorStatePlugin";
import { ExtendedTextNode } from "./nodes/ExtendedTextNode";
import { $getRoot, LineBreakNode, TextNode } from "lexical";
import { TableContext } from "./plugins/TablePlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import TableCellResizerPlugin from "./plugins/TableCellResizer";
import { useEffect, useState } from "react";
import { CAN_USE_DOM } from "./utils/canUseDOM";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import DraggableBlockPlugin from "./plugins/DraggableBlockPlugin";
import TableActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import TableHoverActionsPlugin from "./plugins/TableHoverActionsPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import { ToolbarContext } from "./context/ToolbarContext";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import ImagesPlugin from "./plugins/ImagesPlugin";
import { ImageNode } from "./nodes/ImageNode";
import ShortcutsPlugin from "./plugins/ShortcutsPlugin";

function Placeholder({ text }) {
  return <div className="editor-placeholder">{text}</div>;
}

const editorConfig = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    console.error(`Lexical update error`, error);
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    ImageNode,
    InlineImageNode,
    HorizontalRuleNode,
    LineBreakNode,
    ExtendedTextNode,
    {
      replace: TextNode,
      with: (node) => new ExtendedTextNode(node.__text),
      withKlass: ExtendedTextNode,
    },
  ],
};

// TODO(hossein): Refactor this component into seperate type sage package
export const LexicalEditor = ({ value, onChange, placeholder = "" }) => {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState(false);

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener("resize", updateViewPortWidth);

    return () => {
      window.removeEventListener("resize", updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <TableContext>
        <ToolbarContext>
          <div className="editor-container">
            <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
            <div className="editor-inner">
              <RichTextPlugin
                contentEditable={
                  <div className="editor-scroller">
                    <div className="editor" ref={onRef}>
                      <ContentEditable className="editor-input" />
                    </div>
                  </div>
                }
                placeholder={<Placeholder text={placeholder} />}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <ClearEditorPlugin />
              <RestoreEditorStatePlugin
                state={value}
                onChange={(html) => onChange?.(html)}
              />
              <TablePlugin
                hasCellMerge={true}
                hasCellBackgroundColor={true}
                hasHorizontalScroll={true}
              />
              <TableCellResizerPlugin />
              <ImagesPlugin />
              <InlineImagePlugin />
              <HistoryPlugin />
              <AutoFocusPlugin />
              <CodeHighlightPlugin />
              <HorizontalRulePlugin />
              <ListPlugin />
              <LinkPlugin />
              <AutoLinkPlugin />
              <ClickableLinkPlugin />
              <ListMaxIndentLevelPlugin maxDepth={7} />
              <ShortcutsPlugin setIsLinkEditMode={setIsLinkEditMode} />
              <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
              {floatingAnchorElem && !isSmallWidthViewport && (
                <>
                  <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                  <FloatingLinkEditorPlugin
                    anchorElem={floatingAnchorElem}
                    isLinkEditMode={isLinkEditMode}
                    setIsLinkEditMode={setIsLinkEditMode}
                  />
                  <TableActionMenuPlugin
                    anchorElem={floatingAnchorElem}
                    cellMerge={true}
                  />
                  <TableHoverActionsPlugin anchorElem={floatingAnchorElem} />
                  <FloatingTextFormatToolbarPlugin
                    anchorElem={floatingAnchorElem}
                    setIsLinkEditMode={setIsLinkEditMode}
                  />
                </>
              )}
            </div>
          </div>
        </ToolbarContext>
      </TableContext>
    </LexicalComposer>
  );
};
