import {
  ComponentRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Flex, Form, Input, Switch } from "antd";
import { BannerUpload, DatePickerAntd, Select } from "ui";
import { FieldError } from "ui/field-error";
import { getRand } from "core/utils/common-utils";
import { getImageSrc } from "core/utils/json-utils";
import { toDate, toGregorian } from "core/utils/date-time";
import { useGetArticleCatsFlat } from "pages/articles/api";
import { useListAuthors } from "pages/authors/api";
import { articleForm } from "pages/articles/type";
import { addArticleFormSchema } from "pages/articles/schema";

interface ArticleFormProp {
  formData?: articleForm;
}
interface RefHandler {
  getFormData: () => articleForm;
  resetForm: () => void;
}
export type ArticleFormRef = ComponentRef<typeof ArticleForm>;

const ArticleForm = forwardRef<RefHandler, ArticleFormProp>(
  ({ formData }, ref) => {
    const [refreshKey, setRefreshKey] = useState("");

    const { data: authorsData, isPending: fetchingAuthors } = useListAuthors({
      page: 1,
      pageSize: 1000,
      inputParams: {
        filters: [],
        sort: {
          propertyName: "id",
          ascending: false,
        },
      },
    });
    const authorsOptions = useMemo(() => {
      if (authorsData) {
        return (authorsData?.data?.data ?? []).map((item) => ({
          label: item.fullName,
          value: item.id,
        }));
      }
      return [];
    }, [authorsData]);

    const { data: articleCatsData, isPending: fetchingArticleCats } =
      useGetArticleCatsFlat();
    const articleCatsOptions = useMemo(() => {
      if (articleCatsData) {
        return (articleCatsData?.data ?? []).map((item) => ({
          label: item.name,
          value: item.id,
        }));
      }
      return [];
    }, [articleCatsData]);

    const [form] = Form.useForm();
    const formik = useFormik<Partial<articleForm>>({
      initialValues: {
        id: formData?.id,
        title: formData?.title,
        subTitle: formData?.subTitle,
        summary: formData?.summary,
        content: formData?.content,
        timeToReadInMinutes: formData?.timeToReadInMinutes
          ? Number(formData.timeToReadInMinutes)
          : undefined,
        showComment: formData?.showComment ?? false,
        showLastArticles: formData?.showLastArticles ?? false,
        showRelatedProduct: formData?.showRelatedProduct ?? false,
        authorId: undefined,
        articleCategoryId: undefined,
        from: formData?.from,
        to: formData?.to,
        image: formData?.image,
        uploadImage: undefined,
        state: formData?.state,
      },
      validationSchema: addArticleFormSchema,
      enableReinitialize: true,
      onSubmit: () => {},
    });
    const { setFieldValue, values, errors, resetForm } = formik;

    useImperativeHandle(ref, () => ({
      getFormData() {
        return values as articleForm;
      },
      resetForm() {
        resetForm();
        setRefreshKey(`${getRand()}`);
        form.resetFields();
      },
    }));

    return (
      <FormikProvider value={formik}>
        <Form form={form}>
          <Form.Item name="uploadImage" valuePropName="uploadImage">
            <BannerUpload
              key={`article-${refreshKey}`}
              value={
                values.image?.url
                  ? {
                      width: values.image!.width,
                      height: values.image!.width,
                      preview: getImageSrc(values.image?.url, true),
                    }
                  : undefined
              }
              name="uploadImage"
              title="تصویر پیش نمایش مقاله"
              listType="picture-card"
              className="avatar-uploader"
              onBannerChange={(banner) => {
                setFieldValue("image", undefined);
                setFieldValue("uploadImage", banner);
              }}
            />
          </Form.Item>

          <Form.Item
            label="موضوع مقاله"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="title"
            rules={[
              {
                required: true,
                message: "لطفا نام مقاله را وارد کنید",
              },
            ]}
          >
            <Field name="title">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="لطفا تایپ کنید"
                  value={values.title}
                  onChange={(e) => setFieldValue("title", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="زیر عنوان"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="subTitle"
            rules={[
              {
                required: true,
                message: "لطفا زیر عنوان مقاله را وارد کنید",
              },
            ]}
          >
            <Field name="subTitle">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="لطفا تایپ کنید"
                  value={values.subTitle}
                  onChange={(e) => setFieldValue("subTitle", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="محتوا"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="content"
            rules={[
              {
                required: true,
                message: "لطفا محتوای مقاله را وارد کنید",
              },
            ]}
          >
            <Field name="content">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="لطفا تایپ کنید"
                  value={values.content}
                  onChange={(e) => setFieldValue("content", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="خلاصه"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="summary"
            rules={[
              {
                required: true,
                message: "لطفا خلاصه مقاله را وارد کنید",
              },
            ]}
          >
            <Field name="summary">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="لطفا تایپ کنید"
                  value={values.summary}
                  onChange={(e) => setFieldValue("summary", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          {/* <Form.Item
            label="لینک"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="summary"
            rules={[
              {
                required: true,
                message: "لطفا لینک مقاله را وارد کنید",
              },
            ]}
          >
            <Field name="summary">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="لطفا تایپ کنید"
                  value={values.summary}
                  onChange={(e) => setFieldValue("summary", e.target.value)}
                />
              )}
            </Field>
          </Form.Item> */}
          <Form.Item
            label="زمان مطالعه"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="timeToReadInMinutes"
            rules={[
              {
                required: true,
                message: "لطفا زمان مطالعه ی مقاله را وارد کنید",
              },
            ]}
          >
            <Field name="timeToReadInMinutes">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="زمان مطالعه را به دقیقه وارد کنید"
                  type="number"
                  min="0"
                  value={values.timeToReadInMinutes}
                  onChange={(e) =>
                    setFieldValue("timeToReadInMinutes", e.target.value)
                  }
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="دسته بندی مقاله"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="articleCategoryId"
          >
            <Field name="articleCategoryId">
              {({ field }: FieldProps) => (
                <Select
                  {...field}
                  placeholder="دسته بندی مقاله را انتخاب کنید  "
                  onChange={(e) => setFieldValue("articleCategoryId", e)}
                  value={values.articleCategoryId}
                  options={articleCatsOptions}
                  loading={fetchingArticleCats}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="نویسنده"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="authorId"
          >
            <Field name="authorId">
              {({ field }: FieldProps) => (
                <Select
                  {...field}
                  placeholder="نویسنده مقاله را انتخاب کنید  "
                  onChange={(e) => setFieldValue("authorId", e)}
                  value={values.authorId}
                  options={authorsOptions}
                  loading={fetchingAuthors}
                />
              )}
            </Field>
          </Form.Item>
          {/* <Form.Item
            name="url"
            label="لینک به محصول"
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="url">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="لینک صفحه ی محصول موردنظر را وارد نمایید"
                  value={values.url}
                  onChange={(e) => setFieldValue("url", e.target.value)}
                />
              )}
            </Field>
          </Form.Item> */}
          <Form.Item
            name={"date"}
            label="مهلت نشر"
            labelCol={{ span: 5 }}
            labelAlign="left"
            style={{ margin: 0 }}
          >
            <Flex gap={10}>
              <Form.Item name="from" className="w-100">
                <DatePickerAntd
                  size="lg"
                  label="از:"
                  name={`from-${refreshKey}`}
                  placeholder={toDate(values.from ?? "")}
                  onChange={(e: any) =>
                    setFieldValue("from", toGregorian(e?.format()))
                  }
                />
                <FieldError show={errors.from} text={errors.from} />
              </Form.Item>
              <Form.Item name="to" className="w-100">
                <DatePickerAntd
                  size="lg"
                  label="تا:"
                  name={`to-${refreshKey}`}
                  placeholder={toDate(values.to ?? "")}
                  onChange={(e: any) =>
                    setFieldValue("to", toGregorian(e?.format()))
                  }
                />
                <FieldError show={errors.to} text={errors.to} />
              </Form.Item>
            </Flex>
          </Form.Item>
          <Flex gap="10" justify="space-between">
            <Form.Item
              name={"showComment"}
              label="نمایش نظرات"
              colon={false}
              labelCol={{ span: 10 }}
              labelAlign="left"
              className="w-100"
            >
              <Field name="showComment">
                {({ field }: FieldProps) => (
                  <Switch
                    {...field}
                    checked={values.showComment}
                    onChange={(checked) =>
                      setFieldValue("showComment", checked)
                    }
                  />
                )}
              </Field>
            </Form.Item>
            <Form.Item
              name={"showRelatedProduct"}
              label={<label className="text-wrap">نمایش محصولات مرتبط</label>}
              colon={false}
              labelCol={{ span: 14 }}
              labelAlign="left"
              className="w-100"
            >
              <Field name="showRelatedProduct">
                {({ field }: FieldProps) => (
                  <Switch
                    {...field}
                    checked={values.showRelatedProduct}
                    onChange={(checked) =>
                      setFieldValue("showRelatedProduct", checked)
                    }
                  />
                )}
              </Field>
            </Form.Item>
          </Flex>
          <Form.Item
            name="showLastArticles"
            label={<label className="text-wrap">نمایش آخرین مطالب</label>}
            colon={false}
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="showLastArticles">
              {({ field }: FieldProps) => (
                <Switch
                  {...field}
                  checked={values.showLastArticles}
                  onChange={(checked) =>
                    setFieldValue("showLastArticles", checked)
                  }
                />
              )}
            </Field>
          </Form.Item>
        </Form>
      </FormikProvider>
    );
  }
);

export default ArticleForm;
