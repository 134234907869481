import { Avatar, Badge, Button, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useGetUserSettings } from "core/services/dashboard/useGetUserSettings";
import { IconMessage, IconFire } from "../../../icons";

import "./index.scss";
const { Text } = Typography;
const Header = () => {
  const { data: usersettings } = useGetUserSettings();

  const getAvatar = () => {
    const urlImage = usersettings?.image ?? "";
    if (urlImage.length > 0 && urlImage !== "string") {
      return urlImage.includes("http")
        ? urlImage
        : `${process.env.REACT_APP_BASE_URL}${urlImage}`;
    } else {
      return null;
    }
  };

  return (
    <div className="header">
      <div className="header__right">
        <div className="header__profile">
          <div>
            {getAvatar() ? (
              <Avatar src={getAvatar()} size={50} shape="square" />
            ) : (
              <Avatar
                shape="square"
                src={usersettings?.image}
                size={50}
                icon={<UserOutlined />}
              />
            )}
          </div>

          <div className="header__profile__user-info">
            <Text type="secondary">سلام</Text>
            <Text>
              {usersettings?.name} <span>{usersettings?.family}</span>{" "}
            </Text>
          </div>
        </div>

        <div className="header__links">
          <a href="#" className="header__links__button">
            <IconMessage color="#D6D7DD" size={20} />
            <Badge status="success" />
          </a>

          <a href="#" className="header__links__button">
            <IconFire color="#FF6600" size={20} />
            <Badge status="warning" />
          </a>
        </div>
      </div>

      <img
        className="header__logo"
        src="/assets/logo/logo-dashboard.svg"
        alt=""
      />
    </div>
  );
};

export default Header;
