import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { homeBannerForm } from "../../type";

const getPosition = (data: homeBannerForm) => {
  const p = data?.priority ?? 0;
  const l = data?.line ?? 0;
  switch (data?.positionId ?? 0) {
    case 1:
      return { line: 1, priority: 1 };
    case 2:
      return { line: 1, priority: 2 };
    case 3:
      return { line: 2, priority: 1 };
    case 4:
      return { line: 2, priority: 2 };
    default:
      return { line: l, priority: p };
  }
};

const addMagHomeBanner = async ({ data }: { data: homeBannerForm }) => {
  const position = getPosition(data);
  const formData = new FormData();
  formData.append("Priority", String(position?.priority ?? "0"));
  formData.append("Line", String(position?.line ?? "0"));
  formData.append("AltText", data.name ?? "");
  formData.append("Name", data.name ?? "");
  formData.append("Url", data.url ?? "");
  formData.append("IsActive", String(data.isActive ?? "false"));
  formData.append("Description", data?.description ?? "");
  if (data.uploadImage) {
    formData.append("Image.AltText", `${data.name ?? ""}-desktop-image`);
    formData.append("Image.Width", String(data.uploadImage.width));
    formData.append("Image.Height", String(data.uploadImage.height));
    formData.append("Image.SizePercentage", "100");
    formData.append("Image.File", data.uploadImage.file);
  }
  if (data.mobileUploadImage) {
    formData.append("MobileImage.AltText", `${data.name ?? ""}-mobile-image`);
    formData.append("MobileImage.Width", String(data.mobileUploadImage.width));
    formData.append(
      "MobileImage.Height",
      String(data.mobileUploadImage.height)
    );
    formData.append("MobileImage.SizePercentage", "100");
    formData.append("MobileImage.File", data.mobileUploadImage.file);
  }

  const res = await instance.post(MAG_HOME_END_POINTS.add_banner, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useAddMagHomeBanner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      MAG_HOME_END_POINTS.add_banner,
      MAG_HOME_END_POINTS.banners_list,
    ],
    mutationFn: addMagHomeBanner,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(`${e.response?.data.Message || "خطا در ایجاد بنر"}`);
    },
    onSuccess: () => {
      toast.success(`بنر با موفقیت افزوده شد`);
      queryClient.invalidateQueries({
        queryKey: [MAG_HOME_END_POINTS.banners_list],
      });
    },
  });
};
