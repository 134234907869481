import ReversedRadiusDivider from "components/common/ReversedRadiusDivider";
import { ActionBar } from "./actionbar";
import ArticleLinesTable from "./table";
import ArticleLinesModal from "./modal";

import styles from "./index.module.scss";
const ArticleLinesTab = () => {
  return (
    <>
      <ReversedRadiusDivider />
      <div className={styles.article_lines_container}>
        <ActionBar className="pt-10" />
        <ArticleLinesTable />
        <ArticleLinesModal />
      </div>
    </>
  );
};

export default ArticleLinesTab;
