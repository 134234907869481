import { useMemo } from "react";
import { Flex, Table } from "antd";
import { BrandAvatar, CopyButton, StatusLabel, ActionButton } from "ui";
import { toDate, toDateTime } from "core/utils/date-time";
import { getImageSrc } from "core/utils/json-utils";
import { useAppStore } from "store";
import { useMagHomeManagementStore } from "pages/mag-home-management/store";
import {
  useDeleteMagHomeBanner,
  useGetMagHomeBanners,
} from "pages/mag-home-management/api";
import {
  MAG_HOME_BANNERS_COLUMN,
  MAG_HOME_SLIDERS_COLUMN,
} from "pages/mag-home-management/constant";
import { articleBanner } from "pages/articles/type";
import { homeBanner } from "pages/mag-home-management/type";

const BannersTable = () => {
  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setFormData, setIsEdit } = useMagHomeManagementStore(
    (state) => state
  );

  const { data, isPending } = useGetMagHomeBanners();
  const { mutate: deleteBannerMutation } = useDeleteMagHomeBanner();

  const getPositionLabel = (item: homeBanner) => {
    const p = item?.priority ?? 0;
    const l = item?.line ?? 0;
    switch (l) {
      case 1:
        return p === 1 ? "بالا سمت راست" : "بالا سمت چپ";
      case 2:
        return p === 1 ? "پایین سمت راست" : "پایین سمت چپ";
      default:
        return `خط ${l} -- اولویت ${p}`;
    }
  };

  const dataSource = useMemo(() => {
    return (
      (data?.data ?? []).map((item: homeBanner) => {
        return {
          key: item.id,
          image: (
            <BrandAvatar
              img={getImageSrc(item.image?.url, true)}
              label={item.name}
            />
          ),
          title: (
            <Flex vertical align="start" gap="5px">
              <strong className="color-primary">{item?.name ?? "-"}</strong>
              <label className="color-stroke">
                تاریخ ثبت: {toDateTime(new Date().toISOString())}
              </label>
            </Flex>
          ),
          priority: (
            <span className="color-stroke text-center">
              {getPositionLabel(item)}
            </span>
          ),
          status: (
            <Flex align="center" justify="center">
              <StatusLabel status={item.isActive} />
            </Flex>
          ),
          link: (
            <Flex justify="center">
              <CopyButton value={item.url ?? ""} />
            </Flex>
          ),
          action: (
            <Flex gap={8} justify="center">
              <ActionButton
                type="remove"
                onClick={() => deleteBannerMutation(item.id!)}
              />
              <ActionButton
                type="edit"
                onClick={() => {
                  setFormData(item);
                  setIsEdit(true);
                  setModalVisibility("MAG_HOME_PAGE_BANNER_MODAL");
                }}
              />
            </Flex>
          ),
        };
      }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={MAG_HOME_BANNERS_COLUMN}
      dataSource={dataSource}
      loading={isPending}
      pagination={false}
    />
  );
};

export default BannersTable;
