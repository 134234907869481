import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance2 from "core/constants/request";
import { SimpleResponseDTO } from "interface";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import { articleSection } from "pages/articles/type";

const getSections = async (
  query: QueryKey
): Promise<SimpleResponseDTO<articleSection[]>> => {
  const [{ articleId }] = query as QueryType[];

  const res = await instance2.get(
    `${ARTICLE_END_POINTS.list_sections}?articleId=${articleId}`
  );

  return res.data;
};

type QueryType = { articleId: number };
type UseGetAdsOptions = UseQueryOptions<
  SimpleResponseDTO<articleSection[]>,
  QueryType,
  SimpleResponseDTO<articleSection[]>
>;
export const useGetSections = (config?: UseGetAdsOptions) => {
  return useQuery({
    queryKey: [ARTICLE_END_POINTS.list_sections],
    queryFn: ({ queryKey }) => getSections(queryKey),
    ...config,
  });
};
