import { ComponentRef, forwardRef, useImperativeHandle, useMemo } from "react";
import {
  Field,
  FieldProps,
  FormikErrors,
  FormikProvider,
  useFormik,
} from "formik";
import { Form, Select, Input, Spin } from "antd";
import InfiniteSelect from "ui/infinite-select";
import { FieldError } from "ui/field-error";
import { useGetProductCategories } from "core/services/product-category/useGetProductCategories";
import { useSearchProducts } from "core/services/product/useSearchProducts";
import { useGetStoreProducts } from "core/services/product/useGetStoreProducts";
import { TEMPLATE_TIME_RANGES } from "core/constants/statics";
import { bestOfferType } from "../../type";
import { addBestOfferSchema, updateBestOfferSchema } from "../../schema";

interface BestOfferFormProp {
  formData?: bestOfferType;
}
interface RefHandler {
  getFormData: () => {
    values: bestOfferType;
    validation: Promise<FormikErrors<bestOfferType>>;
  };
  resetForm: () => void;
}
export type BestOfferFormRef = ComponentRef<typeof BestOfferForm>;

const BestOfferForm = forwardRef<RefHandler, BestOfferFormProp>(
  ({ formData }, ref) => {
    const [form] = Form.useForm();

    const isEdit = formData != undefined;

    const formik = useFormik({
      initialValues: {
        id: formData?.id,
        title: formData?.title,
        categoriesPage: 1,
        categoriesSearchPhrase: "",
        productsPage: 1,
        productsSearchPhrase: "",
        productCategory: undefined,
        productId: undefined,
        storeProductId: undefined,
        storeId: undefined,
        discountTime: undefined,
        priority: formData?.priority,
      },
      enableReinitialize: true,
      validationSchema: isEdit ? updateBestOfferSchema : addBestOfferSchema,
      onSubmit: () => {},
    });
    const { setFieldValue, values, errors, resetForm, validateForm } = formik;

    const { data: productCats, isPending: fetchingCats } =
      useGetProductCategories({
        queryKey: [
          {
            page: values.categoriesPage,
            search: values.categoriesSearchPhrase,
          },
        ],
      });
    const categoriesOptions = useMemo(
      () =>
        (productCats?.data?.data ?? []).map((item) => ({
          label: item.name,
          value: item.id,
        })),
      [productCats]
    );

    const { data: products, isPending: fetchingProducts } = useSearchProducts({
      queryKey: [
        {
          productCategoryId: values.productCategory,
          keyword: values.productsSearchPhrase,
          page: values.productsPage,
        },
      ],
    });
    const productsOptions = useMemo(
      () =>
        (products?.items ?? []).map((item: any) => ({
          label: item.name,
          value: item.id,
        })),
      [products]
    );

    const { data: storeProducts, isLoading: fetchingStoreProducts } =
      useGetStoreProducts({
        queryKey: [{ productId: values.productId }],
        enabled: values.productId != undefined,
      });

    const storeProductsOptions = useMemo(
      () =>
        (storeProducts ?? []).map((item: any) => ({
          label: item.store,
          value: item.id,
        })),
      [storeProducts]
    );

    useImperativeHandle(ref, () => ({
      getFormData() {
        return {
          validation: validateForm(),
          values,
        };
      },
      resetForm() {
        resetForm();
        form.resetFields();
      },
    }));

    return (
      <FormikProvider value={formik}>
        <Form form={form} layout="vertical">
          {isEdit ? (
            <></>
          ) : (
            <>
              <Form.Item
                name="productCategory"
                label=": انتخاب دسته بندی"
                layout="vertical"
              >
                <Field name="productCategory">
                  {({ field }: FieldProps) => (
                    <InfiniteSelect
                      args={{ ...field }}
                      placeholder="لطفا دسته بندی را انتخاب کنید"
                      onChange={(value) => {
                        setFieldValue("productsPage", 1);
                        setFieldValue("productCategory", value?.value);
                      }}
                      options={categoriesOptions}
                      onInputChange={(phrase) => {
                        setFieldValue("categoriesSearchPhrase", phrase);
                      }}
                      isLoading={fetchingCats}
                      onPageChange={(page) =>
                        setFieldValue("categoriesPage", page)
                      }
                      page={values.categoriesPage}
                      isClearable={true}
                    />
                  )}
                </Field>
              </Form.Item>
              <Form.Item
                name="productId"
                label=": نام محصول "
                layout="vertical"
              >
                <Field name="productId">
                  {({ field }: FieldProps) => (
                    <InfiniteSelect
                      args={{ ...field }}
                      placeholder="لطفا یک محصول را انتخاب نمایید"
                      onChange={(value) => {
                        setFieldValue("storeProductId", undefined);
                        setFieldValue("title", value?.label);
                        setFieldValue("productId", value?.value);
                      }}
                      options={productsOptions}
                      onInputChange={(phrase) => {
                        setFieldValue("productsSearchPhrase", phrase);
                      }}
                      isLoading={fetchingProducts}
                      onPageChange={(page) =>
                        setFieldValue("productsPage", page)
                      }
                      page={values.productsPage}
                      isClearable={true}
                    />
                  )}
                </Field>
                <FieldError show={errors.productId} text={errors.productId} />
              </Form.Item>
              <Form.Item label=": فروشگاه" layout="vertical">
                <Field name="storeProductId">
                  {({ field }: FieldProps) => (
                    <Select
                      {...field}
                      placeholder="   لطفا فروشگاه را انتخاب کنید "
                      onChange={(value) => {
                        const storeObj = (storeProducts ?? []).filter(
                          (i: any) => Number(i.id) === Number(value)
                        )?.[0];
                        setFieldValue("storeId", storeObj?.storeId);
                        setFieldValue("storeProductId", value);
                      }}
                      options={storeProductsOptions}
                      notFoundContent={
                        <center className="py-4">
                          {fetchingStoreProducts ? (
                            <Spin />
                          ) : (
                            <strong className="mx-auto">
                              محصولی که انتخاب کرده اید فروشگاه ندارد
                            </strong>
                          )}
                        </center>
                      }
                    />
                  )}
                </Field>
                <FieldError
                  show={errors.storeProductId}
                  text={errors.storeProductId}
                />
              </Form.Item>
            </>
          )}
          <Form.Item label=": مدت زمان تخفیف" layout="vertical">
            <Field name="discountTime">
              {({ field }: FieldProps) => (
                <Select
                  {...field}
                  placeholder="لطفا انتخاب نمایید"
                  onChange={(value) => setFieldValue("discountTime", value)}
                  options={TEMPLATE_TIME_RANGES}
                />
              )}
            </Field>
            <FieldError show={errors.discountTime} text={errors.discountTime} />
          </Form.Item>
          <Form.Item name="priority" label="اولویت نمایش">
            <Field name="priority">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  value={values.priority}
                  type="number"
                  min="0"
                  placeholder="اولویت را وارد کنید  "
                  onChange={(e) => setFieldValue("priority", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
        </Form>
      </FormikProvider>
    );
  }
);

export default BestOfferForm;
