import { useMemo, Suspense } from "react";
import {
  createBrowserRouter,
  RouterProvider as ReactRouterProvider,
} from "react-router-dom";
import ProtectedRoute from "core/utils/protected-route";

import {
  DashboardLayout,
  NotFoundPage,
  SpinnerFullPage,
  RoleManagement,
  UserManagement,
  Settings,
  StoresManagementTable,
  AuthPage,
  Login,
  ForgetPassword,
  ProductTable,
  SeoDetail,
  AddProduct,
  Landing,
  AddLanding,
  ProductCategoryTable,
  AddProductCategory,
  ChangePassword,
  OtpCodeVerify,
  AdvertiseManagement,
  PrivacyManagement,
  TermsManagement,
  AboutUsManagement,
  SEOManagement,
  OrdersManagement,
  OrderDetails,
  OrderEdit,
  VendeesManagement,
  CustomersCarts,
  OverView,
  SpecsList,
  ArticleManagement,
  ArticleSections,
  MagHomeManagement,
  SiteHomeManagement,
  RedirectsManagement,
} from "./components";
import { VendeesDetails } from "pages/orders/vendees-details/components";
import { BrandManagement } from "pages/section-management/brand-management/components";

export const RouterProvider = () => {
  const routes = useMemo(() => {
    return [
      {
        path: "/",
        errorElement: <NotFoundPage />,
        element: (
          <ProtectedRoute>
            <Suspense fallback={<SpinnerFullPage />}>
              <DashboardLayout children={undefined} usersettings={undefined} />
            </Suspense>
          </ProtectedRoute>
        ),
        children: [
          {
            path: "dashboard",
            errorElement: <NotFoundPage />,
            children: [
              {
                path: "overview",
                element: <OverView />,
              },
              { path: "settings", element: <Settings /> },
              { path: "role_management", element: <RoleManagement /> },
              { path: "user_management", element: <UserManagement /> },
            ],
          },
          {
            path: "articles",
            errorElement: <NotFoundPage />,
            children: [
              {
                path: "articles_management",
                element: <ArticleManagement />,
              },
              {
                path: ":id/article_sections",
                element: <ArticleSections />,
              },
              {
                path: "mag_home_management",
                element: <MagHomeManagement />,
              },
            ],
          },
          {
            path: "stores",
            errorElement: <NotFoundPage />,
            children: [
              {
                path: "stores_management",
                element: <StoresManagementTable />,
              },
              {
                path: "requests",
              },
            ],
          },
          {
            path: "section_management",
            errorElement: <NotFoundPage />,
            children: [
              {
                path: "brand_management",
                element: <BrandManagement />,
              },
              {
                path: "site_home_management",
                element: <SiteHomeManagement />,
              },
              {
                path: "advertise_management",
                element: <AdvertiseManagement />,
              },
              {
                path: "privacy_management",
                element: <PrivacyManagement />,
              },
              {
                path: "terms_management",
                element: <TermsManagement />,
              },
              {
                path: "about_us_management",
                element: <AboutUsManagement />,
              },
              {
                path: "seo_management",
                element: <SEOManagement />,
              },
            ],
          },
          {
            path: "catalog",
            errorElement: <NotFoundPage />,
            children: [
              {
                path: "product/:id/edit",
                element: <AddProduct />,
              },
              {
                path: "product",
                element: <ProductTable />,

                children: [
                  {
                    path: "seo_details",
                    element: <SeoDetail />,
                  },
                ],
              },
              {
                path: "landing/:id/edit",
                element: <AddLanding />,
              },
              {
                path: "landing",
                element: <Landing />,
              },
              {
                path: "product_category",
                element: <ProductCategoryTable />,
              },
              {
                path: "product_category/:id/edit",
                element: <AddProductCategory />,
              },
              {
                path: "specs",
                element: <SpecsList />,
              },
              {
                path: "redirects_management",
                element: <RedirectsManagement />,
              },
            ],
          },
          {
            path: "orders",
            children: [
              {
                path: "orders_management",
                element: <OrdersManagement />,
              },
              {
                path: "orders_management/:id",
                element: <OrderDetails />,
              },
              {
                path: "orders_management/:id/edit",
                element: <OrderEdit />,
              },
              {
                path: "vendees_management",
                element: <VendeesManagement />,
              },
              {
                path: "vendees_management/:id",
                element: <VendeesDetails />,
              },
              {
                path: "customers_carts",
                element: <CustomersCarts />,
              },
            ],
          },
        ],
      },
      {
        path: "/auth",
        element: (
          <Suspense fallback={<SpinnerFullPage />}>
            <AuthPage />
          </Suspense>
        ),
        children: [
          { path: "/auth", element: <Login /> },
          {
            path: "forget-password",
            element: <ForgetPassword />,
          },
          {
            path: "change-password",
            element: <ChangePassword />,
          },
          {
            path: "otp-vertification",
            element: <OtpCodeVerify />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ];
  }, []);

  const element = createBrowserRouter(routes, {
    basename: process.env.PUBLIC_URL,
  });
  return <ReactRouterProvider router={element} />;
};
