import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance from "core/constants/request";
import { RequestDTO } from "interface";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import { articleManagementFilter } from "../../type";

type articlesRequestType = RequestDTO<articleManagementFilter>;
const getArticles = async (
  articlesRequestType: articlesRequestType
): Promise<any> => {
  const res = await instance.post(ARTICLE_END_POINTS.list, articlesRequestType);

  return res.data;
};

type UseGetArticlesOptions = UseQueryOptions<any, unknown, any>;
export const useGetArticles = (
  params: articlesRequestType,
  config?: UseGetArticlesOptions
) => {
  return useQuery({
    queryKey: [
      ARTICLE_END_POINTS.list,
      params.page,
      params.pageSize,
      ...(params.inputParams.filters ? params.inputParams.filters : []),
    ],
    queryFn: () => getArticles(params),
    ...config,
  });
};
