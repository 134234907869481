import { useParams } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { Field, FormikProvider, useFormik } from "formik";
import ReversedRadiusDivider from "components/common/ReversedRadiusDivider";
import { useGetArticleSeo } from "pages/articles/api/articles/useGetArticleSeo";
import { useUpdateArticleSeo } from "pages/articles/api/articles/useUpdateArticleSeo";

import styles from "./index.module.scss";
const SeoTab = () => {
  const { id: articleId } = useParams();

  const { mutate, isPending } = useUpdateArticleSeo();
  const { data } = useGetArticleSeo({ queryKey: [{ id: articleId }] });

  const [form] = Form.useForm();
  const formik = useFormik({
    initialValues: {
      articleId,
      tags: (data?.tags ?? []).join(","),
      keyWords: (data?.keyWords ?? []).join(","),
      description: data?.description ?? "",
      url: data?.url ?? "",
    },
    enableReinitialize: true,
    onSubmit: (values) => mutate(values),
  });
  const { setFieldValue, values, submitForm, resetForm } = formik;

  return (
    <div className={styles.home_seo_container}>
      <ReversedRadiusDivider />
      <div className={styles.home_seo_card}>
        <h3>سئوی مقاله</h3>
        <FormikProvider value={formik}>
          <Form className={styles.seo_form} form={form}>
            <Form.Item
              name="url"
              label="لینک"
              labelCol={{ span: 3 }}
              labelAlign="left"
              wrapperCol={{ span: 22 }}
            >
              <Field name="url">
                {({ field }) => (
                  <Input
                    {...field}
                    placeholder="لطفا بنویسید"
                    className={styles.common_textarea}
                    value={values.url}
                    onChange={(e) => setFieldValue("url", e.target.value)}
                  />
                )}
              </Field>
            </Form.Item>
            <Form.Item
              name="description"
              label="توضیحات متا"
              labelCol={{ span: 3 }}
              labelAlign="left"
              wrapperCol={{ span: 22 }}
            >
              <Field name="description">
                {({ field }) => (
                  <Input.TextArea
                    {...field}
                    placeholder="لطفا بنویسید"
                    autoSize={{ minRows: 5, maxRows: 7 }}
                    className={styles.common_textarea}
                    value={values.description}
                    onChange={(e) =>
                      setFieldValue("description", e.target.value)
                    }
                  />
                )}
              </Field>
            </Form.Item>
            <Form.Item
              name="keyWords"
              label="کلیدواژه ها"
              labelCol={{ span: 3 }}
              labelAlign="left"
              wrapperCol={{ span: 22 }}
            >
              <Field name="keyWords">
                {({ field }) => (
                  <Input.TextArea
                    {...field}
                    placeholder="کلیدواژه ها را با ، جدا کنید (برای نمونه: کلید اول ، کلید دوم ، ...)"
                    autoSize={{ minRows: 5, maxRows: 7 }}
                    className={styles.common_textarea}
                    value={values.keyWords}
                    onChange={(e) => setFieldValue("keyWords", e.target.value)}
                  />
                )}
              </Field>
            </Form.Item>
            <Form.Item
              name="tags"
              label="متاتایتل ها"
              labelCol={{ span: 3 }}
              labelAlign="left"
              wrapperCol={{ span: 22 }}
            >
              <Field name="tags">
                {({ field }) => (
                  <Input.TextArea
                    {...field}
                    placeholder="متاتایتل ها را با ، جدا کنید (برای نمونه: کلید اول ، کلید دوم ، ...)"
                    autoSize={{ minRows: 5, maxRows: 7 }}
                    className={styles.common_textarea}
                    value={values.tags}
                    onChange={(e) => setFieldValue("tags", e.target.value)}
                  />
                )}
              </Field>
            </Form.Item>
          </Form>
        </FormikProvider>
      </div>
      <div className={styles.action_footer}>
        <Button onClick={() => resetForm()}>لغو کردن</Button>
        <Button type="primary" onClick={() => submitForm()} loading={isPending}>
          ذخیره تغییرات
        </Button>
      </div>
    </div>
  );
};

export default SeoTab;
