import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance from "core/constants/request";
import { SimpleResponseDTO } from "interface";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";

type KV = { id: number; name: string };
type UseGetArticleCatsFlatOptions = UseQueryOptions<
  unknown,
  unknown,
  SimpleResponseDTO<KV[]>
>;

const getArticleCatsFlat = async (): Promise<SimpleResponseDTO<KV[]>> => {
  const res = await instance.get(ARTICLE_END_POINTS.list_article_cats_flat);

  return res.data;
};

export const useGetArticleCatsFlat = (
  config?: UseGetArticleCatsFlatOptions
) => {
  return useQuery({
    queryKey: [ARTICLE_END_POINTS.list_article_cats_flat],
    queryFn: getArticleCatsFlat,
    ...config,
  });
};
