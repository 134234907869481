import ReversedRadiusDivider from "components/common/ReversedRadiusDivider";
import { ActionBar } from "./actionbar";
import CategoryLinesTable from "./table";
import CategoryLinesModal from "./modal";

import styles from "./index.module.scss";
const CategoryLinesTab = () => {
  return (
    <>
      <ReversedRadiusDivider />
      <div className={styles.mag_home_category_lines_container}>
        <ActionBar className="pt-10" />
        <CategoryLinesTable />
        <CategoryLinesModal />
      </div>
    </>
  );
};

export default CategoryLinesTab;
