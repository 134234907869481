import { Card, Flex, Button } from "antd";
import { useParams } from "react-router-dom";
// @ts-ignore
import ReactHtmlParser from "react-html-parser";
import { IconEditPencil } from "components/icons/iconEditPencil";
import { IconEye } from "components/icons/iconEye";
import { IconDelete } from "components/icons/iconDelete";
import { getImageSrc } from "core/utils/json-utils";
import { useAppStore } from "store";
import { useSectionsStore } from "pages/articles/store";
import { useDeleteSection, useGetSections } from "pages/articles/api";
import { articleForm, articleSection } from "pages/articles/type";

import styles from "./index.module.scss";
const SectionsTable = () => {
  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setFormData, setIsEdit } = useSectionsStore((state) => state);

  const { id } = useParams();
  const { data } = useGetSections({ queryKey: [{ articleId: id }] });
  const { mutate: deleteSection, isPending } = useDeleteSection();

  return (
    <Card bordered={false} className={styles.sections_table_container}>
      <strong>لیست فهرست و توضیحات یا سر تیتر ها</strong>
      <div className="mt-5">
        {(data?.data ?? []).map((item: articleSection, index: number) => (
          <div>
            <div className={styles.section_info}>
              <div>
                <span className="mx-4">{item?.title ?? "-"}</span>
                <span className={styles.section_key}>{index + 1}</span>
              </div>
              <div
                className={styles.section_delete}
                onClick={() =>
                  deleteSection({ id: Number(item.id), articleId: Number(id) })
                }
              >
                <IconDelete color="#FF6600" />
              </div>
            </div>
            <div className={styles.section_des}>
              <img src={getImageSrc(item?.image?.url, true)} alt="Image" />
              <p className="mx-3">
                {ReactHtmlParser(item?.content || item?.subTitle || "-")}
              </p>
              <Flex gap={8} className={styles.desc_actions}>
                <Button>
                  <IconEye />{" "}
                  <span className="color-primary2">مشاهده محتوا</span>
                </Button>

                <Button
                  className={styles.section_des_edit}
                  onClick={() => {
                    setFormData(item as articleForm);
                    setIsEdit(true);
                    setModalVisibility("ARTICLE_SECTION_MODAL");
                  }}
                >
                  <IconEditPencil color="#FF6600" />
                </Button>
              </Flex>
            </div>
          </div>
        ))}
        {(data?.data ?? []).length === 0 && (
          <h1 className="color-stroke text-center mt-3 mb-4">
            هیچ آیتمی به این مقاله اضافه نشده
          </h1>
        )}
      </div>
    </Card>
  );
};

export default SectionsTable;
