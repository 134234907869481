import { toast } from "react-toastify";
import { AxiosError } from "axios";
import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { articleBanner } from "pages/articles/type";

const addAd = async ({ data }: { data: articleBanner }) => {
  const formData = new FormData();
  formData.append("Priority", String(data.priority ?? "0"));
  formData.append("AltText", data.name ?? "");
  formData.append("Name", data.name ?? "");
  formData.append("Url", data.url ?? "");
  formData.append("Description", data.description ?? "");
  formData.append("From", data.from ?? "");
  formData.append("To", data.to ?? "");
  formData.append("LineOrder", String(data.lineOrder ?? "0"));
  formData.append("ArticleId", String(data.articleId ?? "0"));
  formData.append("IsActive", String(data.isActive ?? false));
  if (data.uploadImage) {
    formData.append("Image.AltText", `${data.name ?? ""}-desktop-image`);
    formData.append("Image.Width", String(data.uploadImage.width));
    formData.append("Image.Height", String(data.uploadImage.height));
    formData.append("Image.SizePercentage", "100");
    formData.append("Image.File", data.uploadImage.file ?? "");
  }
  if (data.mobileUploadImage) {
    formData.append("MobileImage.AltText", `${data.name ?? ""}-mobile-image`);
    formData.append("MobileImage.Width", String(data.mobileUploadImage.width));
    formData.append(
      "MobileImage.Height",
      String(data.mobileUploadImage.height)
    );
    formData.append("MobileImage.SizePercentage", "100");
    formData.append("MobileImage.File", data.mobileUploadImage.file ?? "");
  }

  const res = await instance.post(ARTICLE_END_POINTS.add_banner, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useAddAd = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      ARTICLE_END_POINTS.add_banner,
      ARTICLE_END_POINTS.list_banners,
    ],
    mutationFn: addAd,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(`${e.response?.data.Message || "خطا در ایجاد بنر مقاله"}`);
    },
    onSuccess: () => {
      toast.success(`بنر مقاله با موفقیت افزوده شد`);
      queryClient.invalidateQueries([
        ARTICLE_END_POINTS.list_banners,
      ] as InvalidateQueryFilters);
    },
  });
};
