import ReversedRadiusDivider from "components/common/ReversedRadiusDivider";
import { ActionBar } from "./actionbar";
import VideosTable from "./table";
import VideosModal from "./modal";

import styles from "./index.module.scss";
const VideosTab = () => {
  return (
    <>
      <ReversedRadiusDivider />
      <div className={styles.mag_home_videos_container}>
        <ActionBar className="pt-10" />
        <VideosTable />
        <VideosModal />
      </div>
    </>
  );
};

export default VideosTab;
