import ReversedRadiusDivider from "components/common/ReversedRadiusDivider";
import { ActionBar } from "./actionbar";
import BannersTable from "./table";
import BannersModal from "./modal";

import styles from "./index.module.scss";
const BannersTab = () => {
  return (
    <>
      <ReversedRadiusDivider />
      <div className={styles.mag_home_banners_container}>
        <ActionBar className="pt-10" />
        <BannersTable />
        <BannersModal />
      </div>
    </>
  );
};

export default BannersTab;
