import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { PRODUCT_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ProductSearchQuery } from "pages/catalog/AddProduct/type";

type QueryOptionsWithoutQueryFn = Omit<UseQueryOptions<any>, "queryFn">;

export const useSearchProducts = (options?: QueryOptionsWithoutQueryFn) =>
  useQuery<any>({
    retry: 0,
    ...(options ?? {}),
    queryFn: async ({ queryKey }) => {
      const [, { keyword, productCategoryId, brandId, page, pageSize }] =
        queryKey as ProductSearchQuery[];

      let queries = "?";
      if (keyword) queries += `keyword=${keyword}&`;
      if (productCategoryId)
        queries += `productCategoryId=${productCategoryId}&`;
      if (brandId) queries += `brandId=${brandId}&`;
      if (page) queries += `page=${page}&`;

      queries += `pageSize=${pageSize || 20}&`;

      const result = await instance.get(
        `${PRODUCT_ENDPOINTS.search}${queries}`
      );

      return result.data?.data ?? {};
    },
    queryKey: options?.queryKey
      ? [PRODUCT_ENDPOINTS.search, ...options.queryKey]
      : [PRODUCT_ENDPOINTS.search],
  });
