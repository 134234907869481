import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { articleLineForm } from "../../type";

const addArticleLine = async ({ data }: { data: articleLineForm }) => {
  const res = await instance.post(MAG_HOME_END_POINTS.add_article_line, data);

  return res.data;
};

export const useAddArticleLine = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      MAG_HOME_END_POINTS.add_article_line,
      MAG_HOME_END_POINTS.article_lines_list,
    ],
    mutationFn: addArticleLine,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(`${e.response?.data.Message || "خطا در ایجاد لاین مقاله"}`);
    },
    onSuccess: () => {
      toast.success(`لاین مقاله با موفقیت افزوده شد`);
      queryClient.invalidateQueries({
        queryKey: [MAG_HOME_END_POINTS.article_lines_list],
      });
    },
  });
};
