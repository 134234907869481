import { useMemo, useState } from "react";
import { Flex, Table } from "antd";
import { ActionButton, BrandAvatar } from "ui";
import { getImageSrc } from "core/utils/json-utils";
import { useAppStore } from "store";
import { useBestOfferStore } from "../../store";
import { useDeleteBestOffer, useListBestOffers } from "../../api";
import { BEST_OFFER_TABLE_COLUMN } from "../../constant";
import { bestOfferType } from "../../type";

const OffersTable = () => {
  const [page, setPage] = useState<number>(1);
  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setFormData } = useBestOfferStore((state) => state);

  const { data, isPending } = useListBestOffers({
    page: page,
    pageSize: 10,
    inputParams: {
      filters: [],
      sort: {
        propertyName: "id",
        ascending: false,
      },
    },
  });
  const { mutate: deleteSpecMutation } = useDeleteBestOffer();

  const dataSource = useMemo(() => {
    return (
      (data?.data?.data ?? []).map((item: bestOfferType, index: number) => {
        return {
          key: item.id,
          index: (
            <Flex gap={16} align="center" justify="center">
              <label className="color-primary">{index + 1}</label>
            </Flex>
          ),
          image: (
            <center>
              <BrandAvatar
                img={getImageSrc(item.image?.url, true)}
                label={item.title}
              />
            </center>
          ),
          title: (
            <strong className="color-stroke">{item?.title ?? "ندارد"}</strong>
          ),
          totalPrice: (
            <label className="color-primary">{item?.price ?? "-"}</label>
          ),
          finalPrice: (
            <label className="color-primary">
              {item?.priceWithDiscount ?? "-"}
            </label>
          ),
          discountTime: (
            <label className="color-primary">
              {item?.endDatePersian ?? "-"}
            </label>
          ),
          action: (
            <Flex gap={8} justify="center">
              <ActionButton
                type="edit"
                onClick={() => {
                  setFormData(item);
                  setModalVisibility("BEST_OFFER_MODAL");
                }}
              />
              <ActionButton
                type="remove"
                onClick={() => deleteSpecMutation({ id: item.id ?? 0 })}
              />
            </Flex>
          ),
        };
      }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={BEST_OFFER_TABLE_COLUMN}
      dataSource={dataSource}
      loading={isPending}
      pagination={{
        showSizeChanger: false,
        total: data?.data?.totals ?? 0,
        onChange: (page) => {
          setPage(page);
        },
      }}
    />
  );
};

export default OffersTable;
