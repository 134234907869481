import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance from "core/constants/request";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";

const getArticleLines = async (): Promise<any> => {
  const res = await instance.get(MAG_HOME_END_POINTS.article_lines_list);

  return res.data;
};

type optionsType = UseQueryOptions<any, unknown, any>;
export const useGetArticleLines = (config?: optionsType) => {
  return useQuery({
    queryKey: [MAG_HOME_END_POINTS.article_lines_list],
    queryFn: getArticleLines,
    ...config,
  });
};
