import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";

type QueryOptionsWithoutQueryFn = Omit<UseQueryOptions<any>, "queryFn">;
type QueryType = { id: number };

export const useGetArticleDetails = (options?: QueryOptionsWithoutQueryFn) =>
  useQuery<any>({
    retry: 0,
    ...(options ?? {}),
    queryFn: async ({ queryKey }) => {
      const [, { id } = { id: 0 }] = queryKey as QueryType[];

      const result = await instance.get(`${ARTICLE_END_POINTS.get}?id=${id}`);

      return result.data?.data ?? {};
    },
    queryKey: options?.queryKey
      ? [ARTICLE_END_POINTS.get, ...options.queryKey]
      : [ARTICLE_END_POINTS.get],
  });
