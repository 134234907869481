import { useRef } from "react";
import { Button } from "antd";
import { Modal } from "ui";
import { useMagHomeManagementStore } from "pages/mag-home-management/store";
import {
  useAddArticleLine,
  useUpdateArticleLine,
} from "pages/mag-home-management/api";
import ArticleLineForm, { ArticleLineFormRef } from "../form";

const ArticleLinesModal = () => {
  const { setFormData, formData, isEdit } = useMagHomeManagementStore(
    (state) => state
  );
  const updateArticleLineQuery = useUpdateArticleLine();
  const addArticleLineQuery = useAddArticleLine();
  const loading = isEdit
    ? updateArticleLineQuery.isPending
    : addArticleLineQuery.isPending;

  const formRef = useRef<ArticleLineFormRef>(null);

  const handleOk = (onSuccess: () => void) => {
    const values = formRef.current?.getFormData();
    setFormData(values);

    if (isEdit) {
      updateArticleLineQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    } else {
      addArticleLineQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    }
  };

  return (
    <Modal
      name="MAG_HOME_PAGE_ARTICLE_LINE_MODAL"
      title={
        isEdit ? "ویرایش لاین مقاله صفحه اصلی" : "ایجاد لاین مقاله صفحه اصلی"
      }
      okButtonProps={{
        loading: loading,
      }}
      onClose={() => {
        setFormData(undefined);
        formRef.current?.resetForm();
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {isEdit ? "ویرایش" : "ثبت"}
          </Button>
        </>
      )}
    >
      <ArticleLineForm ref={formRef} formData={formData} />
    </Modal>
  );
};
export default ArticleLinesModal;
