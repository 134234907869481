import { useMemo } from "react";
import { Flex, Table } from "antd";
import { BrandAvatar, CopyButton, StatusLabel, ActionButton } from "ui";
import { toDate, toDateTime } from "core/utils/date-time";
import { getImageSrc } from "core/utils/json-utils";
import { useAppStore } from "store";
import { useMagHomeManagementStore } from "pages/mag-home-management/store";
import {
  useDeleteMagHomeSlider,
  useGetMagHomeSliders,
} from "pages/mag-home-management/api";
import { MAG_HOME_SLIDERS_COLUMN } from "pages/mag-home-management/constant";
import { articleBanner } from "pages/articles/type";

const SlidersTable = () => {
  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setFormData, setIsEdit } = useMagHomeManagementStore(
    (state) => state
  );

  const { data, isPending } = useGetMagHomeSliders();
  const { mutate: deleteSliderMutation } = useDeleteMagHomeSlider();

  const dataSource = useMemo(() => {
    return (
      (data?.data ?? []).map((item: articleBanner) => {
        return {
          key: item.id,
          image: (
            <BrandAvatar
              img={getImageSrc(item.image?.url, true)}
              label={item.name}
            />
          ),
          title: (
            <Flex vertical align="start" gap="5px">
              <strong className="color-primary">{item?.name ?? "-"}</strong>
              <label className="color-stroke">
                تاریخ ثبت: {toDateTime(item?.from)}
              </label>
            </Flex>
          ),
          priority: (
            <strong className="color-primary text-center">
              {item?.priority ?? "0"}
            </strong>
          ),
          status: (
            <Flex align="center" justify="center">
              <StatusLabel status={item.isActive} />
            </Flex>
          ),
          link: (
            <Flex justify="center">
              <CopyButton value={item.url ?? ""} />
            </Flex>
          ),
          action: (
            <Flex gap={8} justify="center">
              <ActionButton
                type="remove"
                onClick={() => deleteSliderMutation(item.id!)}
              />
              <ActionButton
                type="edit"
                onClick={() => {
                  setFormData(item);
                  setIsEdit(true);
                  setModalVisibility("MAG_HOME_PAGE_SLIDER_MODAL");
                }}
              />
            </Flex>
          ),
        };
      }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={MAG_HOME_SLIDERS_COLUMN}
      dataSource={dataSource}
      loading={isPending}
      pagination={false}
    />
  );
};

export default SlidersTable;
