import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { BEST_OFFER_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { bestOfferFormType } from "../type";

const updateOffer = async ({ data }: { data: bestOfferFormType }) => {
  let endDate = new Date();
  endDate.setHours(endDate.getHours() + (data.discountTime ?? 0));
  const offerUpdate = {
    id: data.id,
    isActive: true,
    startDate: data.startDate,
    endDate: endDate,
    priority: data?.priority ?? 0,
  };

  const res = await instance.put(BEST_OFFER_END_POINTS.update, {
    ...offerUpdate,
  });

  return res.data;
};

export const useUpdateBestOffer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [BEST_OFFER_END_POINTS.update, BEST_OFFER_END_POINTS.list],
    mutationFn: updateOffer,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(
        `${e.response?.data.Message || "خطا در ویرایش پیشنهاد ویژه"}`
      );
    },
    onSuccess: () => {
      toast.success(`پیشنهاد ویژه با موفقیت ویرایش شد`);
      queryClient.invalidateQueries({
        queryKey: [BEST_OFFER_END_POINTS.list],
      });
    },
  });
};
