import { AlignType } from "rc-table/lib/interface";

export const BEST_OFFER_TABLE_COLUMN = [
  {
    title: "ردیف",
    dataIndex: "index",
    key: "index",
    align: "center" as AlignType,
    width: "5%",
  },
  {
    title: "تصویر محصول",
    dataIndex: "image",
    key: "image",
    align: "center" as AlignType,
    width: "10%",
  },
  {
    title: "نام محصول و دسته بندی",
    dataIndex: "title",
    key: "title",
    width: "20%",
  },
  {
    title: "مبلغ کل",
    dataIndex: "totalPrice",
    key: "totalPrice",
    align: "center" as AlignType,
    width: "10%",
  },
  {
    title: "مبلغ پس از تخفیف",
    dataIndex: "finalPrice",
    key: "finalPrice",
    align: "center" as AlignType,
    width: "10%",
  },
  {
    title: "مدت زمان تخفیف",
    dataIndex: "discountTime",
    key: "discountTime",
    align: "center" as AlignType,
    width: "10%",
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
    width: "5%",
  },
];
