import ReversedRadiusDivider from "components/common/ReversedRadiusDivider";
import { ActionBar } from "./actionbar";
import AdsTable from "./table";
import AdsModal from "./modal";

import styles from "./index.module.scss";
const AdsTab = () => {
  return (
    <>
      <ReversedRadiusDivider />
      <div className={styles["article_ads_container"]}>
        <ActionBar className="pt-10" />
        <AdsTable />
        <AdsModal />
      </div>
    </>
  );
};

export default AdsTab;
