import { AlignType } from "rc-table/lib/interface";

export const MAG_HOME_BANNER_POSITIONS = [
  { value: 1, label: "بالا سمت راست" },
  { value: 2, label: "بالا سمت چپ" },
  { value: 3, label: "پایین سمت راست" },
  { value: 4, label: "پایین سمت چپ" },
];

export const SLIDER_MANAGEMENT_COLUMN = [
  {
    title: "تصویر اسلایدر",
    dataIndex: "image",
    key: "image",
    align: "center" as AlignType,
  },
  {
    title: "نام اسلایدر و تاریخ ثبت",
    dataIndex: "title",
    key: "title",
    align: "center" as AlignType,
  },
  {
    title: "مهلت انتشار",
    dataIndex: "date",
    key: "date",
    align: "center" as AlignType,
  },
  {
    title: "حجم فایل",
    dataIndex: "size",
    key: "size",
    align: "center" as AlignType,
  },
  {
    title: "لینک",
    dataIndex: "link",
    key: "link",
    align: "center" as AlignType,
  },
  {
    title: "وضعیت",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
  },
];

export const MAG_HOME_SLIDERS_COLUMN = [
  {
    title: "تصویر اسلایدر",
    dataIndex: "image",
    key: "image",
    // align: "center" as AlignType,
    width: "25%",
  },
  {
    title: "موضوع اسلایدر و تاریخ ثبت",
    dataIndex: "title",
    key: "title",
    // align: "center" as AlignType,
    width: "30%",
  },
  {
    title: "لینک",
    dataIndex: "link",
    key: "link",
    align: "center" as AlignType,
    width: "25%",
  },
  {
    title: "فعال بودن",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "اولویت",
    dataIndex: "priority",
    key: "priority",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
    width: "20%",
  },
];

export const MAG_HOME_BANNERS_COLUMN = [
  {
    title: "تصویر بنر",
    dataIndex: "image",
    key: "image",
    // align: "center" as AlignType,
    width: "15%",
  },
  {
    title: "موضوع بنر و تاریخ ثبت",
    dataIndex: "title",
    key: "title",
    // align: "center" as AlignType,
    width: "30%",
  },
  {
    title: "جایگاه",
    dataIndex: "priority",
    key: "priority",
    align: "center" as AlignType,
    width: "25%",
  },
  {
    title: "لینک",
    dataIndex: "link",
    key: "link",
    align: "center" as AlignType,
    width: "15%",
  },
  {
    title: "فعال بودن",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
    width: "15%",
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
    width: "15%",
  },
];

export const MAG_HOME_VIDEOS_COLUMN = [
  {
    title: "ویدیو",
    dataIndex: "image",
    key: "image",
    align: "center" as AlignType,
    width: "15%",
  },
  {
    title: "عنوان ویدیو و تاریخ ثبت",
    dataIndex: "title",
    key: "title",
    // align: "center" as AlignType,
    width: "30%",
  },
  {
    title: "لینک",
    dataIndex: "link",
    key: "link",
    align: "center" as AlignType,
    width: "25%",
  },
  {
    title: "فعال بودن",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "اولویت",
    dataIndex: "priority",
    key: "priority",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
    width: "20%",
  },
];

export const MAG_HOME_PRODUCTS_COLUMN = [
  {
    title: "ردیف",
    dataIndex: "index",
    key: "index",
    align: "center" as AlignType,
    width: "10%",
  },
  {
    title: "نام و شناسه محصول",
    dataIndex: "title",
    key: "title",
    width: "35%",
  },
  {
    title: "اولویت",
    dataIndex: "priority",
    key: "priority",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "فعال بودن",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
    width: "20%",
  },
];

export const MAG_HOME_CATEGORY_LINES_COLUMN = [
  {
    title: "ردیف",
    dataIndex: "index",
    key: "index",
    align: "center" as AlignType,
    width: "10%",
  },
  {
    title: "نام لاین",
    dataIndex: "title",
    key: "title",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "دسته بندی",
    dataIndex: "category",
    key: "category",
    width: "20%",
  },
  {
    title: "لاین",
    dataIndex: "line",
    key: "line",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "اولویت",
    dataIndex: "priority",
    key: "priority",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "فعال بودن",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
    width: "20%",
  },
];

export const MAG_HOME_ARTICLE_LINES_COLUMN = [
  {
    title: "ردیف",
    dataIndex: "index",
    key: "index",
    align: "center" as AlignType,
    width: "10%",
  },
  {
    title: "نام لاین",
    dataIndex: "title",
    key: "title",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "لاین",
    dataIndex: "line",
    key: "line",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "اولویت",
    dataIndex: "priority",
    key: "priority",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "فعال بودن",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
    width: "20%",
  },
];
