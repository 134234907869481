export const IconUploadVideo = ({ size = 22, color = "#73d13d" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 22 22"
  >
    <g id="sr-upload" transform="translate(-1 -1)">
      <path
        id="Path_1374"
        data-name="Path 1374"
        d="M6.293,7.707a1,1,0,0,1,0-1.414l5-5a1,1,0,0,1,1.414,0l5,5a1,1,0,0,1-1.414,1.414L13,4.414V16a1,1,0,0,1-2,0V4.414L7.707,7.707A1,1,0,0,1,6.293,7.707Z"
        fill={color}
      />
      <path
        id="Path_1375"
        data-name="Path 1375"
        d="M2,15a1,1,0,0,1,1,1v4a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V16a1,1,0,0,1,2,0v4a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V16A1,1,0,0,1,2,15Z"
        fill={color}
      />
    </g>
  </svg>
);
