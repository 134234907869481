import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { articleForm } from "../../type";

const addArticle = async ({ data }: { data: articleForm }) => {
  const formData = new FormData();
  formData.append("AltText", "");
  formData.append("Title", data.title ?? "");
  formData.append("SubTitle", data.subTitle ?? "");
  formData.append("Content", String(data.content ?? ""));
  formData.append("Summary", String(data.summary ?? ""));
  formData.append("TimeToReadInMinutes", String(data.timeToReadInMinutes ?? 0));
  formData.append("State", String(data.state ?? 0));
  formData.append(
    "PublishedAt",
    String(data.publishedAt ?? new Date().toISOString())
  );
  formData.append("From", String(data.from ?? ""));
  formData.append("To", String(data.to ?? ""));
  formData.append("ShowComment", String(data.showComment ?? false));
  formData.append("ShowLastArticles", String(data.showLastArticles ?? false));
  formData.append(
    "ShowRelatedProduct",
    String(data.showRelatedProduct ?? false)
  );
  formData.append("AuthorId", String(data.authorId ?? 0));
  formData.append("ArticleCategoryId", String(data.articleCategoryId ?? 0));
  if (data?.image?.file) {
    formData.append("Thumbnail.Width", String(data.image?.width ?? 0));
    formData.append("Thumbnail.Height", String(data.image?.height ?? 0));
    formData.append("Thumbnail.SizePercentage", "100");
    formData.append("Thumbnail.Description", data?.image?.altText ?? "");
    formData.append("Thumbnail.File", data.image.file ?? "");

    formData.append("Width", String(data?.image?.width ?? 0));
    formData.append("Height", String(data?.image?.height ?? 0));
    formData.append("SizePercentage", "100");
    formData.append("File", data?.image?.file ?? "");
  }

  const res = await instance.post(ARTICLE_END_POINTS.add, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useAddArticle = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [ARTICLE_END_POINTS.add, ARTICLE_END_POINTS.list],
    mutationFn: addArticle,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(`${e.response?.data.Message || "خطا در ایجاد مقاله"}`);
    },
    onSuccess: () => {
      toast.success(`مقاله با موفقیت افزوده شد`);
      queryClient.invalidateQueries({
        queryKey: [ARTICLE_END_POINTS.list],
      });
    },
  });
};
