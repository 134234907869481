import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { BEST_OFFER_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { RequestDTO, ResponseDTO } from "interface";
import { bestOfferListFilter, bestOfferType } from "../type";

type offersRequestType = RequestDTO<bestOfferListFilter>;
const getOffers = async (
  articlesRequestType: offersRequestType
): Promise<ResponseDTO<bestOfferType[]>> => {
  const res = await instance.post(
    BEST_OFFER_END_POINTS.list,
    articlesRequestType
  );

  return res.data;
};

type UseGetOffersOptions = UseQueryOptions<
  ResponseDTO<bestOfferType[]>,
  unknown,
  ResponseDTO<bestOfferType[]>
>;
export const useListBestOffers = (
  params: offersRequestType,
  config?: UseGetOffersOptions
) => {
  return useQuery({
    queryKey: [
      BEST_OFFER_END_POINTS.list,
      params.page,
      params.pageSize,
      ...(params.inputParams.filters ? params.inputParams.filters : []),
    ],
    queryFn: () => getOffers(params),
    ...config,
  });
};
