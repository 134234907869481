import { Button, Form, Input, Modal, Select } from "antd";
import { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { Icon } from "ui";
import InfiniteSelect from "ui/infinite-select";
import { useAddProductCategory } from "core/services/product-category/useAddProductCategory";
import { useGetProductCategories } from "core/services/product-category/useGetProductCategories";
import styles from "./index.module.scss";

const AddProductCategoryModal = ({ onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [catsPage, setCatsPage] = useState(1);
  const [catsSearch, setCatsSearch] = useState("");

  const [form] = Form.useForm();

  const { mutate, isPending } = useAddProductCategory();

  const { data: cats, isPending: fetchingCats } = useGetProductCategories({
    queryKey: [
      {
        page: catsPage,
        search: catsSearch,
      },
    ],
  });

  const catsOptions = useMemo(
    () =>
      (cats?.data?.data ?? []).map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [cats]
  );

  useEffect(() => {
    form.resetFields();
  }, [isModalOpen]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    const { name } = form.getFieldsValue();

    mutate(
      {
        parentId,
        name,
        featuredTitle: name,
        description: "",
        externalLink: "",
        content: "",
        tagIds: [],
      },
      {
        onSuccess: (res) => {
          toast.success("دسته بندی با موفقیت ایجاد گردید");
          handleCancel();
        },
      }
    );
  };

  const handleCancel = () => {
    setParentId(null);
    setCatsPage(1);
    setCatsSearch("");
    setIsModalOpen(false);
    onClose?.();
  };

  return (
    <>
      <Button onClick={showModal} className={styles["add_category_button"]}>
        <Icon type="sr-plus" />
        <span>افزودن دسته بندی</span>
      </Button>
      <Modal
        title={"افزودن دسته بندی"}
        open={isModalOpen}
        okButtonProps={{
          loading: isPending,
        }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={650}
        footer={() => (
          <>
            <Button onClick={handleCancel}>لغو</Button>
            <Button loading={isPending} type="primary" onClick={handleOk}>
              {"افزودن"}
            </Button>
          </>
        )}
      >
        <Form className={styles["add_category_form"]} form={form}>
          <Form.Item
            name="name"
            label=":نام دسته بندی "
            labelCol={{ span: 6 }}
            labelAlign="left"
            wrapperCol={{ span: 18 }}
          >
            <Input className={styles["add_category_name_modal"]} />
          </Form.Item>
          <Form.Item
            name="parentId"
            label=" :دسته والد "
            labelCol={{ span: 6 }}
            labelAlign="left"
            wrapperCol={{ span: 18 }}
          >
            <InfiniteSelect
              placeholder="   لطفا انتخاب کنید  "
              onChange={(value) => setParentId(value?.value)}
              options={catsOptions}
              onInputChange={(phrase) => {
                setCatsSearch(phrase);
              }}
              isLoading={fetchingCats}
              onPageChange={(newPage) => {
                setCatsPage(newPage);
              }}
              page={catsPage}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddProductCategoryModal;
