import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { IconHome, IconDocument, IconBook } from "components/icons";
import { Icon } from "ui";

import styles from "../index.module.scss";
interface sidebarItems {
  key: string;
  label: ReactNode;
  children?: sidebarItems[];
  icon?: ReactNode;
}
export const SIDEBAR_ITEMS: sidebarItems[] = [
  {
    key: "dashboard",
    label: "دشبورد",
    children: [
      {
        key: "/dashboard/overview",
        label: <Link to={"/dashboard/overview"}>نمای کلی</Link>,
      },
      {
        key: "/dashboard/settings",
        label: <Link to={"/dashboard/settings"}>تنظیمات حساب کاربری</Link>,
      },
      {
        key: "/dashboard/user_management",
        label: <Link to={"/dashboard/user_management"}>مدیریت کاربران</Link>,
      },
      {
        key: "/dashboard/role_management",
        label: <Link to={"/dashboard/role_management"}>مدیریت نقش ها</Link>,
      },
    ],
    icon: <IconHome size={16} color="inherit" />,
  },
  {
    key: "section_management",
    label: "مدیریت بخش ها",
    icon: <IconDocument size={16} color="inherit" />,
    children: [
      {
        key: "/section_management/site_home_management",
        label: (
          <Link to={"/section_management/site_home_management"}>
            مدیریت صفحه اصلی
          </Link>
        ),
      },
      {
        key: "/section_management/brand_management",
        label: (
          <Link to={"/section_management/brand_management"}>مدیریت برندها</Link>
        ),
      },
      {
        key: "/section_management/advertise_management",
        label: (
          <Link to={"/section_management/advertise_management"}>
            مدیریت تبلیغات
          </Link>
        ),
      },
      {
        key: "/section_management/privacy_management",
        label: (
          <Link to={"/section_management/privacy_management"}>
            مدیریت حریم خصوصی
          </Link>
        ),
      },
      {
        key: "/section_management/terms_management",
        label: (
          <Link to={"/section_management/terms_management"}>
            مدیریت قوانین سایت
          </Link>
        ),
      },
      {
        key: "/section_management/about_us_management",
        label: (
          <Link to={"/section_management/about_us_management"}>
            مدیریت بخش درباره ما
          </Link>
        ),
      },
      {
        key: "/section_management/seo_management",
        label: (
          <Link to={"/section_management/seo_management"}>مدیریت سئو</Link>
        ),
      },
    ],
  },
  {
    key: "catalog",
    label: "کاتالوگ",
    children: [
      {
        key: "/catalog/product",
        label: <Link to={"/catalog/product"}>محصولات</Link>,
      },
      {
        key: "/catalog/landing",
        label: <Link to={"/catalog/landing"}>لندینگ</Link>,
      },
      {
        key: "/catalog/product_category",
        label: <Link to={"/catalog/product_category"}>دسته بندی محصولات</Link>,
      },
      {
        key: "/catalog/specs",
        label: <Link to={"/catalog/specs"}>لیست ویژگی ها</Link>,
      },
      {
        key: "/catalog/redirects_management",
        label: (
          <Link to={"/catalog/redirects_management"}>مدیریت ریدایرکت ها</Link>
        ),
      },
    ],
    icon: <IconBook size={16} color="inherit" />,
  },
  {
    key: "articles",
    label: "مقالات",
    children: [
      {
        key: "/articles",
        label: <Link to={"/articles/articles_management"}>مدیریت مقالات</Link>,
      },
      {
        key: "/mag-home",
        label: <Link to={"/articles/mag_home_management"}>صفحه اصلی مگ</Link>,
      },
    ],
    icon: <IconBook size={16} color="inherit" />,
  },
  {
    key: "orders",
    label: "سفارشات",
    icon: <Icon type="sr-cart-basket" />,
    children: [
      {
        key: "/orders/orders_management",
        label: <Link to={"/orders/orders_management"}>مدیریت سفارشات</Link>,
      },
      {
        key: "/orders/vendees_management",
        label: <Link to={"/orders/vendees_management"}>مدیریت خریداران</Link>,
      },
      {
        key: "/orders/customers_carts",
        label: <Link to={"/orders/customers_carts"}>سبد خرید مشتریان</Link>,
      },
    ],
  },
  {
    key: "stores",
    label: "فروشگاه ها",
    children: [
      {
        key: "/stores/stores_management",
        label: <Link to={"/stores/stores_management"}>مدیریت فروشگاه ها</Link>,
      },
      {
        key: "/stores/requests",
        label: <Link to={"/stores/requests"}>درخواست ها</Link>,
      },
    ],
    icon: <IconBook size={16} color="inherit" />,
  },
].map((item) => {
  if (item.children) {
    return {
      ...item,
      children: item.children.map((item) => {
        return {
          ...item,
          label: (
            <>
              <Icon
                type="sr-circle-fill"
                className={styles.menu_submenu_item_circle}
              />
              {item.label}
            </>
          ),
        };
      }),
    };
  } else return item;
});
