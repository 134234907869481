import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { homeProductForm } from "../../type";

const updateMagHomeHomeProduct = async ({
  data,
}: {
  data: homeProductForm;
}) => {
  const formData = new FormData();
  formData.append("Id", String(data.id ?? 0));
  formData.append("ProductId", String(data.productId ?? 0));
  formData.append("Priority", String(data.priority ?? 0));
  formData.append("IsActive", String(data.isActive ?? "false"));

  const res = await instance.put(
    MAG_HOME_END_POINTS.update_home_product,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  return res.data;
};

export const useUpdateHomeProduct = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      MAG_HOME_END_POINTS.update_home_product,
      MAG_HOME_END_POINTS.home_products_list,
    ],
    mutationFn: updateMagHomeHomeProduct,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(
        `${e.response?.data.Message || "خطا در ویرایش محصول صفحه اصلی"}`
      );
    },
    onSuccess: () => {
      toast.success(`محصول صفحه اصلی با موفقیت ویرایش شد`);
      queryClient.invalidateQueries({
        queryKey: [MAG_HOME_END_POINTS.home_products_list],
      });
    },
  });
};
