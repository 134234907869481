import { ComponentRef, forwardRef, useImperativeHandle, useState } from "react";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Flex, Form, Input, Select, Switch, Upload } from "antd";
import { DatePickerAntd, BannerUpload } from "ui";
import { getRand } from "core/utils/common-utils";
import { getImageSrc } from "core/utils/json-utils";
import { homeVideoForm } from "pages/mag-home-management/type";
import { IconDelete } from "components/icons/iconDelete";
import { IconUploadVideo } from "components/icons/iconUploadVideo";

import styles from "./index.module.scss";
import { toast } from "react-toastify";
import { RcFile } from "antd/es/upload";
import { useDeleteMagHomeVideo } from "pages/mag-home-management/api";

interface VideoFormProp {
  formData?: homeVideoForm;
}
interface RefHandler {
  getFormData: () => homeVideoForm;
  resetForm: () => void;
}
export type VideoFormRef = ComponentRef<typeof VideoForm>;

const VideoForm = forwardRef<RefHandler, VideoFormProp>(({ formData }, ref) => {
  const { mutate: deleteVideo } = useDeleteMagHomeVideo();

  const [form] = Form.useForm();
  const formik = useFormik<homeVideoForm>({
    initialValues: {
      id: formData?.id,
      videoMediaId: formData?.videoMediaId,
      name: formData?.name,
      url: formData?.url,
      video: formData?.video,
      uploadVideo: undefined,
      description: formData?.description,
      isActive: formData?.isActive,
      priority: formData?.priority ? Number(formData.priority) : undefined,
      line: formData?.line,
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });
  const { setFieldValue, values, resetForm } = formik;

  useImperativeHandle(ref, () => ({
    getFormData() {
      return values;
    },
    resetForm() {
      resetForm();
      form.resetFields();
    },
  }));

  // const hasUploadedVideos = (data ?? []).length > 0;
  const hasUploadedVideos = formData?.video != undefined;

  const onUpload = (file: RcFile) => {
    const videoTag = document.createElement("video");
    const url = URL.createObjectURL(file);
    videoTag.src = url;

    videoTag.addEventListener("loadedmetadata", function () {
      const selectedVideo = {
        width: videoTag.videoWidth,
        height: videoTag.videoHeight,
        file: file,
        uid: file.uid,
      };
      setFieldValue("uploadVideo", selectedVideo);
    });

    return true;
  };

  const openVideo = (event: React.MouseEvent<HTMLVideoElement>) => {
    event.preventDefault();
    if ((formData?.video?.url ?? "").length > 0) {
      window.open(
        `${process.env.REACT_APP_BASE_URL}${formData?.video?.url ?? "/"}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      toast.error("این ویدیو لینک ندارد");
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form form={form}>
        <Form.Item
          name="uploadImage"
          valuePropName="uploadImage"
          label="انتخاب ویدیو"
          labelCol={{ span: 5 }}
          labelAlign="left"
        >
          {hasUploadedVideos ? (
            <div className={styles.video_cell}>
              <div
                className={styles.delete_video_icon}
                onClick={() => deleteVideo(0)}
              >
                <IconDelete color="#B71C1C" />
              </div>
              <video onClick={(e) => openVideo(e)}>
                <source
                  src={`${process.env.REACT_APP_BASE_URL}${formData?.video?.url}`}
                />
              </video>
            </div>
          ) : (
            <div className={styles.video_upload_container}>
              <Upload
                className={styles.upload_box}
                showUploadList={true}
                multiple={false}
                accept="video/*"
                beforeUpload={(file, _) => onUpload(file)}
                customRequest={(ops) => {
                  ops.onSuccess?.(<></>);
                }}
              >
                <div className={styles.video_upload_info}>
                  <div>
                    <IconUploadVideo />
                  </div>
                  <div className={styles.video_upload_info_detail}>
                    <div>کلیک کنید و فایل خود را آپلود نمایید</div>
                    <div>نمی تواند بیشتر از یک فایل باشد</div>
                  </div>
                </div>
              </Upload>
              <p>لطفا ویدیو به صورت لنداسکیپ افقی و با کیفیت 720 آپلود شود</p>
            </div>
          )}
        </Form.Item>

        <Form.Item
          label="عنوان ویدیو"
          labelCol={{ span: 5 }}
          labelAlign="left"
          name="name"
          rules={[
            {
              required: true,
              message: "لطفا نام ویدیو را وارد کنید",
            },
          ]}
        >
          <Field name="name">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                placeholder="لطفا تایپ کنید"
                value={values.name}
                onChange={(e) => setFieldValue("name", e.target.value)}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="اولویت ویدیو"
          labelCol={{ span: 5 }}
          labelAlign="left"
          name="priority"
        >
          <Field name="priority">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                type="number"
                min="0"
                placeholder="اولویت را وارد کنید  "
                onChange={(e) =>
                  setFieldValue("priority", Number(e.target.value))
                }
                value={Number(values.priority ?? "0")}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          name="url"
          label="لینک ویدیو"
          labelCol={{ span: 5 }}
          labelAlign="left"
        >
          <Field name="url">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                placeholder="لینک ویدیو را وارد نمایید"
                value={values.url}
                onChange={(e) => setFieldValue("url", e.target.value)}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          name="description"
          label="توضیحات ویدیو"
          labelCol={{ span: 5 }}
          labelAlign="left"
        >
          <Field name="description">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                placeholder="توضیحات ویدیو را وارد نمایید"
                value={values.description}
                onChange={(e) => setFieldValue("description", e.target.value)}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          name="isActive"
          label={<label>وضعیت</label>}
          labelCol={{ span: 5 }}
          labelAlign="left"
        >
          <Field name="isActive">
            {({ field }: FieldProps) => (
              <Switch
                {...field}
                checked={values.isActive}
                onChange={(checked) => setFieldValue("isActive", checked)}
              />
            )}
          </Field>
        </Form.Item>
      </Form>
    </FormikProvider>
  );
});

export default VideoForm;
