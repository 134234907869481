import React from "react";
import { Form, Flex } from "antd";
import { Button, Icon, SearchInput } from "ui";
import { useAppStore } from "store";
import { useMagHomeManagementStore } from "pages/mag-home-management/store";

interface FormValues {
  search: string;
}
interface ArticleLinesActionBar {
  className?: string;
}

export const ActionBar: React.FC<ArticleLinesActionBar> = ({ className }) => {
  const { setModalVisibility } = useAppStore((state) => state);
  const { setFilter, setFormData, setIsEdit } = useMagHomeManagementStore(
    (state) => state
  );
  const [form] = Form.useForm<FormValues>();
  const onValuesChangeHandler = (
    changedValues: FormValues,
    allValues: FormValues
  ) => {};
  return (
    <div className={`${className} box w-100`}>
      <strong>لیست لاین مقاله های صفحه اصلی</strong>
      <Flex gap={10} justify="space-between" className="mt-6">
        <Form
          form={form}
          className="w-100"
          onValuesChange={onValuesChangeHandler}
        >
          <Flex>
            <Form.Item name={"name"}>
              <SearchInput size="large" placeholder="جستجو " />
            </Form.Item>
          </Flex>
        </Form>
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setFormData(undefined);
            setIsEdit(false);
            setModalVisibility("MAG_HOME_PAGE_ARTICLE_LINE_MODAL");
          }}
        >
          <Icon type="sr-plus" />
          <span>لاین مقاله جدید </span>
        </Button>
      </Flex>
    </div>
  );
};
