// @ts-nocheck
import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { BEST_OFFER_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { bestOfferFormType } from "../type";

const addOffer = async ({ data }: { data: bestOfferFormType }) => {
  let endDate = new Date();
  endDate.setHours(endDate.getHours() + (data.discountTime ?? 0));
  const offer = {
    productId: data.productId ?? 0,
    storeId: data.storeId ?? 0,
    isActive: true,
    startDate: new Date().toISOString(),
    endDate: endDate.toISOString(),
    priority: data.priority ?? 0,
  };
  const res = await instance.post(BEST_OFFER_END_POINTS.create, {
    ...offer,
  });

  // Upload selected image for newly created offer
  let uploadImagePost = null;
  if (res.status === 200) {
    if (data?.selectedImage?.file) {
      const imageUploadData = new FormData();
      imageUploadData.append("Width", String(data.selectedImage.width ?? 0));
      imageUploadData.append("Height", String(data.selectedImage.height ?? 0));
      imageUploadData.append("Description", `تصویر آفر`);
      imageUploadData.append("Id", String(res?.data?.data ?? 0));
      imageUploadData.append("File", data.selectedImage?.file ?? "");
      uploadImagePost = await instance.post(
        BEST_OFFER_END_POINTS.upload_image,
        imageUploadData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
  }
  return { offer: res?.data, imageRes: uploadImagePost?.data };
};

export const useAddBestOffer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [BEST_OFFER_END_POINTS.create, BEST_OFFER_END_POINTS.list],
    mutationFn: addOffer,
    onSuccess: () => {
      toast.success("پیشنهاد ویژه با موفقیت ایجاد شد");
      queryClient.invalidateQueries([
        BEST_OFFER_END_POINTS.list,
      ] as InvalidateQueryFilters);
    },
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(
        `${e.response?.data?.Message || "خطا در ایجاد پیشنهاد ویژه"}`
      );
    },
  });
};
