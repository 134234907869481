export function getDOMRangeRect(
  nativeSelection: Selection,
  rootElement: HTMLElement
): DOMRect {
  let domRange = null;
  try {
    domRange = nativeSelection.getRangeAt(0);
  } catch (e) {
    console.error("nativeSelection.getRangeAt error", e);
  }

  let rect;

  if (nativeSelection.anchorNode === rootElement) {
    let inner = rootElement;
    while (inner.firstElementChild != null) {
      inner = inner.firstElementChild as HTMLElement;
    }
    rect = inner.getBoundingClientRect();
  } else if (
    domRange != null &&
    (domRange.startOffset > 0 || domRange.endOffset > 0)
  ) {
    // if selection was available, use its boundings as rect
    rect = domRange.getBoundingClientRect();
  } else {
    // if domRange was not available, calculate center of visible scroll as rect
    const { top, right, x, width } =
      document.scrollingElement!.getBoundingClientRect();

    const topOffset = 222;
    const calculatedTop =
      rootElement.getBoundingClientRect().top - top - topOffset;

    rect = {
      top: calculatedTop,
      right: 0,
      bottom: 0,
      left: right,
      width,
      height: 0,
      x,
      y: 0,
    } as DOMRect;
  }

  return rect;
}
