import { ConfigProvider, Tabs } from "antd";
import CategoryLinesTab from "./tabs/category-lines-tab";
import ArticleLinesTab from "./tabs/article-lines-tab";
import SlidersTab from "./tabs/sliders-tab";
import BannersTab from "./tabs/banners-tab";
import VideosTab from "./tabs/videos-tab";
import HomeProductsTab from "./tabs/products-tab";
import SeoTab from "./tabs/seo-tab";
import Header from "./header";

import styles from "./index.module.scss";

const items = [
  {
    key: "article_categories",
    label: "دسته بندی ها",
    children: <CategoryLinesTab />,
  },
  {
    key: "article_lines",
    label: "لاین ها",
    children: <ArticleLinesTab />,
  },
  {
    key: "home_sliders",
    label: "اسلایدر ها",
    children: <SlidersTab />,
  },
  {
    key: "home_banners",
    label: "بنر ها",
    children: <BannersTab />,
  },
  {
    key: "home_video",
    label: "ویدیو",
    children: <VideosTab />,
  },
  {
    key: "home_products",
    label: "محصولات",
    children: <HomeProductsTab />,
  },
  {
    key: "home_seo",
    label: "سئو",
    children: <SeoTab />,
  },
];

export const MagHomeManagement = () => {
  return (
    <div className={styles.mag_home_management_container}>
      <Header />
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerSplitColor: "transparent",
            },
          },
        }}
      >
        <Tabs
          defaultActiveKey="article_categories"
          items={items}
          className={styles.mag_home_tabs}
        />
      </ConfigProvider>
    </div>
  );
};
