import {
  InvalidateQueryFilters,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { toast } from "react-toastify";

type ApiResponse = {
  data: any;
};

type QueryOptionsWithoutMutationFn = Omit<
  UseMutationOptions<ApiResponse, AxiosError, any, unknown>,
  "mutationFn"
>;

export const useUpdateArticleSeo = (
  options?: QueryOptionsWithoutMutationFn
) => {
  const queryClient = useQueryClient();

  return useMutation<ApiResponse, AxiosError, any>({
    retry: 0,
    ...options,
    mutationFn: async (data: any) => {
      const postData = {
        ...data,
        keyWords: data.keyWords.split(","),
        tags: data.tags.split(","),
      };

      const result = await instance.put<ApiResponse>(
        ARTICLE_END_POINTS.update_article_seo,
        { ...postData }
      );

      return result.data;
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries([
        ARTICLE_END_POINTS.get_article_seo,
      ] as InvalidateQueryFilters);
      toast.success(`تغییرات با موفقیت ذخیره شدند`);
    },
    onError: (e: any) => {
      console.log(e);
      toast.error(`${e.response?.data?.Message || "خطا در اعمال تغییرات"}`);
    },
  });
};
