import {
  InvalidateQueryFilters,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";

type QueryOptionsWithoutMutationFn = Omit<
  UseMutationOptions<unknown, AxiosError<ErrorDTO>, number, unknown>,
  "mutationFn"
>;

export const useDeleteCategoryLine = (
  options?: QueryOptionsWithoutMutationFn
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError<ErrorDTO>, number>({
    retry: 0,
    ...options,
    mutationFn: async (id: number) => {
      const result = await instance.delete(
        `${MAG_HOME_END_POINTS.delete_category_line}?id=${id}`
      );

      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        MAG_HOME_END_POINTS.category_lines_list,
      ] as InvalidateQueryFilters);
      toast.success(`لاین دسته بندی با موفقیت حذف گردید`);
    },
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(
        `${e.response?.data?.Message || "خطا در حذف لاین دسته بندی"}`
      );
    },
  });
};
