import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance from "core/constants/request";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";

const getMagHomeBanners = async (): Promise<any> => {
  const res = await instance.get(MAG_HOME_END_POINTS.banners_list);

  return res.data;
};

type optionsType = UseQueryOptions<any, unknown, any>;
export const useGetMagHomeBanners = (config?: optionsType) => {
  return useQuery({
    queryKey: [MAG_HOME_END_POINTS.banners_list],
    queryFn: getMagHomeBanners,
    ...config,
  });
};
