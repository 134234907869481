import * as Yup from "yup";

export const addBestOfferSchema = Yup.object().shape({
  productId: Yup.number().required("لطفا محصول را انتخاب کنید"),
  storeProductId: Yup.number().required("لطفا فروشگاه را انتخاب کنید"),
  discountTime: Yup.string().required("لطفا مدت زمان تخفیف را وارد کنید"),
});

export const updateBestOfferSchema = Yup.object().shape({
  discountTime: Yup.string().required("لطفا مدت زمان تخفیف را وارد کنید"),
});
