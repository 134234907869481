import Header from "./header";
import ArticlesTab from "./tabs/articles-tab";
import styles from "./index.module.scss";

export const ArticleManagement = () => {
  return (
    <>
      <div className={styles.articles_container}>
        <Header />
        <div className="mt-5">
          <ArticlesTab />
        </div>
      </div>
    </>
  );
};
