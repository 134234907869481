import { create } from "zustand";
import { filter, Key } from "interface";
import { articleBanner, articleManagementFilter } from "../../type";

type AdsStore = {
  isEdit: boolean;
  formData?: Partial<articleBanner>;
  selectedArticlesId?: Key[];
  filter?: filter<articleManagementFilter>[];
  setIsEdit: (val: boolean) => void;
  setFormData: (formData?: articleBanner) => void;
  setFilter: (filter: filter<articleManagementFilter>[]) => void;
  setSelectedArticlesId: (value: Key[]) => void;
};

export const useAdsStore = create<AdsStore>((set) => {
  return {
    isEdit: false,
    setFormData: (value: Partial<articleBanner> | undefined) => {
      set({ formData: value });
    },
    headerInfo: {
      isActiveTotal: 0,
      totals: 0,
      totalSize: 0,
    },
    setFilter: (value: filter<articleManagementFilter>[]) => {
      set({ filter: value });
    },
    setSelectedArticlesId: (value: Key[]) => {
      set({ selectedArticlesId: value });
    },
    setIsEdit: (value: boolean) => {
      set({ isEdit: value });
    },
  };
});
