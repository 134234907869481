import { get } from "lodash";
import { create } from "zustand";

type modals =
  | "LANDING_INDEX_EDIT_MODAL"
  | "LANDING_SLIDER_UPLOAD_MODAL"
  | "LANDING_BANNER_MODAL"
  | "LANDING_PRODUCT_LINE_EDIT_MODAL"
  | "LANDING_SEO_URL_MODAL"
  | "PRODUCT_SPEC_EDIT_MODAL"
  | "PRODUCT_SEO_URL_MODAL"
  | "PRODUCT_CATEGORY_BANNER_MODAL"
  | "MENU_EDIT_MODAL"
  | "SPECS_MANAGEMENT_MODAL"
  | "USER_MANAGEMENT_MODAL"
  | "VENDEE_ADD_MODAL"
  | "ADVERTISE_MANAGEMENT_MODAL"
  | "MAIN_BANNERS_MANAGEMENT_MODAL"
  | "MAIN_SLIDERS_MANAGEMNT_MODAL"
  | "BRAND_MANAGEMENT_MODAL"
  | "ARTICLE_MANAGEMENT_MODAL"
  | "ARTICLE_SECTION_MODAL"
  | "ARTICLE_ADS_MODAL"
  | "BEST_OFFER_MODAL"
  | "MAG_HOME_PAGE_VIDEO_MODAL"
  | "MAG_HOME_PAGE_SLIDER_MODAL"
  | "MAG_HOME_PAGE_BANNER_MODAL"
  | "MAG_HOME_PAGE_PRODUCT_MODAL"
  | "MAG_HOME_PAGE_CATEGROY_LINE_MODAL"
  | "MAG_HOME_PAGE_ARTICLE_LINE_MODAL"
  | "SITE_HOME_PAGE_CATEGROY_LINE_MODAL"
  | "SITE_HOME_PAGE_CATEGROY_LINE_PRODUCTS_MODAL"
  | null;

type BreadcrumbId = { id: number; title: string };

type AppStore = {
  modalvisibilty: modals;
  setModalVisibility: (modalvisibilty: modals) => void;
  breadcrumbIds: BreadcrumbId[];
  addBreadcrumbId: (breadcrumb: BreadcrumbId) => void;
  reset: () => void;
};

const INITIAL_STATE = {
  modalvisibilty: null,
  breadcrumbIds: [],
};

export const useAppStore = create<AppStore>((set, get) => {
  return {
    ...INITIAL_STATE,
    setModalVisibility: (value) => {
      set({ modalvisibilty: value });
    },
    addBreadcrumbId: (idMap) => {
      const currentIds = get().breadcrumbIds;
      if (!currentIds.includes(idMap) && (idMap?.title ?? "").length > 0) {
        set({ breadcrumbIds: [...currentIds, idMap] });
      }
    },
    reset: () => {
      set({ ...INITIAL_STATE });
    },
  };
});
