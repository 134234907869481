import { useMemo } from "react";
import { Flex, Table } from "antd";
import { Link } from "react-router-dom";
import {
  BrandAvatar,
  CopyButton,
  StatusLabel,
  ActionButton,
  Button,
  Icon,
} from "ui";
import { toDate, toDateTime } from "core/utils/date-time";
import { getImageSrc } from "core/utils/json-utils";
import { useAppStore } from "store";
import { useArticleManagementStore } from "pages/articles/store";
import { useGetArticles } from "pages/articles/api";
import { article } from "pages/articles/type";
import { ARTICLES_TABLE_COLUMN } from "pages/articles/constant";

const ArticlesTable = () => {
  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setFormData, setIsEdit } = useArticleManagementStore(
    (state) => state
  );

  const { data, isPending } = useGetArticles({
    page: 1,
    pageSize: 10,
    inputParams: {
      filters: [],
      sort: {
        propertyName: "id",
        ascending: false,
      },
    },
  });

  const dataSource = useMemo(() => {
    return (
      (data?.data?.data ?? []).map((item: article) => {
        return {
          key: item.id,
          image: (
            <BrandAvatar
              img={getImageSrc(item.image?.url, true)}
              label={item.title}
            />
          ),
          title: (
            <Flex vertical align="start" gap="5px">
              <strong className="color-primary">{item?.title ?? "-"}</strong>
              <label className="color-stroke">
                تاریخ ثبت: {toDateTime(item?.createdAt)}
              </label>
            </Flex>
          ),
          date: (
            <Flex vertical gap="5px">
              <strong className="color-stroke">
                از تاریخ: {toDate(item?.from)}
              </strong>
              <strong className="color-stroke">
                تا تاریخ: {toDate(item?.to)}
              </strong>
            </Flex>
          ),
          description: (
            <Link to={`/articles/${item.id}/article_sections`}>
              <Button type="primary" size="middle" onClick={() => {}}>
                <Icon type="sr-plus" />
                <span>افزودن توضیحات </span>
              </Button>
            </Link>
          ),
          status: (
            <Flex align="center" justify="center">
              <StatusLabel status={item.state === 0} />
            </Flex>
          ),
          link: (
            <Flex justify="center">
              <CopyButton
                value={
                  item?.seo?.url
                    ? `${process.env.REACT_APP_PUBLISH_WEBSITE_BASE_URL}/mag/${item?.seo?.url}`
                    : undefined
                }
              />
            </Flex>
          ),
          action: (
            <Flex justify="center">
              <ActionButton
                type="edit"
                onClick={() => {
                  setFormData(item);
                  setIsEdit(true);
                  setModalVisibility("ARTICLE_MANAGEMENT_MODAL");
                }}
              />
            </Flex>
          ),
        };
      }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={ARTICLES_TABLE_COLUMN}
      dataSource={dataSource}
      loading={isPending}
      pagination={false}
    />
  );
};

export default ArticlesTable;
