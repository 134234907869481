import { ComponentRef, forwardRef, useImperativeHandle } from "react";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Flex, Form, Input, Switch } from "antd";
import { articleLineForm } from "pages/mag-home-management/type";

interface ArticleLineFormProp {
  formData?: articleLineForm;
}
interface RefHandler {
  getFormData: () => articleLineForm;
  resetForm: () => void;
}
export type ArticleLineFormRef = ComponentRef<typeof ArticleLineForm>;

const ArticleLineForm = forwardRef<RefHandler, ArticleLineFormProp>(
  ({ formData }, ref) => {
    const [form] = Form.useForm();
    const formik = useFormik({
      initialValues: {
        id: formData?.id,
        title: formData?.title,
        line: formData?.line,
        priority: formData?.priority,
        isActive: formData?.isActive,
      },
      enableReinitialize: true,
      onSubmit: () => {},
    });
    const { setFieldValue, values, resetForm } = formik;

    useImperativeHandle(ref, () => ({
      getFormData() {
        return values;
      },
      resetForm() {
        resetForm();
        form.resetFields();
      },
    }));

    return (
      <FormikProvider value={formik}>
        <Form form={form}>
          <Form.Item
            label="عنوان لاین"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="title"
          >
            <Field name="title">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="عنوان لاین را وارد کنید  "
                  onChange={(e) => setFieldValue("title", e.target.value)}
                  value={values.title}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="لاین"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="line"
          >
            <Field name="line">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  type="number"
                  min="0"
                  placeholder="لاین را وارد کنید  "
                  onChange={(e) => setFieldValue("line", e.target.value)}
                  value={values.line}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="اولویت نمایش"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="priority"
          >
            <Field name="priority">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  type="number"
                  min="0"
                  placeholder="اولویت را وارد کنید  "
                  onChange={(e) => setFieldValue("priority", e.target.value)}
                  value={values.priority}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="isActive"
            label={<label>وضعیت</label>}
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="isActive">
              {({ field }: FieldProps) => (
                <Switch
                  {...field}
                  checked={values.isActive}
                  onChange={(checked) => setFieldValue("isActive", checked)}
                />
              )}
            </Field>
          </Form.Item>
        </Form>
      </FormikProvider>
    );
  }
);

export default ArticleLineForm;
