import { useRef } from "react";
import { Button } from "antd";
import { Modal } from "ui";
import { isObjectEmpty } from "core/utils/json-utils";
import { useBestOfferStore } from "../../store";
import { useUpdateBestOffer } from "../../api";
import BestOfferForm, { BestOfferFormRef } from "../form";

const BestOfferModal = () => {
  const { setFormData, formData } = useBestOfferStore((state) => state);
  const { mutate, isPending } = useUpdateBestOffer();

  const formRef = useRef<BestOfferFormRef>(null);

  const handleOk = (onSuccess: () => void) => {
    if (!formRef.current) return;

    // Get validation and form values
    const { validation, values } = formRef.current.getFormData();
    validation.then((result) => {
      // If no errors, then data is valid
      if (isObjectEmpty(result)) {
        mutate(
          {
            data: values,
          },
          {
            onSuccess: () => {
              onSuccess();
            },
          }
        );
      }
    });
  };

  return (
    <Modal
      name="BEST_OFFER_MODAL"
      title={"ویرایش پیشنهاد ویژه"}
      okButtonProps={{
        loading: isPending,
      }}
      onClose={() => {
        setFormData(undefined);
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={isPending}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {"ویرایش"}
          </Button>
        </>
      )}
    >
      <BestOfferForm ref={formRef} formData={formData} />
    </Modal>
  );
};
export default BestOfferModal;
