import { Button, Flex, Input, Table } from "antd";
import { IconSearch } from "components/icons/iconSearch";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetProductCategories } from "core/services/product-category/useGetProductCategories";
import AddProductCategoryModal from "../AddProductCategoryModal";
import { IconDelete } from "components/icons/iconDelete";
import { getImageSrc } from "core/utils/json-utils";
import { BrandAvatar } from "ui";
import { useDeleteProductCategory } from "core/services/product-category/useDeleteProductCategory";
import { PRODUCT_CATEGORIES_ENDPOINTS } from "core/constants/endpoints";
import { IconEye } from "components/icons/iconEye";

import styles from "./index.module.scss";
const ProductCategoryTable = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const { data: productCategories, isPending } = useGetProductCategories({
    queryKey: [{ page, search }, PRODUCT_CATEGORIES_ENDPOINTS.list],
  });
  const { mutate: deleteProductCategory } = useDeleteProductCategory();

  const goToEditPage = (id) => {
    navigate(`/catalog/product_category/${id}/edit`);
  };

  const getColumns = useCallback(
    () => [
      {
        title: <div className={styles["column"]}>نام دسته بندی و ویژگی</div>,
        render: (data) => {
          return (
            <Flex align="center" gap="1.5rem">
              <div>
                <BrandAvatar
                  img={getImageSrc(data?.image?.url, true)}
                  label={data?.name}
                />
              </div>
              <div className={styles["category_name_info"]}>
                <div>{data.name}</div>
                <div>محصولات صنعتی</div>
              </div>
            </Flex>
          );
        },
      },
      {
        title: <div className={styles["column"]}>نام مستعار</div>,
        render: (data) => {
          return (
            <div className={styles["featured_title"]}>
              {data?.featuredTitle ?? "-"}
            </div>
          );
        },
      },
      {
        title: <div className={styles["column"]}>تعداد نمایش سفارش</div>,
        render: (data) => {
          const tmpNumber = data?.productCount ?? 0;

          return (
            <div
              className={
                tmpNumber < 10
                  ? styles["low_show_count"]
                  : styles["high_show_count"]
              }
            >
              <div>{tmpNumber}</div>
              <div>{tmpNumber < 10 ? "تعداد محدود" : "تعداد بالا"}</div>
            </div>
          );
        },
      },
      {
        title: <div className={styles["column"]}>لینک دسته بندی</div>,
        algin: "center",
        render: (data) => (
          <div className={styles["link_button"]}>
            <Button
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_PUBLISH_WEBSITE_BASE_URL}${data?.seoUrl}`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              disabled={(data?.seoUrl ?? "").length == 0}
            >
              <IconEye /> مشاهده
            </Button>
          </div>
        ),
      },
      {
        title: <div className={styles["column"]}> عملیات</div>,
        render: (data) => (
          <div className={styles["actions"]}>
            <Button onClick={() => goToEditPage(data.id)}>درج مشخصات</Button>
            <div
              className={styles["delete"]}
              onClick={() => deleteProductCategory({ id: data.id })}
            >
              <IconDelete color="#F5222D" />
            </div>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <div
      style={{
        marginBlock: 30,
      }}
    >
      <div className={styles["table_filter"]}>
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          addonBefore={<IconSearch />}
          placeholder="جستجوی دسته بندی"
        />

        <AddProductCategoryModal />
      </div>
      <Table
        locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
        className={styles["table"]}
        columns={getColumns()}
        dataSource={productCategories?.data?.data ?? []}
        loading={isPending}
        pagination={{
          showSizeChanger: false,
          total: productCategories?.data?.totals ?? 0,
          onChange: (page) => {
            setPage(page);
          },
        }}
      />
    </div>
  );
};

export default ProductCategoryTable;
