import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { homeBannerForm } from "../../type";

const updateMagHomeBanner = async ({ data }: { data: homeBannerForm }) => {
  const formData = new FormData();
  formData.append("Id", String(data.id ?? "0"));
  formData.append("Priority", String(data.priority ?? "0"));
  formData.append("Line", String(data?.line ?? "0"));
  formData.append("AltText", data.name ?? "");
  formData.append("Name", data.name ?? "");
  formData.append("Url", data.url ?? "");
  formData.append("IsActive", String(data.isActive ?? "false"));
  formData.append("Description", data?.description ?? "");

  const res = await instance.put(MAG_HOME_END_POINTS.update_banner, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useUpdateHomeBanner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      MAG_HOME_END_POINTS.update_banner,
      MAG_HOME_END_POINTS.banners_list,
    ],
    mutationFn: updateMagHomeBanner,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data.Message || "خطا در ویرایش بنر"}`);
    },
    onSuccess: () => {
      toast.success(`بنر با موفقیت ویرایش شد`);
      queryClient.invalidateQueries({
        queryKey: [MAG_HOME_END_POINTS.banners_list],
      });
    },
  });
};
