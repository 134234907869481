import React from "react";
import { getImageSrc } from "core/utils/json-utils";
import { BannerUpload } from "ui";
import { BannerImage } from "ui/banner-input";

import styles from "./index.module.scss";
type ImagePickerProps = {
  onChange?: (banner: BannerImage | undefined) => void;
  currentImage?: BannerImage;
};
const ImagePicker: React.FC<ImagePickerProps> = ({
  onChange,
  currentImage,
}) => {
  return (
    <div className={styles.best_offer_image_picker}>
      <strong className="mr-2">تصویر طراحی شده محصول</strong>
      <div className="uploader">
        <BannerUpload
          card
          name="image"
          value={
            currentImage
              ? {
                  ...currentImage,
                  preview: getImageSrc(
                    String(currentImage?.preview ?? ""),
                    true
                  ),
                }
              : undefined
          }
          listType="picture-card"
          className="avatar-uploader"
          onBannerChange={(banner) => onChange?.(banner)}
          hint={
            <div className={styles.info}>
              <p>تصویر پیش فرض محصول را تنظیم کنید.</p>
              <p>فقط فرمت های jpg, jpeg, png و webp</p>
              <p>پذیرفته می شوند</p>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ImagePicker;
