import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";

type QueryOptionsWithoutQueryFn = Omit<UseQueryOptions<any>, "queryFn">;

export const useGetMagHomeSeo = (options?: QueryOptionsWithoutQueryFn) =>
  useQuery<any>({
    retry: 0,
    ...(options ?? {}),
    queryFn: async () => {
      const result = await instance.get(`${MAG_HOME_END_POINTS.get_seo}`);

      return result.data?.data ?? {};
    },
    queryKey: options?.queryKey
      ? [MAG_HOME_END_POINTS.get_seo, ...options.queryKey]
      : [MAG_HOME_END_POINTS.get_seo],
  });
