import { create } from "zustand";
import { filter, Key } from "interface";
import { sliderForm, sliderManagementFilter, slidersInfo } from "../type";
type MagHomeManagementStore = {
  isEdit: boolean;
  formData?: any;
  headerInfo: slidersInfo;
  setHeaderInfo?: (info?: slidersInfo) => void;
  selectedMagHomesId?: Key[];
  filter?: filter<sliderManagementFilter>[];
  setIsEdit: (val: boolean) => void;
  setFormData: (formData?: any) => void;
  setFilter: (filter: filter<sliderManagementFilter>[]) => void;
  setSelectedMagHomesId: (value: Key[]) => void;
};

export const useMagHomeManagementStore = create<MagHomeManagementStore>(
  (set) => {
    return {
      isEdit: false,
      setFormData: (value: any | undefined) => {
        set({ formData: value });
      },
      headerInfo: {
        isActiveTotal: 0,
        totals: 0,
        totalSize: 0,
      },
      setHeaderInfo: (info: slidersInfo | undefined) => {
        set({ headerInfo: info });
      },
      setFilter: (value: filter<sliderManagementFilter>[]) => {
        set({ filter: value });
      },
      setSelectedMagHomesId: (value: Key[]) => {
        set({ selectedMagHomesId: value });
      },
      setIsEdit: (value: boolean) => {
        set({ isEdit: value });
      },
    };
  }
);
