import ReversedRadiusDivider from "components/common/ReversedRadiusDivider";
import { ActionBar } from "./actionbar";
import SlidersTable from "./table";
import SlidersModal from "./modal";

import styles from "./index.module.scss";
const SlidersTab = () => {
  return (
    <>
      <ReversedRadiusDivider />
      <div className={styles.mag_home_sliders_container}>
        <ActionBar className="pt-10" />
        <SlidersTable />
        <SlidersModal />
      </div>
    </>
  );
};

export default SlidersTab;
