import { useRef } from "react";
import { Button } from "antd";
import { Modal } from "ui";
import { useMagHomeManagementStore } from "pages/mag-home-management/store";
import {
  useAddCategoryLine,
  useUpdateCategoryLine,
} from "pages/mag-home-management/api";
import CategoryLineForm, { CategoryLineFormRef } from "../form";

const CategoryLinesModal = () => {
  const { setFormData, formData, isEdit } = useMagHomeManagementStore(
    (state) => state
  );
  const updateCategoryLineQuery = useUpdateCategoryLine();
  const addCategoryLineQuery = useAddCategoryLine();
  const loading = isEdit
    ? updateCategoryLineQuery.isPending
    : addCategoryLineQuery.isPending;

  const formRef = useRef<CategoryLineFormRef>(null);

  const handleOk = (onSuccess: () => void) => {
    const values = formRef.current?.getFormData();
    setFormData(values);

    if (isEdit) {
      updateCategoryLineQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    } else {
      addCategoryLineQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    }
  };

  return (
    <Modal
      name="MAG_HOME_PAGE_CATEGROY_LINE_MODAL"
      title={
        isEdit
          ? "ویرایش لاین دسته بندی صفحه اصلی"
          : "ایجاد لاین دسته بندی صفحه اصلی"
      }
      okButtonProps={{
        loading: loading,
      }}
      onClose={() => {
        setFormData(undefined);
        formRef.current?.resetForm();
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {isEdit ? "ویرایش" : "ثبت"}
          </Button>
        </>
      )}
    >
      <CategoryLineForm ref={formRef} formData={formData} />
    </Modal>
  );
};
export default CategoryLinesModal;
