import React, { useEffect, useState } from "react";
import { Flex, Upload, UploadFile, UploadProps } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import classNames from "classnames";
import { toast } from "react-toastify";
import { IconEditPencil } from "components/icons/iconEditPencil";
import IconCamera from "components/icons/iconCamera";
import { IconDelete } from "components/icons/iconDelete";

import styles from "./index.module.scss";

interface BannerUploadProps extends UploadProps {
  name: string;
  title?: string;
  card?: boolean;
  label?: React.ReactNode;
  hint?: React.ReactNode;
  value?: BannerImage;
  onBannerChange?: (banner: BannerImage | undefined) => void;
}

export interface BannerImage {
  file?: UploadFile<any>;
  width: number;
  height: number;
  preview: string | ArrayBuffer | null | undefined;
}

export const BannerUpload: React.FC<BannerUploadProps> = ({
  onChange,
  onBannerChange,
  name,
  title,
  card = false,
  label = <IconCamera width={32} height={30} />,
  hint = (
    <div className={styles.hints}>
      <span>انواع فایل های مجاز برای آپلود: png, webp, jpg, jpeg.</span>
      <span>حجم فایل حداکثر ۲۰۰ کیلوبایت</span>
    </div>
  ),
  value,
  className,
  ...rest
}) => {
  const [currentImage, setCurrentImage] = useState<BannerImage | undefined>(
    undefined
  );
  const [fileList, setFileList] = useState<BannerImage[]>([]);

  useEffect(() => {
    if (fileList?.length > 0) {
      onBannerChange?.(fileList?.[0]);
    }
  }, [fileList]);
  useEffect(() => {
    setCurrentImage(value);
  }, [value]);

  const showSelectedFile = fileList.length > 0;
  const getImageInfo = () =>
    showSelectedFile
      ? {
          width: fileList[0].width,
          height: fileList[0].height,
          src: String(fileList[0].preview),
        }
      : {
          width: currentImage?.width,
          height: currentImage?.height,
          src: String(currentImage?.preview),
        };

  const applyUpload = (upload: UploadFile) => {
    if (upload.originFileObj) {
      const file = upload.originFileObj!;

      setTimeout(async () => {
        const imageBitmap = await createImageBitmap(file);
        const { width, height } = imageBitmap;

        const reader = new FileReader();
        reader.onload = (e) => {
          const uploadFile = {
            width,
            height,
            file,
            preview: e.target?.result,
          };
          setFileList((prev) => [...prev, uploadFile]);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const reset = () => {
    onBannerChange?.(undefined);
    setCurrentImage(undefined);
    setFileList([]);
  };

  return (
    <div className={classNames([styles.add_slider_container, className])}>
      {showSelectedFile || currentImage ? (
        <>
          <Flex gap="1rem">
            {title && <span className="color-stroke">{title}</span>}
            <span className="text-danger">
              سایز تصویر {`${getImageInfo().width}x${getImageInfo().height}`}
            </span>
          </Flex>
          <div className={styles.image_preview}>
            <img src={String(getImageInfo().src)} />
            <div className={styles.action_icon} onClick={() => reset()}>
              <IconDelete color="#F5222D" />
            </div>
          </div>
        </>
      ) : (
        <div className={classNames(["w-100", card && styles.card_style])}>
          <Upload
            name={name}
            fileList={fileList.map((upload) => upload.file!)}
            multiple={false}
            onChange={(e: UploadChangeParam<UploadFile<any>>) => {
              onChange?.(e);
              applyUpload(e.file);
            }}
            className="avatar-uploader"
            beforeUpload={(file) => {
              const isLegitimate =
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "image/webp";
              if (!isLegitimate) {
                toast.error("فقط فایل های JPG/WebP/PNG مجاز هستند");
              }
              return isLegitimate;
            }}
            customRequest={(ops) => {
              ops.onSuccess?.(<></>);
            }}
            {...rest}
          >
            {label}
            {card && (
              <div className={styles.edit_button}>
                <IconEditPencil color="#B5B5C3" />
              </div>
            )}
          </Upload>
        </div>
      )}
      {hint}
    </div>
  );
};
