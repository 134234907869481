import { ComponentRef, forwardRef, useImperativeHandle, useState } from "react";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Flex, Form, Input, Switch } from "antd";
import { BannerUpload, DatePickerAntd } from "ui";
import { getRand } from "core/utils/common-utils";
import { getImageSrc } from "core/utils/json-utils";
import { homeSliderForm } from "pages/mag-home-management/type";

interface SliderFormProp {
  formData?: homeSliderForm;
}
interface RefHandler {
  getFormData: () => homeSliderForm;
  resetForm: () => void;
}
export type SliderFormRef = ComponentRef<typeof SliderForm>;

const SliderForm = forwardRef<RefHandler, SliderFormProp>(
  ({ formData }, ref) => {
    const [refreshKey, setRefreshKey] = useState("");

    const [form] = Form.useForm();

    const formik = useFormik<homeSliderForm>({
      initialValues: {
        id: formData?.id,
        name: formData?.name,
        url: formData?.url,
        image: formData?.image,
        uploadImage: undefined,
        mobileImage: formData?.mobileImage,
        mobileUploadImage: undefined,
        description1: formData?.description1,
        description2: formData?.description2,
        isActive: formData?.isActive,
        priority: formData?.priority,
      },
      enableReinitialize: true,
      onSubmit: () => {},
    });
    const { setFieldValue, values, resetForm } = formik;

    useImperativeHandle(ref, () => ({
      getFormData() {
        return values;
      },
      resetForm() {
        resetForm();
        setRefreshKey(`${getRand()}`);
        form.resetFields();
      },
    }));

    return (
      <FormikProvider value={formik}>
        <Form form={form}>
          {!values.uploadImage && <h2>تصویر دسکتاپ:</h2>}
          <Form.Item name="uploadImage" valuePropName="uploadImage">
            <BannerUpload
              key={`banner-${refreshKey}`}
              value={
                values.image?.url
                  ? {
                      width: values.image!.width,
                      height: values.image!.width,
                      preview: getImageSrc(values.image?.url, true),
                    }
                  : undefined
              }
              name="uploadImage"
              title="تصویر اسلایدر"
              listType="picture-card"
              className="avatar-uploader"
              onBannerChange={(banner) => {
                setFieldValue("image", undefined);
                setFieldValue("uploadImage", banner);
              }}
            />
          </Form.Item>
          {!values.mobileUploadImage && <h2>تصویر موبایل:</h2>}
          <Form.Item name="mobileUploadImage" valuePropName="mobileUploadImage">
            <BannerUpload
              key={`banner-mobile-${refreshKey}`}
              value={
                values.mobileImage?.url
                  ? {
                      width: values.mobileImage!.width,
                      height: values.mobileImage!.width,
                      preview: getImageSrc(values.mobileImage?.url, true),
                    }
                  : undefined
              }
              name="mobileUploadImage"
              title="تصویر اسلایدر موبایل"
              listType="picture-card"
              className="avatar-uploader"
              onBannerChange={(banner) => {
                setFieldValue("mobileImage", undefined);
                setFieldValue("mobileUploadImage", banner);
              }}
            />
          </Form.Item>
          <Form.Item
            label="عنوان اسلایدر"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="name"
            rules={[
              {
                required: true,
                message: "لطفا نام اسلایدر را وارد کنید",
              },
            ]}
          >
            <Field name="name">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="لطفا تایپ کنید"
                  value={values.name}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="اولویت اسلایدر"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="priority"
          >
            <Field name="priority">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  type="number"
                  min="0"
                  placeholder="اولویت را وارد کنید  "
                  onChange={(e) => setFieldValue("priority", e.target.value)}
                  value={values.priority}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="url"
            label="لینک اسلایدر"
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="url">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="لینک اسلایدر را وارد نمایید"
                  value={values.url}
                  onChange={(e) => setFieldValue("url", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="description1"
            label="توضیحات اول"
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="description1">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="توضیحات اول را وارد نمایید"
                  value={values.description1}
                  onChange={(e) =>
                    setFieldValue("description1", e.target.value)
                  }
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="description2"
            label="توضیحات دوم"
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="description2">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="توضیحات دوم را وارد نمایید"
                  value={values.description2}
                  onChange={(e) =>
                    setFieldValue("description2", e.target.value)
                  }
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="isActive"
            label={<label>وضعیت</label>}
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="isActive">
              {({ field }: FieldProps) => (
                <Switch
                  {...field}
                  checked={values.isActive}
                  onChange={(checked) => setFieldValue("isActive", checked)}
                />
              )}
            </Field>
          </Form.Item>
        </Form>
      </FormikProvider>
    );
  }
);

export default SliderForm;
