import { AlignType } from "rc-table/lib/interface";

export const BRAND_MANAGEMENT_COLUMN = [
  {
    title: "تصویر و نام برند",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "لینک برند",
    dataIndex: "link",
    key: "link",
    align: "center" as AlignType,
    width: "15%",
  },
  {
    title: "وضعیت",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
    width: "15%",
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
    width: "15%",
  },
];
