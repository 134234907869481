import { Flex } from "antd";
import { IconDrop } from "components/icons/iconDrop";
import { useArticleManagementStore } from "pages/articles/store";

import styles from "./index.module.scss";
interface headerProps {}
const Header: React.FC<headerProps> = () => {
  const totals = useArticleManagementStore((state) => state.headerInfo.totals);

  return (
    <div className={styles.header_container}>
      <div className={styles.image_container}>
        <img src="/assets/images/landing_bg.svg" alt="img" />
      </div>
      <Flex align="center" gap="15px" className={styles.info_container}>
        <div>
          <IconDrop />
        </div>
        <Flex vertical gap="6px">
          <strong>مدیریت مقالات</strong>
          <span className="fs-small color-stroke">
            تعداد مقالات آپلود شده {totals}
          </span>
        </Flex>
      </Flex>
    </div>
  );
};

export default Header;
