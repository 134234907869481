import { toast } from "react-toastify";
import classNames from "classnames";
import { IconCopy } from "components/icons";

import styles from "./index.module.scss";
export const CopyButton = ({ value }: { value?: string }) => {
  const hasText = (value ?? "").trim().length > 0;

  const copyLink = (value: string) => {
    if (hasText) {
      navigator.clipboard.writeText(value);
      toast.success("لینک کپی شد");
    }
  };

  return (
    <div
      className={classNames(styles.copy_button, [!hasText && styles.disabled])}
      onClick={() => copyLink(value ?? "")}
    >
      <IconCopy color={hasText ? "var(--warning)" : "var(--disabled-color)"} />
      <span>{hasText ? value : "URL ندارد"}</span>
    </div>
  );
};
