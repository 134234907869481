import { useRef } from "react";
import { Button } from "antd";
import { Modal } from "ui";
import { useMagHomeManagementStore } from "pages/mag-home-management/store";
import {
  useAddMagHomeBanner,
  useUpdateHomeBanner,
} from "pages/mag-home-management/api";
import BannerForm, { BannerFormRef } from "../form";
import { toast } from "react-toastify";

const BannersModal = () => {
  const { setFormData, formData, isEdit } = useMagHomeManagementStore(
    (state) => state
  );
  const updateBannerQuery = useUpdateHomeBanner();
  const addBannerQuery = useAddMagHomeBanner();
  const loading = isEdit
    ? updateBannerQuery.isPending
    : addBannerQuery.isPending;

  const formRef = useRef<BannerFormRef>(null);

  const handleOk = (onSuccess: () => void) => {
    const values = formRef.current?.getFormData();
    setFormData(values);

    if (isEdit) {
      updateBannerQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    } else {
      if (!values?.uploadImage) {
        toast.error("لطفا تصویر بنر را مشخص کنید");
        return;
      }
      addBannerQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    }
  };

  return (
    <Modal
      name="MAG_HOME_PAGE_BANNER_MODAL"
      title={isEdit ? "ویرایش بنر" : "افزودن بنر جدید"}
      okButtonProps={{
        loading: loading,
      }}
      onClose={() => {
        setFormData(undefined);
        formRef.current?.resetForm();
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {isEdit ? "ویرایش" : "ثبت"}
          </Button>
        </>
      )}
    >
      <BannerForm ref={formRef} formData={formData} />
    </Modal>
  );
};
export default BannersModal;
