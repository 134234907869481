import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { TECH_SPEC_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { RequestDTO, ResponseDTO } from "interface";
import { specsManagementFilter, specType } from "../type";

type offersRequestType = RequestDTO<specsManagementFilter>;
const getOffers = async (
  articlesRequestType: offersRequestType
): Promise<ResponseDTO<specType[]>> => {
  const res = await instance.post(TECH_SPEC_ENDPOINTS.grid, {
    ...articlesRequestType,
  });

  return res.data;
};

type UseGetOffersOptions = UseQueryOptions<
  ResponseDTO<specType[]>,
  unknown,
  ResponseDTO<specType[]>
>;
export const useGetTechnicalSpecificationsGrid = (
  params: offersRequestType,
  config?: UseGetOffersOptions
) => {
  return useQuery({
    queryKey: [
      TECH_SPEC_ENDPOINTS.grid,
      params.page,
      params.pageSize,
      ...(params.inputParams.filters ? params.inputParams.filters : []),
    ],
    queryFn: () => getOffers(params),
    ...config,
  });
};
