import { ReactNode, useEffect, useState } from "react";
import { Spin } from "antd";
import Select, { GroupBase, Options, SingleValue } from "react-select";
import styles from "./index.module.scss";

export interface Option extends GroupBase<number> {
  label: string;
  value: number;
}

interface InfiniteSelectProps {
  options: Options<Option> | any;
  onChange: (option: Option | undefined) => void;
  page?: number;
  onInputChange?: (text: string) => void;
  onPageChange?: (page: number) => void;
  onEnter?: (text: string) => void;
  value?: SingleValue<number>;
  isLoading: boolean;
  placeholder: string;
  isClearable?: boolean;
  noOptionsMessage?: string | ReactNode;
  args: any;
}

const InfiniteSelect: React.FC<InfiniteSelectProps> = ({
  options,
  onChange,
  value,
  page = 1,
  onInputChange,
  onPageChange,
  onEnter,
  isLoading,
  placeholder,
  isClearable = true,
  noOptionsMessage,
  args,
}) => {
  const [currentText, setCurrentText] = useState<string>("");
  const [allOptions, setAllOptions] = useState<Options<Option>>([]);

  useEffect(() => {
    setAllOptions((prev) =>
      page === 1 ? [...options] : [...prev, ...options]
    );
  }, [options]);

  const onKeyDown = (event: any) => {
    if (event.key === "Enter") {
      onEnter?.(currentText);
    }
  };

  return (
    <Select
      {...args}
      value={options?.find((item: any) => item.value === value)}
      options={allOptions}
      onChange={(value: Option) => {
        onChange?.(value);
      }}
      onMenuScrollToBottom={(e) => {
        if (!isLoading) {
          onPageChange?.(page + 1);
        }
      }}
      isMulti={false}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      classNames={{
        control: () => styles.common_select,
      }}
      styles={{
        menuPortal: (baseStyles, state) => ({
          ...baseStyles,
          zIndex: 9999,
        }),
      }}
      placeholder={placeholder}
      onInputChange={(e) => {
        setCurrentText(e);
        onInputChange?.(e);
      }}
      onKeyDown={(e) => onKeyDown(e)}
      isClearable={isClearable}
      noOptionsMessage={({ inputValue }) => {
        if (noOptionsMessage) return noOptionsMessage;
        return (
          <center>
            {isLoading ? <Spin /> : <strong>"{inputValue}" یافت نشد</strong>}
          </center>
        );
      }}
    />
  );
};

export default InfiniteSelect;
