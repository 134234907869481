import styles from "./index.module.scss";

const ReversedRadiusDivider = () => (
  <div className={styles["holder"]}>
    <div className={`${styles["top-left"]}`}></div>
    <div className={`${styles["top-right"]}`}></div>
    <div className={`${styles["bottom-left"]}`}></div>
    <div className={`${styles["bottom-right"]}`}></div>
  </div>
);

export default ReversedRadiusDivider;
