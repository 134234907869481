import moment from "moment-jalaali";

export const toTime = (dateStr: string | undefined | null) => {
  if (dateStr) return moment(dateStr).format("hh:mm");

  return "-";
};

export const toDate = (dateStr: string | undefined | null) => {
  if (dateStr) {
    const m = moment(dateStr);
    return m.isValid() ? m.format("jYYYY/jM/jD") : "-";
  }

  return "-";
};

export const toDateTime = (dateStr: string | undefined | null) => {
  let formatted = "-";

  if (dateStr) {
    const m = moment(dateStr);
    formatted = m.format("hh:mm jYYYY/jM/jD");
    if (formatted.includes("NaN")) {
      formatted = m.format("hh:mm YYYY/MM/DD");
    }
  }

  return formatted;
};

export const toGregorian = (jalaliStr: string | undefined | null) => {
  if (jalaliStr)
    return moment(jalaliStr, "jYYYY-jM-jDTHH:mm:ss").format(
      "YYYY-MM-DDTHH:mm:ss"
    );

  return undefined;
};
