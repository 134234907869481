import * as Yup from "yup";

export const addArticleFormSchema = Yup.object().shape({
  title: Yup.string().required("لطفا نام مقاله را وارد کنید"),
  content: Yup.string().required("لطفا محتوا را وارد کنید"),
  authorId: Yup.number().required("لطفا نویسنده را مشخص کنید"),
  articleCategoryId: Yup.number().required("لطفا دسته بندی مقاله را مشخص کنید"),
});

export const addArticleBannerFormSchema = Yup.object().shape({
  name: Yup.string().required("لطفا نام بنر را وارد کنید"),
  url: Yup.string().required("لطفا لینک را مشخص کنید"),
  from: Yup.string().required("لطفا تاریخ آغاز را مشخص کنید"),
  to: Yup.string().required("لطفا تاریخ پایان را مشخص کنید"),
});
