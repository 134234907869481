import { useRef } from "react";
import { Button } from "antd";
import { Modal } from "ui";
import { useAdsStore } from "pages/articles/store";
import { useAddAd, useUpdateAd } from "pages/articles/api";
import AdForm, { AdFormRef } from "../form";
import { toast } from "react-toastify";
import { isObjectEmpty } from "core/utils/json-utils";

const AdsModal = () => {
  const { setFormData, formData, isEdit } = useAdsStore((state) => state);
  const updateAdQuery = useUpdateAd();
  const addAdQuery = useAddAd();
  const loading = isEdit ? updateAdQuery.isPending : addAdQuery.isPending;

  const formRef = useRef<AdFormRef>(null);

  const handleOk = (onSuccess: () => void) => {
    if (!formRef.current) return;

    // Get validation and form values
    const { validation, values } = formRef.current.getFormData();
    validation.then((result) => {
      // If no errors, then data is valid
      if (isObjectEmpty(result)) {
        setFormData(values);
        if (isEdit) {
          updateAdQuery.mutate(
            {
              data: values!,
            },
            {
              onSuccess: () => {
                formRef.current?.resetForm();
                onSuccess();
              },
            }
          );
        } else {
          if (!values?.uploadImage) {
            toast.error("لطفا تصویر بنر را مشخص کنید");
            return;
          }

          addAdQuery.mutate(
            {
              data: values!,
            },
            {
              onSuccess: () => {
                formRef.current?.resetForm();
                onSuccess();
              },
            }
          );
        }
      }
    });
  };

  return (
    <Modal
      name="ARTICLE_ADS_MODAL"
      title={isEdit ? "ویرایش بنر" : "افزودن بنر جدید"}
      okButtonProps={{
        loading: loading,
      }}
      onClose={() => {
        setFormData(undefined);
        formRef.current?.resetForm();
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {isEdit ? "ویرایش" : "ثبت"}
          </Button>
        </>
      )}
    >
      <AdForm ref={formRef} formData={formData} />
    </Modal>
  );
};
export default AdsModal;
