import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { categoryLineForm } from "../../type";

const addCategoryLine = async ({ data }: { data: categoryLineForm }) => {
  const formData = new FormData();
  formData.append("ArticleCategoryId", String(data.articleCategoryId ?? 0));
  formData.append("Title", String(data.title ?? ""));
  formData.append("Line", String(data.line ?? "0"));
  formData.append("Priority", String(data.priority ?? "0"));
  formData.append(
    "NumberOfArticleToDisplay",
    String(data.numberOfArticleToDisplay ?? 0)
  );
  formData.append("IsActive", String(data.isActive ?? "false"));

  const res = await instance.post(
    MAG_HOME_END_POINTS.add_category_line,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  return res.data;
};

export const useAddCategoryLine = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      MAG_HOME_END_POINTS.add_category_line,
      MAG_HOME_END_POINTS.category_lines_list,
    ],
    mutationFn: addCategoryLine,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(
        `${e.response?.data.Message || "خطا در ایجاد لاین دسته بندی"}`
      );
    },
    onSuccess: () => {
      toast.success(`لاین دسته بندی با موفقیت افزوده شد`);
      queryClient.invalidateQueries({
        queryKey: [MAG_HOME_END_POINTS.category_lines_list],
      });
    },
  });
};
