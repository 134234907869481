import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  MAG_HOME_END_POINTS,
  VIDEOMEDIA_END_POINTS,
} from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { homeVideoForm } from "../../type";

let videoUploadToastId = -1;

const addMagHomeVideo = async ({ data }: { data: homeVideoForm }) => {
  // *** Upload Video Media ***
  let uploadVideoMediaPost;
  if (data?.uploadVideo?.file) {
    const videoMediaData = new FormData();
    videoMediaData.append("Title", data?.name ?? "");
    videoMediaData.append("SubTitle", data?.name ?? "");
    videoMediaData.append("Description", data?.description ?? "");
    videoMediaData.append("TimeToWatchVideo", "0");
    videoMediaData.append("Width", String(data?.uploadVideo?.width));
    videoMediaData.append("Height", String(data?.uploadVideo?.height));
    videoMediaData.append("SizePercentage", "100");
    videoMediaData.append("File", data?.uploadVideo?.file);

    videoUploadToastId = toast.warn("در حال بارگذاری ویدیو...", {
      autoClose: false,
      hideProgressBar: true,
    }) as number;

    uploadVideoMediaPost = await instance.post<any>(
      VIDEOMEDIA_END_POINTS.add,
      videoMediaData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  // *** Create home-video with uploaded media id ***

  const formData = new FormData();
  formData.append(
    "VideoMediaId",
    String(uploadVideoMediaPost?.data?.data ?? 0)
  );
  formData.append("Priority", String(data?.priority ?? "0"));
  formData.append("AltText", data.name ?? "");
  formData.append("Name", data.name ?? "");
  formData.append("Url", data.url ?? "");
  formData.append("IsActive", String(data.isActive ?? "false"));
  formData.append("Description", data?.description ?? "");

  const res = await instance.post(MAG_HOME_END_POINTS.add_video, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useAddMagHomeVideo = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      MAG_HOME_END_POINTS.add_video,
      MAG_HOME_END_POINTS.videos_list,
    ],
    mutationFn: addMagHomeVideo,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(`${e.response?.data.Message || "خطا در ایجاد ویدیو"}`);
    },
    onSuccess: () => {
      toast.success(`ویدیو با موفقیت افزوده شد`);
      queryClient.invalidateQueries({
        queryKey: [MAG_HOME_END_POINTS.videos_list],
      });
    },
    onSettled: () => {
      if (videoUploadToastId >= 0) toast.dismiss(videoUploadToastId);
    },
  });
};
