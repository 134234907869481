import { create } from "zustand";
import { filter, Key } from "interface";
import { articleForm, articleManagementFilter } from "../../type";
type SectionsStore = {
  isEdit: boolean;
  formData?: Partial<articleForm>;
  selectedArticlesId?: Key[];
  filter?: filter<articleManagementFilter>[];
  setIsEdit: (val: boolean) => void;
  setFormData: (formData?: articleForm) => void;
  setFilter: (filter: filter<articleManagementFilter>[]) => void;
  setSelectedArticlesId: (value: Key[]) => void;
};

export const useSectionsStore = create<SectionsStore>((set) => {
  return {
    isEdit: false,
    setFormData: (value: Partial<articleForm> | undefined) => {
      set({ formData: value });
    },
    setFilter: (value: filter<articleManagementFilter>[]) => {
      set({ filter: value });
    },
    setSelectedArticlesId: (value: Key[]) => {
      set({ selectedArticlesId: value });
    },
    setIsEdit: (value: boolean) => {
      set({ isEdit: value });
    },
  };
});
