import React, { useMemo } from "react";
import { Flex, Table } from "antd";
import { toast } from "react-toastify";
import { CopyButton, StatusLabel, ActionButton } from "ui";
import { toDateTime } from "core/utils/date-time";
import { getImageSrc } from "core/utils/json-utils";
import { ImageDTO } from "interface";
import { useAppStore } from "store";
import { useMagHomeManagementStore } from "pages/mag-home-management/store";
import {
  useDeleteMagHomeVideo,
  useGetMagHomeVideos,
} from "pages/mag-home-management/api";
import { MAG_HOME_VIDEOS_COLUMN } from "pages/mag-home-management/constant";
import { homeVideo } from "pages/mag-home-management/type";

const VideosTable = () => {
  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setFormData, setIsEdit } = useMagHomeManagementStore(
    (state) => state
  );

  const { data, isPending } = useGetMagHomeVideos();
  const { mutate: deleteVideoMutation } = useDeleteMagHomeVideo();

  const openVideo = (
    event: React.MouseEvent<HTMLVideoElement>,
    video?: ImageDTO
  ) => {
    event.preventDefault();
    if ((video?.url ?? "").length > 0) {
      window.open(
        `${process.env.REACT_APP_BASE_URL}${video?.url ?? "/"}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      toast.error("این ویدیو لینک ندارد");
    }
  };

  const dataSource = useMemo(() => {
    return (
      (data?.data ?? [])
        .sort(
          (i1: homeVideo, i2: homeVideo) =>
            (i1.priority ?? 0) - (i2.priority ?? 0)
        )
        .map((item: homeVideo) => {
          return {
            key: item.id,
            image: (
              <video onClick={(e) => openVideo(e, item?.videoMedia)}>
                <source src={getImageSrc(item?.videoMedia?.url)}></source>
              </video>
            ),
            title: (
              <Flex vertical align="start" gap="5px">
                <strong className="color-primary">{item?.name ?? "-"}</strong>
                <label className="color-stroke">
                  تاریخ ثبت: {toDateTime(new Date().toISOString())}
                </label>
              </Flex>
            ),
            priority: (
              <span className="color-stroke text-center">
                {item?.priority ?? 0}
              </span>
            ),
            status: (
              <Flex align="center" justify="center">
                <StatusLabel status={item.isActive} />
              </Flex>
            ),
            link: (
              <Flex justify="center">
                <CopyButton value={item.url ?? ""} />
              </Flex>
            ),
            action: (
              <Flex gap={8} justify="center">
                <ActionButton
                  type="remove"
                  onClick={() => deleteVideoMutation(item.id!)}
                />
                <ActionButton
                  type="edit"
                  onClick={() => {
                    setFormData(item);
                    setIsEdit(true);
                    setModalVisibility("MAG_HOME_PAGE_VIDEO_MODAL");
                  }}
                />
              </Flex>
            ),
          };
        }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={MAG_HOME_VIDEOS_COLUMN}
      dataSource={dataSource}
      loading={isPending}
      pagination={false}
    />
  );
};

export default VideosTable;
