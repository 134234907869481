export const AUTH_END_POINTS = {
  login: `account/sign-in-by/password`,
  refresh: `account/refresh-token`,
  forget_password_otp: `account/send-forget-password-otp`,
  forget_password_confirm: `account/forget-password`,
  change_password: `account/change-password`,
  sign_up: `/api/users/`,
  verify: `/api/users/verify`,
};

export const DASHBOARD_END_POINTS = {
  user_setting: `/dashboard/user-setting`,
  update_user_setting: `/dashboard/update-user-setting`,
  user_info: `/dashboard/user-info`,
  products_count: `/dashboard/products-count`,
  last_contents: `/dashboard/last-contents`,
};

export const USER_END_POINTS = {
  list: "/users/grid",
  add: "/users/add",
  delete: "/users/delete",
  update: "/users/update",
  block: "/users/lock",
  unblock: "/users/unlock",
};

export const SETTINGS_END_POINTS = {
  update_privacy: "/sections/settings/update-privacy",
  get_privacy: "/sections/settings/privacy",
  update_contactus: "/sections/settings/update-contact-us",
  get_contactus: "/sections/settings/contact-us",
  update_aboutus: "/sections/settings/update-about-us",
  get_aboutus: "/sections/settings/about-us",
  update_terms: "/sections/settings/update-terms",
  get_terms: "/sections/settings/terms",
};

export const ROLE_END_POINTS = {
  roles: "/roles/get",
  grid: "/roles/grid",
  add: "/roles/add",
  delete: "/roles/delete",
  update: "/roles/update",
};

export const STORES_END_POINTS = {
  list: "/stores/grid",
};

export const SEO_END_POINTS = {
  list: "/seo-url/grid",
  update: "/seo-url/set-redirect-url",
  delete: "/seo-url/delete-redirect-url",
};

export const BRAND_END_POINTS = {
  brands_list: "/brands/grid",
  brands_flat_list: "/brands/list",
  add_brand: "/brands/add",
  update_brand: "/brands/update",
  delete_brand: "/brands/delete/id",
};

export const TAG_END_POINTS = {
  list: "/tag/get/autocomplete",
  create: "/tag/create",
  delete: "/tag/delete",
};

export const HOME_END_POINTS = {
  update_slider: "/home-page/sliders/update",
  update_slider_image: "/home-page/sliders/update/image",
  sliders_list: "/home-page/sliders/grid",
  add_slider: "/home-page/sliders/add",
  delete_slider: "/home-page/sliders/delete",
  add_slider_mobile_image: "/home-page/sliders/add/mobile-image",
};

export const BANNER_END_POINTS = {
  update: "/banner/update",
  list: "/banner/grid",
  add: "/banner/add",
  delete: "/banner/delete",
  update_image: "/banner/update/image",
};

export const PRODUCT_ENDPOINTS = {
  list: "/product/grid",
  add: "/product/add",
  search: "/product/list/search",
  get: "/product/details",
  basic_info: "/product/basic-information",
  status_codes: "/product/product-status",
  list_store_products: "/product/store-products",
  add_store_product: "/product/store-products/add",
  update_store_product: "/product/store-products/update",
  full_description: "/product/full-description",
  update_full_description: "/product/full-description/update",
  update_product_tags: "/product/tags/update",
  product_seo: "/product/seo",
  update_product_seo: "/product/seo/update",
  categories_flat_list: "/product-categories/flat-list",
  get_product_categories: "/product/product-category/get",
  add_category_to_product: "/product/product-category/add",
  remove_category_from_product: "/product/product-category/delete",
  upload_product_image: "/product-galleries/upload",
  get_product_image: "/product-galleries/product-id",
  delete_product_image: "/product-galleries/delete",
  upload_preview_image: "/product-galleries/upload/preview",
  list_product_videos: "/product/video/list",
  upload_product_video: "/product/video/create",
  delete_product_video: "/product/video/delete",
  get_product_category_specs: "/product/category-specs-list",
  get_product_specs: "/products/specifications/get",
  update_product_specs: "/products/specifications/update",
  list_product_parts: "/product/parts",
  add_product_part: "/product/parts/create",
  delete_product_part: "/product/parts/delete",
  update_product_part: "/product/parts/update",
  list_urls: "/product/urls/list",
  update_url: "/product/urls/update",
  delete_url: "/product/urls/delete",
};

export const PRODUCT_CATEGORIES_ENDPOINTS = {
  list: "/product-categories/grid",
  add: "/product-categories/add",
  get: "/product-categories/get",
  delete: "/product-categories/delete",
  update_info: "/product-categories/update",
  update_image: "/product-categories/image/update",
  delete_image: "/product-categories/image/delete",
  get_seo: "/product-categories/seo/get",
  update_seo: "/product-categories/seo/update",
  list_banners: "/product-categories/banners/get",
  add_banner: "/product-categories/banners/add",
  update_banner: "/product-categories/banners/update",
  delete_banner: "/product-categories/banners/delete",
  list_sliders: "/product-categories/sliders/list",
  add_slider: "/product-categories/sliders/add",
  delete_slider: "/product-categories/sliders/delete",
  update_slider: "/product-categories/sliders/update",
  list_tech_specs: "/product-categories/specifications/get",
  add_tech_spec: "/product-categories/specifications/create",
  delete_tech_spec: "/product-categories/specifications/Delete",
  list_products: "/product-categories/products/grid",
  add_product: "/product-categories/products/add",
  delete_product: "/product-categories/products/delete",
  list_parents: "/product-categories/canAssigneeAsParent",
};

export const GENERAL_ENDPOINTS = {
  list_approvement_states: "/generals/approvement-states",
  list_enumeration_units: "/generals/enumeration-units",
  list_discount_types: "/generals/discount-types",
};

export const TECH_SPEC_ENDPOINTS = {
  list: "/technical-specification/list",
  grid: "/technical-specification/grid",
  add: "/technical-specification/create",
  delete: "/technical-specification/delete",
  update: "/technical-specification/update",
  list_categories: "/technical-specification-category/list",
  add_category: "/technical-specification-category/add",
  delete_category: "/technical-specification-category/delete",
};

export const LANDING_END_POINTS = {
  list: "/landings/grid",
  get: "/landings/get",
  add: "/landings/create",
  update: "/landings/update",
  content: "/landings/content",
  list_faq: "/landings/faq/list",
  add_faq: "/landings/faq/add",
  update_faq: "/landings/faq/edit",
  delete_faq: "/landings/faq/delete",
  list_banners: "/landings/banner/Get",
  add_banner: "/landings/banner/add",
  update_banner: "/landings/banner/update",
  delete_banner: "/landings/banner/delete",
  update_banner_image: "/sections/banner/update/image",
  list_sliders: "/sections/sliders/grid",
  add_slider: "/sections/sliders/add",
  delete_slider: "/sections/sliders/delete",
  update_slider: "/sections/sliders/update",
  update_slider_image: "/sections/sliders/update/image",
  list_video: "/landing/video/list",
  add_video: "/landing/video/create",
  update_video: "/landing/video/update",
  delete_video: "/landing/video/delete",
  list_product_lines: "/landings/product-line",
  add_product_line: "/landings/product-line/add",
  update_product_line: "/landings/product-line/update",
  delete_product_line: "/landings/product-line/delete",
  append_product_to_line: "/landings/product-line/products/add",
  delete_product_from_line: "/landings/product-line/products/delete",
  list_content_tables: "/landings/table-of-content/list",
  get_content_table_section: "/landings/table-of-content/Details",
  add_content_table: "/landings/table-of-content/set",
  add_content_table_section: "/landings/table-of-content/add-section",
  update_content_table_section: "/landings/table-of-content/update-section",
  delete_content_table_section: "/landings/table-of-content/delete-section",
  update_content_table_section_image:
    "/landings/table-of-content/section/update-image",
  delete_content_table_section_image:
    "/landings/table-of-content/section/delete-image",
  get_seo: "/landings/seo",
  update_seo: "/landings/seo/update",
  list_urls: "/landings/urls/list",
  update_url: "/landings/urls/update",
  delete_url: "/landings/urls/delete",
};

export const SEO_MANAGEMENT_ENDPOINTS = {
  list: "/menu-management/grid",
  add: "/menu-management/add",
  update: "/menu-management/update",
  delete: "/menu-management/delete",
  flat: "/menu-management/get/flat-list",
};

export const ARTICLE_END_POINTS = {
  list: "/article-management/grid",
  get: "/article-management/get",
  add: "/article-management/add",
  update: "/article-management/update",
  list_sections: "/article/section/list",
  get_section: "/article/section/get",
  add_section: "/article/section/add",
  delete_section: "/article/section/delete",
  update_section: "/article/section/update",
  delete_section_image: "/article/section/delete/image",
  update_section_image: "/article/section/update/image",
  list_banners: "/article/banner/list",
  get_banner: "/article/banner/get",
  add_banner: "/article/banner/add",
  delete_banner: "/article/banner/delete",
  update_banner: "/article/banner/update",
  delete_banner_image: "/article/banner/delete/image",
  update_banner_image: "/article/banner/update/image",
  list_article_cats_flat: "/article-category/list/drop-down",
  get_article_seo: "/article/seo",
  update_article_seo: "/article/seo/update",
};

export const BEST_OFFER_END_POINTS = {
  list: "/special-suggestion/grid",
  get: "/special-suggestion/get",
  create: "/special-suggestion/create",
  update: "/special-suggestion/update",
  delete: "/special-suggestion/delete",
  upload_image: "/special-suggestion/upload/image",
  delete_image: "/special-suggestion/delete/image",
};

export const AUTHOR_END_POINTS = {
  grid: "/author/grid",
  add: "/author/add",
  update: "/author/update",
};

export const VIDEOMEDIA_END_POINTS = {
  flat: "/videos/flat",
  add: "/videos/add",
  delete: "/videos/update",
};

export const MAG_HOME_END_POINTS = {
  update_slider: "/mag/home-page-slider/update",
  sliders_list: "/mag/home-page-slider/list",
  add_slider: "/mag/home-page-slider/add",
  delete_slider: "/mag/home-page-slider/delete",
  update_banner: "/mag/home-page-banner/update",
  update_banner_image: "/mag/home-page-banner/update",
  banners_list: "/mag/home-page-banner/list",
  add_banner: "/mag/home-page-banner/add",
  delete_banner: "/mag/home-page-banner/delete",
  update_video: "/mag/home-page-video/update",
  videos_list: "/mag/home-page-video/list",
  add_video: "/mag/home-page-video/add",
  delete_video: "/mag/home-page-video/delete",
  update_seo: "/mag/home-page/seo",
  get_seo: "/mag/home-page/get",
  update_home_product: "/mag/home-page-product/update",
  home_products_list: "/mag/home-page-product/list",
  add_home_product: "/mag/home-page-product/add",
  delete_home_product: "/mag/home-page-product/delete",
  update_category_line: "/mag/home-page-category/update",
  category_lines_list: "/mag/home-page-category/list",
  add_category_line: "/mag/home-page-category/add",
  delete_category_line: "/mag/home-page-category/delete",
  update_article_line: "/mag/mag-home-page-aricle-line/update",
  article_lines_list: "/mag/mag-home-page-aricle-line/list",
  add_article_line: "/mag/mag-home-page-aricle-line/add",
  delete_article_line: "/mag/mag-home-page-aricle-line/delete",
  update_article_line_articles:
    "/mag/mag-home-page-aricle-line/articles/update",
  article_line_articles_list: "/mag/mag-home-page-aricle-line/articles/gets",
  add_article_line_article: "/mag/mag-home-page-aricle-line/articles/add",
  delete_article_line_article: "/mag/mag-home-page-aricle-line/articles/delete",
};

export const HOME_CATEGORY_END_POINTS = {
  grid: "/home-category/grid",
  add: "/home-category/add",
  update: "/home-category/api/home-category/update",
  delete: "/home-category/delete",
};
