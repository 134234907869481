import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { articleBanner } from "pages/articles/type";

const updateAd = async ({ data }: { data: articleBanner }) => {
  const formData = new FormData();
  formData.append("Id", String(data.id ?? "0"));
  formData.append("ArticleId", String(data.articleId ?? "0"));
  formData.append("Priority", String(data.priority ?? "0"));
  formData.append("AltText", data.name ?? "");
  formData.append("Name", data.name ?? "");
  formData.append("Url", data.url ?? "");
  formData.append("Description", data.description ?? "");
  formData.append("From", data.from ?? "");
  formData.append("To", data.to ?? "");
  formData.append("LineOrder", String(data.lineOrder ?? "0"));
  formData.append("IsActive", String(data.isActive ?? false));

  const res = await instance.put(ARTICLE_END_POINTS.update_banner, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  const updateImageFormData = new FormData();
  updateImageFormData.append("Id", String(data?.id ?? 0));
  updateImageFormData.append("ArticleId", String(data?.articleId ?? 0));
  if (data.uploadImage) {
    updateImageFormData.append(
      "Image.AltText",
      `${data.name ?? ""}-desktop-image`
    );
    updateImageFormData.append("Image.Width", String(data.uploadImage.width));
    updateImageFormData.append("Image.Height", String(data.uploadImage.height));
    updateImageFormData.append("Image.SizePercentage", "100");
    updateImageFormData.append("Image.File", data.uploadImage.file ?? "");
  }
  if (data.mobileUploadImage) {
    updateImageFormData.append(
      "MobileImage.AltText",
      `${data.name ?? ""}-mobile-image`
    );
    updateImageFormData.append(
      "MobileImage.Width",
      String(data.mobileUploadImage.width)
    );
    updateImageFormData.append(
      "MobileImage.Height",
      String(data.mobileUploadImage.height)
    );
    updateImageFormData.append("MobileImage.SizePercentage", "100");
    updateImageFormData.append(
      "MobileImage.File",
      data.mobileUploadImage.file ?? ""
    );
  }
  if (data?.uploadImage?.file || data?.mobileUploadImage?.file) {
    const updateImageRed = await instance.put(
      ARTICLE_END_POINTS.update_banner_image,
      updateImageFormData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  }

  return res.data;
};

export const useUpdateAd = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [ARTICLE_END_POINTS.update, ARTICLE_END_POINTS.list],
    mutationFn: updateAd,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data.Message || "خطا در ویرایش بنر مقاله"}`);
    },
    onSuccess: () => {
      toast.success(`بنر مقاله با موفقیت ویرایش شد`);
      queryClient.invalidateQueries([
        ARTICLE_END_POINTS.list_banners,
      ] as InvalidateQueryFilters);
    },
  });
};
