import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { articleSection } from "pages/articles/type";

const updateSection = async ({ data }: { data: articleSection }) => {
  const formData = new FormData();
  formData.append("Id", String(data.id ?? 0));
  formData.append("ArticleId", String(data.articleId ?? 0));
  formData.append("AltText", data.title ?? "");
  formData.append("Title", data.title ?? "");
  formData.append("SubTitle", data.subTitle ?? "");
  formData.append("Content", data.content ?? "");

  const res = await instance.put(ARTICLE_END_POINTS.update_section, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useUpdateSection = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      ARTICLE_END_POINTS.update_section,
      ARTICLE_END_POINTS.list_sections,
    ],
    mutationFn: updateSection,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data.Message || "خطا در ویرایش سکشن مقاله"}`);
    },
    onSuccess: () => {
      toast.success(`سکشن مقاله با موفقیت ویرایش شد`);
      queryClient.invalidateQueries([
        ARTICLE_END_POINTS.list_sections,
      ] as InvalidateQueryFilters);
    },
  });
};
