import { useEffect, useRef, useState } from "react";
import { LexicalEditor } from "lexical";
import { $generateHtmlFromNodes } from "@lexical/html";

function removeBoldString(str: string) {
  const re = new RegExp('(<b([^>]*)">)|(<b>)|(</b>)', "g");
  return str.replace(re, "");
}

export function PreviewDialog({
  activeEditor,
  onClose,
}: {
  activeEditor: LexicalEditor;
  onClose: () => void;
}): JSX.Element {
  const [htmlText, setHtmlText] = useState<string>("");

  const hasModifier = useRef(false);

  useEffect(() => {
    hasModifier.current = false;
    const handler = (e: KeyboardEvent) => {
      hasModifier.current = e.altKey;
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [activeEditor]);

  useEffect(() => {
    activeEditor.getEditorState().read(() => {
      const htmlString = removeBoldString(
        $generateHtmlFromNodes(activeEditor, null)
      );

      setHtmlText(htmlString);
    });
  }, [activeEditor]);

  return (
    <div
      style={{ paddingInline: "3rem", direction: "rtl" }}
      dangerouslySetInnerHTML={{ __html: htmlText }}
    ></div>
  );
}
