import { useParams } from "react-router-dom";
import { ComponentRef, forwardRef, useImperativeHandle } from "react";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Form, Input } from "antd";
import { BannerUpload, LexicalEditor } from "ui";
import { FieldError } from "ui/field-error";
import { getImageSrc } from "core/utils/json-utils";
import { articleSection } from "pages/articles/type";

interface SectionFormProp {
  formData?: articleSection;
}
interface RefHandler {
  getFormData: () => articleSection;
  resetForm: () => void;
}
export type SectionFormRef = ComponentRef<typeof SectionForm>;

const SectionForm = forwardRef<RefHandler, SectionFormProp>(
  ({ formData }, ref) => {
    const { id: articleId } = useParams();

    const [form] = Form.useForm();
    const formik = useFormik<articleSection>({
      initialValues: {
        id: formData?.id,
        articleId: Number(articleId),
        content: formData?.content,
        title: formData?.title ?? "",
        subTitle: formData?.subTitle,
        uploadImage: undefined,
        image: formData?.image,
      },
      enableReinitialize: true,
      onSubmit: () => {},
    });
    const { setFieldValue, values, errors, resetForm } = formik;

    useImperativeHandle(ref, () => ({
      getFormData() {
        return values;
      },
      resetForm() {
        resetForm();
        form.resetFields();
      },
    }));

    return (
      <FormikProvider value={formik}>
        <Form form={form}>
          <Form.Item
            name="uploadImage"
            valuePropName="uploadImage"
            label="تصویر"
            labelCol={{ span: 2 }}
            labelAlign="left"
          >
            <BannerUpload
              // key={`article-${refreshKey}`}
              value={
                values.image?.url
                  ? {
                      width: values.image!.width,
                      height: values.image!.width,
                      preview: getImageSrc(values.image?.url, true),
                    }
                  : undefined
              }
              name="uploadImage"
              title="تصویر بنر"
              listType="picture-card"
              className="avatar-uploader mt-2"
              onBannerChange={(banner) => {
                setFieldValue("image", undefined);
                setFieldValue("uploadImage", banner);
              }}
            />
          </Form.Item>
          {/* <Form.Item
            name="Image"
            label="عکس تیتر"
            labelCol={{ span: 2 }}
            labelAlign="left"
            className={styles.uploader}
          > */}
          {/* <div>
            <BannerUpload
              name="image"
              value={
                values.image?.url
                  ? {
                      ...values.image,
                      preview: getImageSrc(
                        String(values?.image?.url ?? ""),
                        true
                      ),
                    }
                  : undefined
              }
              listType="picture-card"
              className="avatar-uploader"
              onBannerChange={(banner) => setFieldValue("uploadImage", banner)}
              hint={
                <div className={styles.info}>
                  <p>تصویر سکشن مقاله را تنظیم کنید.</p>
                  <p>فقط فرمت های jpg, jpeg, png و webp</p>
                  <p>پذیرفته می شوند</p>
                </div>
              }
            />
          </div> */}
          {/* <ArticleBannerInput
              onBannerChange={(banner) => setFieldValue("uploadImage", banner)}
            /> */}
          {/* </Form.Item> */}
          <Form.Item
            name="title"
            label="تیتر مقاله"
            labelCol={{ span: 2 }}
            labelAlign="left"
          >
            <Field name="title">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="مثال: رویای خود را بسازید"
                  value={values.title}
                  onChange={(e) => setFieldValue("title", e.target.value)}
                />
              )}
            </Field>
            <span className="color-stroke fs-x-small">
              نام هر سر تیتر مورد نیاز است و توصیه می شود منحصر به فرد باشد.
            </span>
            <FieldError
              show={errors.title != undefined}
              text={errors.title as string}
            />
          </Form.Item>
          <Form.Item
            name="subTitle"
            label="زیر عنوان"
            labelCol={{ span: 2 }}
            labelAlign="left"
          >
            <Field name="subTitle">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="زیر عنوان را وارد کنید"
                  value={values.subTitle}
                  onChange={(e) => setFieldValue("subTitle", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="content"
            label="توضیحات"
            labelCol={{ span: 2 }}
            labelAlign="left"
          >
            <Field name="content">
              {({ field }: FieldProps) => (
                <LexicalEditor
                  {...field}
                  onChange={(html: string) => setFieldValue("content", html)}
                  value={formData?.content}
                />
              )}
            </Field>
            <FieldError
              show={errors.content != undefined}
              text={errors.content as string}
            />
          </Form.Item>
        </Form>
      </FormikProvider>
    );
  }
);

export default SectionForm;
