import { ComponentRef, forwardRef, useImperativeHandle, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Field,
  FieldProps,
  FormikErrors,
  FormikProvider,
  useFormik,
} from "formik";
import { Flex, Form, Input, Switch } from "antd";
import { BannerUpload, DatePickerAntd } from "ui";
import { FieldError } from "ui/field-error";
import { getRand } from "core/utils/common-utils";
import { getImageSrc } from "core/utils/json-utils";
import { toDate, toGregorian } from "core/utils/date-time";
import { addArticleBannerFormSchema } from "pages/articles/schema";
import { articleBanner } from "pages/articles/type";

interface AdFormProp {
  formData?: articleBanner;
}
interface RefHandler {
  getFormData: () => {
    values: articleBanner;
    validation: Promise<FormikErrors<articleBanner>>;
  };
  resetForm: () => void;
}
export type AdFormRef = ComponentRef<typeof AdForm>;

const AdForm = forwardRef<RefHandler, AdFormProp>(({ formData }, ref) => {
  const [refreshKey, setRefreshKey] = useState("");

  const { id } = useParams();

  const [form] = Form.useForm();
  const formik = useFormik<articleBanner>({
    initialValues: {
      id: formData?.id,
      articleId: parseInt(id ?? "0"),
      name: formData?.name,
      url: formData?.url,
      image: formData?.image,
      uploadImage: undefined,
      mobileImage: formData?.mobileImage,
      mobileUploadImage: undefined,
      isActive: formData?.isActive,
      priority: formData?.priority,
      lineOrder: formData?.lineOrder,
      from: formData?.from,
      to: formData?.to,
      description: formData?.description,
    },
    validationSchema: addArticleBannerFormSchema,
    enableReinitialize: true,
    onSubmit: () => {},
  });
  const { setFieldValue, values, errors, resetForm, validateForm } = formik;

  useImperativeHandle(ref, () => ({
    getFormData() {
      return {
        validation: validateForm(),
        values,
      };
    },
    resetForm() {
      resetForm();
      setRefreshKey(`${getRand()}`);
      form.resetFields();
    },
  }));

  return (
    <FormikProvider value={formik}>
      <Form form={form}>
        {!values.uploadImage && <h2>تصویر دسکتاپ:</h2>}
        <Form.Item name="uploadImage" valuePropName="uploadImage">
          <BannerUpload
            key={`banner-${refreshKey}`}
            value={
              values.image?.url
                ? {
                    width: values.image!.width,
                    height: values.image!.width,
                    preview: getImageSrc(values.image?.url, true),
                  }
                : undefined
            }
            name="uploadImage"
            title="تصویر بنر"
            listType="picture-card"
            className="avatar-uploader"
            onBannerChange={(banner) => {
              setFieldValue("image", undefined);
              setFieldValue("uploadImage", banner);
            }}
          />
        </Form.Item>
        {!values.mobileUploadImage && <h2>تصویر موبایل:</h2>}
        <Form.Item name="mobileUploadImage" valuePropName="mobileUploadImage">
          <BannerUpload
            key={`banner-mobile-${refreshKey}`}
            value={
              values.mobileImage?.url
                ? {
                    width: values.mobileImage!.width,
                    height: values.mobileImage!.width,
                    preview: getImageSrc(values.mobileImage?.url, true),
                  }
                : undefined
            }
            name="mobileUploadImage"
            title="تصویر بنر موبایل"
            listType="picture-card"
            className="avatar-uploader"
            onBannerChange={(banner) => {
              setFieldValue("mobileImage", undefined);
              setFieldValue("mobileUploadImage", banner);
            }}
          />
        </Form.Item>
        <Form.Item
          label="عنوان بنر"
          labelCol={{ span: 5 }}
          labelAlign="left"
          name="name"
          rules={[
            {
              required: true,
              message: "لطفا نام بنر را وارد کنید",
            },
          ]}
        >
          <Field name="name">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                placeholder="لطفا نام بنر را وارد کنید"
                value={values.name}
                onChange={(e) => setFieldValue("name", e.target.value)}
              />
            )}
          </Field>
          <FieldError show={errors.name} text={errors.name} />
        </Form.Item>
        <Form.Item
          label="توضیحات"
          labelCol={{ span: 5 }}
          labelAlign="left"
          name="description"
          rules={[
            {
              required: true,
              message: "لطفا توضیحات بنر را وارد کنید",
            },
          ]}
        >
          <Field name="description">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                placeholder="لطفا توضیحات بنر را وارد کنید"
                value={values.description}
                onChange={(e) => setFieldValue("description", e.target.value)}
              />
            )}
          </Field>
          <FieldError show={errors.description} text={errors.description} />
        </Form.Item>
        <Form.Item
          label="اولویت"
          labelCol={{ span: 5 }}
          labelAlign="left"
          name="priority"
        >
          <Field name="priority">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                type="number"
                min="0"
                placeholder="اولویت را وارد کنید  "
                onChange={(e) => setFieldValue("priority", e.target.value)}
                value={values.priority}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="لاین"
          labelCol={{ span: 5 }}
          labelAlign="left"
          name="lineOrder"
        >
          <Field name="lineOrder">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                type="number"
                min="0"
                placeholder="اولویت را وارد کنید  "
                onChange={(e) => setFieldValue("lineOrder", e.target.value)}
                value={values.lineOrder}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          name="url"
          label="لینک"
          labelCol={{ span: 5 }}
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "لطفا لینک بنر را وارد کنید",
            },
          ]}
        >
          <Field name="url">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                placeholder="لینک صفحه ی موردنظر را وارد نمایید"
                value={values.url}
                onChange={(e) => setFieldValue("url", e.target.value)}
              />
            )}
          </Field>
          <FieldError show={errors.url} text={errors.url} />
        </Form.Item>
        <Form.Item
          name={"date"}
          label="مهلت نشر"
          labelCol={{ span: 5 }}
          labelAlign="left"
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: "لطفا مهلت نشر بنر را مشخص کنید",
            },
          ]}
        >
          <Flex gap={10}>
            <Form.Item name="from" className="w-100">
              <DatePickerAntd
                size="lg"
                label="از:"
                name={`from-${refreshKey}`}
                placeholder={toDate(values.from ?? "")}
                onChange={(e: any) =>
                  setFieldValue("from", toGregorian(e?.format()))
                }
              />
              <FieldError show={errors.from} text={errors.from} />
            </Form.Item>
            <Form.Item name="to" className="w-100">
              <DatePickerAntd
                size="lg"
                label="تا:"
                name={`to-${refreshKey}`}
                placeholder={toDate(values.to ?? "")}
                onChange={(e: any) =>
                  setFieldValue("to", toGregorian(e?.format()))
                }
              />
              <FieldError show={errors.to} text={errors.to} />
            </Form.Item>
          </Flex>
        </Form.Item>
        <Form.Item
          name="isActive"
          label={<label>وضعیت</label>}
          labelCol={{ span: 5 }}
          labelAlign="left"
        >
          <Field name="isActive">
            {({ field }: FieldProps) => (
              <Switch
                {...field}
                checked={values.isActive}
                onChange={(checked) => setFieldValue("isActive", checked)}
              />
            )}
          </Field>
        </Form.Item>
      </Form>
    </FormikProvider>
  );
});

export default AdForm;
