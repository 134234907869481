import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { homeSliderForm } from "../../type";

const addMagHomeSlider = async ({ data }: { data: homeSliderForm }) => {
  const formData = new FormData();
  formData.append("Priority", String(data.priority ?? "0"));
  formData.append("AltText", data.name ?? "");
  formData.append("Name", data.name ?? "");
  formData.append("Url", data.url ?? "");
  formData.append("IsActive", String(data.isActive ?? "false"));
  formData.append("Description1", data?.description1 ?? "");
  formData.append("Description2", data?.description2 ?? "");
  if (data.uploadImage) {
    formData.append("Image.AltText", `${data.name ?? ""}-desktop-image`);
    formData.append("Image.Width", String(data.uploadImage.width));
    formData.append("Image.Height", String(data.uploadImage.height));
    formData.append("Image.SizePercentage", "100");
    formData.append("Image.File", data.uploadImage.file);
  }
  if (data.mobileUploadImage) {
    formData.append("MobileImage.AltText", `${data.name ?? ""}-mobile-image`);
    formData.append("MobileImage.Width", String(data.mobileUploadImage.width));
    formData.append(
      "MobileImage.Height",
      String(data.mobileUploadImage.height)
    );
    formData.append("MobileImage.SizePercentage", "100");
    formData.append("MobileImage.File", data.mobileUploadImage.file);
  }

  const res = await instance.post(MAG_HOME_END_POINTS.add_slider, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useAddMagHomeSlider = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      MAG_HOME_END_POINTS.add_slider,
      MAG_HOME_END_POINTS.sliders_list,
    ],
    mutationFn: addMagHomeSlider,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(`${e.response?.data.Message || "خطا در ایجاد اسلایدر"}`);
    },
    onSuccess: () => {
      toast.success(`اسلایدر با موفقیت افزوده شد`);
      queryClient.invalidateQueries({
        queryKey: [MAG_HOME_END_POINTS.sliders_list],
      });
    },
  });
};
