import { ComponentRef, forwardRef, useImperativeHandle, useMemo } from "react";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Form, Input, Switch } from "antd";
import { Select } from "ui";
import { useGetArticleCatsFlat } from "pages/articles/api";
import { categoryLineForm } from "pages/mag-home-management/type";

interface CategoryLineFormProp {
  formData?: categoryLineForm;
}
interface RefHandler {
  getFormData: () => categoryLineForm;
  resetForm: () => void;
}
export type CategoryLineFormRef = ComponentRef<typeof CategoryLineForm>;

const CategoryLineForm = forwardRef<RefHandler, CategoryLineFormProp>(
  ({ formData }, ref) => {
    const [form] = Form.useForm();
    const formik = useFormik({
      initialValues: {
        id: formData?.id,
        articleCategoryId: formData?.articleCategoryId,
        title: formData?.title,
        line: formData?.line,
        priority: formData?.priority,
        isActive: formData?.isActive,
        numberOfArticleToDisplay: formData?.numberOfArticleToDisplay,
      },
      enableReinitialize: true,
      onSubmit: () => {},
    });
    const { setFieldValue, values, resetForm } = formik;

    useImperativeHandle(ref, () => ({
      getFormData() {
        return values;
      },
      resetForm() {
        resetForm();
        form.resetFields();
      },
    }));

    const { data: articleCatsData, isPending: fetchingArticleCats } =
      useGetArticleCatsFlat();
    const articleCatsOptions = useMemo(() => {
      if (articleCatsData) {
        return (articleCatsData?.data ?? []).map((item) => ({
          label: item.name,
          value: item.id,
        }));
      }
      return [];
    }, [articleCatsData]);

    return (
      <FormikProvider value={formik}>
        <Form form={form}>
          <Form.Item
            label="عنوان لاین"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="title"
          >
            <Field name="title">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="عنوان لاین را وارد کنید  "
                  onChange={(e) => setFieldValue("title", e.target.value)}
                  value={values.title}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="دسته بندی مقاله"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="articleCategoryId"
          >
            <Field name="articleCategoryId">
              {({ field }: FieldProps) => (
                <Select
                  {...field}
                  placeholder="دسته بندی مقاله را انتخاب کنید  "
                  onChange={(e) => setFieldValue("articleCategoryId", e)}
                  value={values.articleCategoryId}
                  options={articleCatsOptions}
                  loading={fetchingArticleCats}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="لاین"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="line"
          >
            <Field name="line">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  type="number"
                  min="0"
                  placeholder="لاین را وارد کنید  "
                  onChange={(e) => setFieldValue("line", e.target.value)}
                  value={values.line}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="اولویت نمایش"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="priority"
          >
            <Field name="priority">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  type="number"
                  min="0"
                  placeholder="اولویت را وارد کنید  "
                  onChange={(e) => setFieldValue("priority", e.target.value)}
                  value={values.priority}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="تعداد مقالات"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="numberOfArticleToDisplay"
          >
            <Field name="numberOfArticleToDisplay">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  type="number"
                  min="0"
                  placeholder="حداکثر تعداد مقالات قابل نمایش را مشخص کنید  "
                  onChange={(e) =>
                    setFieldValue("numberOfArticleToDisplay", e.target.value)
                  }
                  value={values.numberOfArticleToDisplay}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="isActive"
            label={<label>وضعیت</label>}
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="isActive">
              {({ field }: FieldProps) => (
                <Switch
                  {...field}
                  checked={values.isActive}
                  onChange={(checked) => setFieldValue("isActive", checked)}
                />
              )}
            </Field>
          </Form.Item>
        </Form>
      </FormikProvider>
    );
  }
);

export default CategoryLineForm;
