import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { PRODUCT_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";

type QueryOptionsWithoutQueryFn = Omit<UseQueryOptions<any>, "queryFn">;
type QueryType = { productId: number };

export const useGetStoreProducts = (options?: QueryOptionsWithoutQueryFn) =>
  useQuery<any>({
    retry: 0,
    ...(options ?? {}),
    queryFn: async ({ queryKey }) => {
      const [, { productId } = { productId: 0 }] = queryKey as QueryType[];

      const result = await instance.get(
        `${PRODUCT_ENDPOINTS.list_store_products}?productId=${productId}`
      );

      return result.data?.data ?? [];
    },
    queryKey: options?.queryKey
      ? [PRODUCT_ENDPOINTS.list_store_products, ...options.queryKey]
      : [PRODUCT_ENDPOINTS.list_store_products],
  });
