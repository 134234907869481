import { create } from "zustand";
import { filter, Key } from "interface";
import { articleForm, articleManagementFilter, articlesInfo } from "../../type";
type ArticleManagementStore = {
  isEdit: boolean;
  formData?: Partial<articleForm>;
  headerInfo: articlesInfo;
  setHeaderInfo?: (info?: articlesInfo) => void;
  selectedArticlesId?: Key[];
  filter?: filter<articleManagementFilter>[];
  setIsEdit: (val: boolean) => void;
  setFormData: (formData?: articleForm) => void;
  setFilter: (filter: filter<articleManagementFilter>[]) => void;
  setSelectedArticlesId: (value: Key[]) => void;
};

export const useArticleManagementStore = create<ArticleManagementStore>(
  (set) => {
    return {
      isEdit: false,
      setFormData: (value: Partial<articleForm> | undefined) => {
        set({ formData: value });
      },
      headerInfo: {
        totals: 0,
      },
      setHeaderInfo: (info: articlesInfo | undefined) => {
        set({ headerInfo: info });
      },
      setFilter: (value: filter<articleManagementFilter>[]) => {
        set({ filter: value });
      },
      setSelectedArticlesId: (value: Key[]) => {
        set({ selectedArticlesId: value });
      },
      setIsEdit: (value: boolean) => {
        set({ isEdit: value });
      },
    };
  }
);
