import {
  ComponentRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Flex, Form, Input, Switch } from "antd";
import { getRand } from "core/utils/common-utils";
import { homeProductForm } from "pages/mag-home-management/type";
import InfiniteSelect from "ui/infinite-select";
import { useSearchProducts } from "core/services/product/useSearchProducts";

interface HomeProductFormProp {
  formData?: homeProductForm;
}
interface RefHandler {
  getFormData: () => homeProductForm;
  resetForm: () => void;
}
export type HomeProductFormRef = ComponentRef<typeof HomeProductForm>;

const HomeProductForm = forwardRef<RefHandler, HomeProductFormProp>(
  ({ formData }, ref) => {
    const [refreshKey, setRefreshKey] = useState("");

    const [form] = Form.useForm();
    const formik = useFormik({
      initialValues: {
        id: formData?.id,
        productId: formData?.productId,
        isActive: formData?.isActive,
        priority: formData?.priority,
        productsSearchPhrase: "",
        productsPage: 1,
      },
      enableReinitialize: true,
      onSubmit: () => {},
    });
    const { setFieldValue, values, resetForm } = formik;

    useImperativeHandle(ref, () => ({
      getFormData() {
        return values;
      },
      resetForm() {
        resetForm();
        setRefreshKey(`${getRand()}`);
        form.resetFields();
      },
    }));

    const { data: products, isPending: fetchingProducts } = useSearchProducts({
      queryKey: [
        {
          keyword: values.productsSearchPhrase,
          page: values.productsPage,
        },
      ],
    });
    const productsOptions = useMemo(
      () =>
        (products?.items ?? []).map((item: any) => ({
          label: item.name,
          value: item.id,
        })),
      [products]
    );

    return (
      <FormikProvider value={formik}>
        <Form form={form}>
          <Form.Item
            label="انتخاب محصول"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="productId"
            rules={[
              {
                required: true,
                message: "لطفا محصول را انتخاب کنید",
              },
            ]}
          >
            <Field name="productId">
              {({ field }: FieldProps) => (
                <InfiniteSelect
                  args={{ ...field }}
                  placeholder="لطفا یک محصول را انتخاب نمایید"
                  onChange={(value) => {
                    setFieldValue("productId", value?.value);
                  }}
                  options={productsOptions}
                  onInputChange={(phrase) => {
                    setFieldValue("productsSearchPhrase", phrase);
                  }}
                  isLoading={fetchingProducts}
                  onPageChange={(page) => setFieldValue("productsPage", page)}
                  page={values.productsPage}
                  isClearable={true}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="اولویت نمایش"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="priority"
          >
            <Field name="priority">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  type="number"
                  min="0"
                  placeholder="اولویت را وارد کنید  "
                  onChange={(e) => setFieldValue("priority", e.target.value)}
                  value={values.priority}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="isActive"
            label={<label>وضعیت</label>}
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="isActive">
              {({ field }: FieldProps) => (
                <Switch
                  {...field}
                  checked={values.isActive}
                  onChange={(checked) => setFieldValue("isActive", checked)}
                />
              )}
            </Field>
          </Form.Item>
        </Form>
      </FormikProvider>
    );
  }
);

export default HomeProductForm;
