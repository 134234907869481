import { Flex, Form } from "antd";
import { Button, Icon, SearchInput } from "ui";
import { useAppStore } from "store";
import { useSpecsListStore } from "../../store";
import { specsManagementFilter } from "../../type";

interface FormValues {
  search: string;
}
export const ActionBar = () => {
  const { setModalVisibility } = useAppStore((state) => state);
  const { setFormData, setIsEdit, setFilter } = useSpecsListStore(
    (state) => state
  );

  const [form] = Form.useForm<FormValues>();
  const onValuesChangeHandler = (
    changedValues: FormValues,
    allValues: FormValues
  ) => {
    setFilter(
      Object.entries(changedValues)
        .filter(([_, value]) => value.length > 0)
        .map(([key, value]) => {
          return {
            propertyName: key as specsManagementFilter,
            operation: "contains",
            value: value as string,
          };
        })
    );
  };

  return (
    <Flex className="box w-100" gap={10} justify="space-between" align="center">
      <Form
        form={form}
        className="w-100"
        onValuesChange={onValuesChangeHandler}
      >
        <Flex>
          <Form.Item name={"name"}>
            <SearchInput size="large" placeholder="جستجوی ویژگی " />
          </Form.Item>
        </Flex>
      </Form>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          setFormData(undefined);
          setIsEdit(false);
          setModalVisibility("SPECS_MANAGEMENT_MODAL");
        }}
      >
        <Icon type="sr-plus" />
        <span>افزودن ویژگی </span>
      </Button>
    </Flex>
  );
};
