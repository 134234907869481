import { useEffect, useState } from "react";
import { Button, Flex, Input, Modal, Typography } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { Icon } from "ui";
import { ROLE_END_POINTS } from "core/constants/endpoints";
import { useMutateRole } from "pages/dashboard/role-management/api/useMutateRole";

import styles from "./styles.module.scss";
const RoleManagementAddRoleModal = ({ editRole, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // TODO: convert states to useFormik
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [priority, setPriority] = useState(1);

  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutateRole();

  const isUpdate = () => (editRole?.id ?? -1) > 0;

  function openModal() {
    setIsModalOpen(true);
  }
  function closeModal() {
    setIsModalOpen(false);
    resetForm();
    onClose?.();
  }

  function resetForm() {
    setName("");
    setTitle("");
    setPriority(1);
  }

  function mutateRole() {
    mutate(
      {
        name,
        title,
        priority,
        ...(isUpdate() && { id: editRole.id }),
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries([ROLE_END_POINTS.grid]);
          toast.success(
            isUpdate() ? "نقش با موفقیت ویرایش شد" : "نقش جدید ایجاد گردید"
          );
          closeModal();
        },
      }
    );
  }

  useEffect(() => {
    if (isUpdate()) {
      setName(editRole.name);
      setTitle(editRole.title);
      setPriority(editRole.priority);

      openModal();
    }
  }, [editRole]);

  return (
    <>
      <Button className={styles["role-add-button"]} onClick={openModal}>
        <Icon type="sr-plus" />
        <span>افزودن نقش جدید</span>
      </Button>
      <Modal
        title="افزودن نقش جدید"
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        width={650}
        footer={() => (
          <>
            <Button className={styles["filter-button"]} onClick={closeModal}>
              لغو
            </Button>
            <Button
              className={styles["filter-button"]}
              type="primary"
              onClick={mutateRole}
              loading={isPending}
            >
              {isUpdate() ? "ویرایش نقش" : "ایجاد نقش"}
            </Button>
          </>
        )}
      >
        <Flex vertical gap={25} className={styles["modal-body"]}>
          <label>
            <Flex align="center" justify="space-between">
              <Typography.Text className={styles["input-label"]}>
                :نام نقش*
              </Typography.Text>
              <Input
                variant="filled"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Flex>
          </label>

          <label>
            <Flex align="center" justify="space-between">
              <Typography.Text className={styles["input-label"]}>
                :عنوان نقش*
              </Typography.Text>
              <Input
                variant="filled"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Flex>
          </label>

          <label>
            <Flex align="center" justify="space-between">
              <Typography.Text className={styles["input-label"]}>
                :سطح نقش*
              </Typography.Text>
              <Input
                className={styles["select-input"]}
                value={priority}
                type="number"
                min="0"
                placeholder="اولویت را وارد کنید  "
                onChange={(e) => setPriority(e.target.value)}
                variant="filled"
              />
            </Flex>
          </label>
        </Flex>
      </Modal>
    </>
  );
};

export default RoleManagementAddRoleModal;
