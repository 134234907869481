import { useMemo } from "react";
import { Flex, Table } from "antd";
import { StatusLabel, ActionButton } from "ui";
import { useAppStore } from "store";
import { useMagHomeManagementStore } from "pages/mag-home-management/store";
import {
  useDeleteCategoryLine,
  useGetCategoryLines,
} from "pages/mag-home-management/api";
import { MAG_HOME_CATEGORY_LINES_COLUMN } from "pages/mag-home-management/constant";
import { categoryLine } from "pages/mag-home-management/type";

const CategoryLinesTable = () => {
  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setFormData, setIsEdit } = useMagHomeManagementStore(
    (state) => state
  );

  const { data, isPending } = useGetCategoryLines();
  const { mutate: deleteCategoryLineMutation } = useDeleteCategoryLine();

  const dataSource = useMemo(() => {
    return (
      (data?.data ?? [])
        .sort(
          (i1: categoryLine, i2: categoryLine) =>
            (i1.line ?? 0) - (i2.line ?? 0)
        )
        .map((item: categoryLine, index: number) => {
          return {
            key: item.id,
            index: (
              <strong className="color-primary text-center">{index + 1}</strong>
            ),
            title: (
              <Flex vertical align="start" gap="5px">
                <strong className="color-primary text-center w-100">
                  {item?.title ?? "-"}
                </strong>
              </Flex>
            ),
            category: (
              <label className="color-primary text-center">
                {item?.articleCategoryTitle ?? "-"}
              </label>
            ),
            line: (
              <strong className="color-primary text-center">
                {item?.line ?? "0"}
              </strong>
            ),
            priority: (
              <strong className="color-primary text-center">
                {item?.priority ?? "0"}
              </strong>
            ),
            status: (
              <Flex align="center" justify="center">
                <StatusLabel status={item.isActive} />
              </Flex>
            ),
            action: (
              <Flex gap={8} justify="center">
                <ActionButton
                  type="remove"
                  onClick={() => deleteCategoryLineMutation(item.id!)}
                />
                <ActionButton
                  type="edit"
                  onClick={() => {
                    setFormData(item);
                    setIsEdit(true);
                    setModalVisibility("MAG_HOME_PAGE_CATEGROY_LINE_MODAL");
                  }}
                />
              </Flex>
            ),
          };
        }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={MAG_HOME_CATEGORY_LINES_COLUMN}
      dataSource={dataSource}
      loading={isPending}
      pagination={false}
    />
  );
};

export default CategoryLinesTable;
