import {
  InvalidateQueryFilters,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";

type deleteSectionParams = {
  id: number;
  articleId: number;
};

type QueryOptionsWithoutMutationFn = Omit<
  UseMutationOptions<
    unknown,
    AxiosError<ErrorDTO>,
    deleteSectionParams,
    unknown
  >,
  "mutationFn"
>;

export const useDeleteSection = (options?: QueryOptionsWithoutMutationFn) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError<ErrorDTO>, deleteSectionParams>({
    retry: 0,
    ...options,
    mutationFn: async (params: deleteSectionParams) => {
      const result = await instance.delete(
        `${ARTICLE_END_POINTS.delete_section}?articleId=${params.articleId}&id=${params.id}`
      );

      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        ARTICLE_END_POINTS.list_sections,
      ] as InvalidateQueryFilters);
      toast.success(`سکشن مقاله با موفقیت حذف گردید`);
    },
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data?.Message || "خطا در حذف سکشن مقاله"}`);
    },
  });
};
