import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance from "core/constants/request";
import { RequestDTO, ResponseDTO } from "interface";
import { AUTHOR_END_POINTS } from "core/constants/endpoints";
import { author, authorsManagementFilter } from "../type";

type authorsRequestType = RequestDTO<authorsManagementFilter>;
const getAuthors = async (
  authorsRequestType: authorsRequestType
): Promise<ResponseDTO<author[]>> => {
  const res = await instance.post(AUTHOR_END_POINTS.grid, authorsRequestType);

  return res.data;
};

type UseListAuthorsOptions = UseQueryOptions<
  ResponseDTO<author[]>,
  unknown,
  ResponseDTO<author[]>
>;
export const useListAuthors = (
  params: authorsRequestType,
  config?: UseListAuthorsOptions
) => {
  return useQuery({
    queryKey: [
      AUTHOR_END_POINTS.grid,
      params.page,
      params.pageSize,
      ...(params.inputParams.filters ? params.inputParams.filters : []),
    ],
    queryFn: () => getAuthors(params),
    ...config,
  });
};
