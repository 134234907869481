import { Field, Formik, FieldProps } from "formik";
import { Input, Switch, Form, Space, Upload, Flex } from "antd";
import { DatePickerFormik, Select } from "ui";
import IconCamera from "components/icons/iconCamera";

interface MenuForm {}
export const AdvertiseForm: React.FC<MenuForm> = () => {
  const [form] = Form.useForm<any>();
  const initialValues = {
    name: undefined,
    adsPage: undefined,
    url: undefined,
    status: true,
  };

  return (
    <Formik<any>
      onSubmit={(values) => {}}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form form={form}>
          <Form.Item
            name="avatar"
            valuePropName="avatar"
            labelAlign="left"
            wrapperCol={{ span: 24 }}
          >
            <Space className="w-100" direction="vertical" size="middle">
              <label>
                <span> تصویر تبلیغ</span>
                <span className="text-danger"> سایز تصویر 630x252 </span>
              </label>
              <Field name="avatar">
                {({ field }: FieldProps) => (
                  <Upload
                    {...field}
                    listType="picture-card"
                    showUploadList={false}
                    className={"upload-input-w-100"}
                    customRequest={(ops) => {
                      ops.onSuccess?.(<></>);
                    }}
                  >
                    <IconCamera />
                  </Upload>
                )}
              </Field>
              <label>
                <span className="color-stroke">
                  {" "}
                  انواع فایل های مجاز برای آپلود: png, webp, jpg, jpeg.&nbsp;
                </span>
                <span className="text-danger">
                  حجم فایل حداکثر 150 کیلوبایت
                </span>
              </label>
            </Space>
          </Form.Item>
          <Form.Item
            required
            name="name"
            label="نام شرکت"
            labelCol={{ span: 5 }}
            labelAlign="left"
            wrapperCol={{ span: 22 }}
          >
            <Field name="name">
              {({ field }: FieldProps) => (
                <Select
                  {...field}
                  allowClear
                  placeholder="جستجو در لیست یا اضافه کردن شرکت جدید"
                  onChange={(value) => setFieldValue("name", value)}
                  options={[
                    {
                      label: "صفحه ی لندینگ آنتی اسکالانت سمت راست",
                      value: "1",
                    },
                  ]}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="adsPage"
            label="صفحه ی تبلیغ"
            labelCol={{ span: 5 }}
            required
            labelAlign="left"
            wrapperCol={{ span: 22 }}
          >
            <Field name="adsPage">
              {({ field }: FieldProps) => (
                <Select
                  {...field}
                  allowClear
                  onChange={(value) => setFieldValue("adsPage", value)}
                  options={[
                    {
                      label: "صفحه ی لندینگ آنتی اسکالانت سمت راست",
                      value: "1",
                    },
                  ]}
                />
              )}
            </Field>
          </Form.Item>

          <Form.Item
            name="url"
            label=":لینک محصول"
            labelAlign="left"
            labelCol={{ span: 5 }}
            required
            wrapperCol={{ span: 22 }}
          >
            <Field name="url">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  value={values.url}
                  placeholder="لینک صفحه ی محصول مورد نظر را وارد نمایید"
                  onChange={(value) => setFieldValue("url", value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="مهلت نشر"
            labelAlign="left"
            labelCol={{ span: 5 }}
            required
            wrapperCol={{ span: 22 }}
          >
            <Flex gap={10} justify="space-between">
              <Field name="startDate">
                {({ field }: FieldProps) => (
                  <DatePickerFormik label="از" {...field} />
                )}
              </Field>
              <Field name="endtDate">
                {({ field }: FieldProps) => (
                  <DatePickerFormik label="تا" {...field} />
                )}
              </Field>
            </Flex>
          </Form.Item>
          <Form.Item
            name="status"
            label="وضعیت"
            labelCol={{ span: 5 }}
            labelAlign="left"
            wrapperCol={{ span: 22 }}
          >
            <Field name="status">
              {({ field }: FieldProps) => (
                <Space>
                  <Switch
                    {...field}
                    value={values.status}
                    onChange={(value) => setFieldValue("status", value)}
                  />
                  <label className="color-stroke">
                    {values.status ? "فعال" : "غیرفعال"}
                  </label>
                </Space>
              )}
            </Field>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};
