import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Flex, Table } from "antd";
import { BrandAvatar, CopyButton, StatusLabel, ActionButton } from "ui";
import { toDate, toDateTime } from "core/utils/date-time";
import { getImageSrc } from "core/utils/json-utils";
import { useAppStore } from "store";
import { useAdsStore } from "pages/articles/store";
import { useDeleteAd, useGetAds } from "pages/articles/api";
import { ADS_TABLE_COLUMN } from "pages/articles/constant";
import { articleBanner } from "pages/articles/type";

const AdsTable = () => {
  const { id: articleId } = useParams();

  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setFormData, setIsEdit } = useAdsStore((state) => state);

  const { data, isPending } = useGetAds({
    queryKey: [{ articleId }],
  });
  const { mutate: deleteAdMutation } = useDeleteAd();

  const dataSource = useMemo(() => {
    return (
      (data?.data ?? []).map((item: articleBanner) => {
        return {
          key: item.id,
          image: (
            <BrandAvatar
              img={getImageSrc(item.image?.url, true)}
              label={item.name}
            />
          ),
          title: (
            <Flex vertical align="start" gap="5px">
              <strong className="color-primary">{item?.name ?? "-"}</strong>
              <label className="color-stroke">
                تاریخ ثبت: {toDateTime(item?.from)}
              </label>
            </Flex>
          ),
          date: (
            <Flex vertical gap="5px">
              <strong className="color-stroke">
                از تاریخ: {toDate(item?.from)}
              </strong>
              <strong className="color-stroke">
                تا تاریخ: {toDate(item?.to)}
              </strong>
            </Flex>
          ),
          status: (
            <Flex align="center" justify="center">
              <StatusLabel status={item.isActive} />
            </Flex>
          ),
          link: (
            <Flex justify="center">
              <CopyButton value={item.url ?? ""} />
            </Flex>
          ),
          action: (
            <Flex gap={8} justify="center">
              <ActionButton
                type="remove"
                onClick={() =>
                  deleteAdMutation({
                    id: item.id!,
                    articleId: Number(articleId),
                  })
                }
              />
              <ActionButton
                type="edit"
                onClick={() => {
                  setFormData(item);
                  setIsEdit(true);
                  setModalVisibility("ARTICLE_ADS_MODAL");
                }}
              />
            </Flex>
          ),
        };
      }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={ADS_TABLE_COLUMN}
      dataSource={dataSource}
      loading={isPending}
      pagination={false}
    />
  );
};

export default AdsTable;
