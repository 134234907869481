import { AlignType } from "rc-table/lib/interface";

export const ARTICLES_TABLE_COLUMN = [
  {
    title: "تصویر مقاله",
    dataIndex: "image",
    key: "image",
    // align: "center" as AlignType,
    width: "25%",
  },
  {
    title: "موضوع مقاله و تاریخ ثبت",
    dataIndex: "title",
    key: "title",
    // align: "center" as AlignType,
    width: "30%",
  },
  {
    title: "مهلت انتشار",
    dataIndex: "date",
    key: "date",
    align: "center" as AlignType,
    width: "25%",
  },
  {
    title: "متن و توضیحات",
    dataIndex: "description",
    key: "description",
    align: "center" as AlignType,
    width: "5%",
  },
  {
    title: "وضعیت",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "لینک",
    dataIndex: "link",
    key: "link",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
    width: "20%",
  },
];

export const ADS_TABLE_COLUMN = [
  {
    title: "تصویر بنر",
    dataIndex: "image",
    key: "image",
    // align: "center" as AlignType,
    width: "25%",
  },
  {
    title: "موضوع بنر و تاریخ ثبت",
    dataIndex: "title",
    key: "title",
    // align: "center" as AlignType,
    width: "30%",
  },
  {
    title: "مهلت انتشار",
    dataIndex: "date",
    key: "date",
    align: "center" as AlignType,
    width: "25%",
  },
  {
    title: "فعال بودن",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "لینک",
    dataIndex: "link",
    key: "link",
    align: "center" as AlignType,
    width: "20%",
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
    width: "20%",
  },
];
