import {
  InvalidateQueryFilters,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";

type QueryOptionsWithoutMutationFn = Omit<
  UseMutationOptions<unknown, AxiosError<ErrorDTO>, paramsType, unknown>,
  "mutationFn"
>;
type paramsType = {
  id: number;
  articleId: number;
};

export const useDeleteAd = (options?: QueryOptionsWithoutMutationFn) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError<ErrorDTO>, paramsType>({
    retry: 0,
    ...options,
    mutationFn: async (params: paramsType) => {
      const result = await instance.delete(
        `${ARTICLE_END_POINTS.delete_banner}?id=${params.id}&articleId=${params.articleId}`
      );

      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        ARTICLE_END_POINTS.list_banners,
      ] as InvalidateQueryFilters);
      toast.success(`تبلیغ با موفقیت حذف گردید`);
    },
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data?.Message || "خطا در حذف تبلیغ"}`);
    },
  });
};
