import ReversedRadiusDivider from "components/common/ReversedRadiusDivider";
import { ActionBar } from "./actionbar";
import HomeProductsTable from "./table";
import HomeProductsModal from "./modal";

import styles from "./index.module.scss";
const HomeProductsTab = () => {
  return (
    <>
      <ReversedRadiusDivider />
      <div className={styles.mag_home_products_container}>
        <ActionBar className="pt-10" />
        <HomeProductsTable />
        <HomeProductsModal />
      </div>
    </>
  );
};

export default HomeProductsTab;
