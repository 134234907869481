import { useRef } from "react";
import { Button } from "antd";
import { Modal } from "ui";
import { useMagHomeManagementStore } from "pages/mag-home-management/store";
import {
  useAddMagHomeVideo,
  useUpdateHomeVideo,
} from "pages/mag-home-management/api";
import VideoForm, { VideoFormRef } from "../form";
import { toast } from "react-toastify";

const VideosModal = () => {
  const { setFormData, formData, isEdit } = useMagHomeManagementStore(
    (state) => state
  );
  const updateVideoQuery = useUpdateHomeVideo();
  const addVideoQuery = useAddMagHomeVideo();
  const loading = isEdit ? updateVideoQuery.isPending : addVideoQuery.isPending;

  const formRef = useRef<VideoFormRef>(null);

  const handleOk = (onSuccess: () => void) => {
    const values = formRef.current?.getFormData();
    setFormData(values);

    if (isEdit) {
      updateVideoQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    } else {
      if (!values?.uploadVideo) {
        toast.error("لطفا ویدیو را انتخاب کنید");
        return;
      }
      addVideoQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    }
  };

  return (
    <Modal
      name="MAG_HOME_PAGE_VIDEO_MODAL"
      title={isEdit ? "ویرایش ویدیو" : "افزودن ویدیو جدید"}
      okButtonProps={{
        loading: loading,
      }}
      onClose={() => {
        setFormData(undefined);
        formRef.current?.resetForm();
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {isEdit ? "ویرایش" : "ثبت"}
          </Button>
        </>
      )}
    >
      <VideoForm ref={formRef} formData={formData} />
    </Modal>
  );
};
export default VideosModal;
