import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { TECH_SPEC_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { specType } from "../type";

type QueryOptionsWithoutQueryFn = Omit<UseQueryOptions<specType[]>, "queryFn">;
type QueryType = { productCategoryId?: number; search?: string };

export const useListTechnicalSpecifications = (
  options?: QueryOptionsWithoutQueryFn
) =>
  useQuery<specType[]>({
    retry: 0,
    ...(options ?? {}),
    queryFn: async ({ queryKey }) => {
      const [
        ,
        { productCategoryId, search } = {
          productCategoryId: undefined,
          search: undefined,
        },
      ] = queryKey as QueryType[];

      let query = "";
      if (productCategoryId) query = `categoryId=${productCategoryId}&`;
      if (search) query = `name=${search}&`;

      const result = await instance.get(`${TECH_SPEC_ENDPOINTS.list}?${query}`);

      return result.data?.data ?? {};
    },
    queryKey: options?.queryKey
      ? [TECH_SPEC_ENDPOINTS.list, ...options.queryKey]
      : [TECH_SPEC_ENDPOINTS.list],
  });
