import { useRef } from "react";
import { Card } from "antd";
import { toast } from "react-toastify";
import { Button, Icon } from "ui";
import { useAddSection } from "pages/articles/api";
import SectionForm, { SectionFormRef } from "../../form";
import SectionsTable from "../../table";
import SectionsModal from "../../modal";

import styles from "./index.module.scss";
const BaseTab = () => {
  const formRef = useRef<SectionFormRef>(null);

  const { mutate } = useAddSection();

  const addSection = () => {
    const values = formRef.current?.getFormData();

    if (!values?.uploadImage) {
      toast.error("لطفا تصویر را مشخص کنید");
      return;
    }

    mutate(
      {
        data: values!,
      },
      {
        onSuccess: () => {
          formRef.current?.resetForm();
        },
      }
    );
  };

  return (
    <>
      <Card bordered={false}>
        <SectionForm ref={formRef} />
        <Button className={styles.form_button} onClick={() => addSection()}>
          <Icon type="sr-plus" />
          <span>افزودن سر تیتر و توضیحات بیشتر</span>
        </Button>
      </Card>
      <SectionsTable />
      <SectionsModal />
    </>
  );
};

export default BaseTab;
