import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ConfigProvider, Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { SIDEBAR_ITEMS } from "./constant/sidebar-items";
import { removeAccessToken, removeRefreshToken } from "core/utils/auth-utils";

const Aside = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const onExitClick = () => {
    removeAccessToken();
    removeRefreshToken();

    navigate("/auth");
  };

  useEffect(() => {
    const path = location.pathname.split("/").filter((i) => i);
    setOpenKeys(path.map((_, index) => path.slice(0, index + 1).join("/")));
    setSelectedKeys([location.pathname]);
  }, [location]);

  const onMenuClick = (e) => {
    setSelectedKeys([e.key]);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              subMenuItemBg: "#fff",
              itemSelectedBg: "#F9F9F9",
              itemHoverBg: "#F9F9F9",
              itemColor: "#B5B5C3",
            },
          },
        }}
      >
        <Menu
          style={{
            paddingRight: "20px",
            fontWeight: "500",
            width: "280px",
            border: "none",
          }}
          onClick={onMenuClick}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={selectedKeys}
          mode="inline"
          items={[
            ...SIDEBAR_ITEMS,
            {
              key: "logout",
              label: (
                <div style={{ marginRight: "13px" }} onClick={onExitClick}>
                  خروج
                </div>
              ),
              icon: <LogoutOutlined />,
            },
          ]}
        />
      </ConfigProvider>
    </div>
  );
};

export default Aside;
