import { useMemo, useState } from "react";
import { Button, Flex, Table } from "antd";
import { BrandAvatar, CopyButton, StatusLabel, ActionButton } from "ui";
import { useAppStore } from "store";
import { useBrandManagementStore } from "../../store";
import { useDeleteBrand, useGetBrands } from "../../api";
import { BRAND_MANAGEMENT_COLUMN } from "../../constant";
import { getImageSrc } from "core/utils/json-utils";
import { IconEye } from "components/icons/iconEye";

const rowSelection = {
  onChange: () => {},
};

const BrandManagementTable = () => {
  const [page, setPage] = useState(1);

  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setFormData, setIsEdit, filter } = useBrandManagementStore(
    (state) => state
  );

  const { data, isPending } = useGetBrands({
    page: page,
    pageSize: 10,
    inputParams: {
      filters: filter,
      sort: {
        propertyName: "id",
        ascending: false,
      },
    },
  });
  const { mutate: deleteBrandMutation } = useDeleteBrand();

  const dataSource = useMemo(() => {
    return (
      data?.data.data.map((item) => {
        return {
          key: item.id,
          name: (
            <Flex gap={16} align="end" className="w-100">
              <BrandAvatar
                img={getImageSrc(item.logo?.url, true)}
                label={item.name}
              />
              <Flex vertical align="start">
                <label className="color-primary w-100">
                  {item?.name ?? "-"}
                </label>
                <label className="color-stroke">
                  {item?.identifier ?? "بدون شناسه"}
                </label>
              </Flex>
            </Flex>
          ),
          link: (
            <Button
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_PUBLISH_WEBSITE_BASE_URL}${item?.localLink}`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              disabled={(item?.localLink ?? "").length == 0}
            >
              <IconEye /> مشاهده
            </Button>
          ),
          status: (
            <Flex align="center" justify="center">
              <StatusLabel status={item.isActive} />
            </Flex>
          ),
          action: (
            <Flex gap={8} justify="center">
              <ActionButton
                type="remove"
                onClick={() => deleteBrandMutation(item.id)}
              />
              <ActionButton
                type="edit"
                onClick={() => {
                  setFormData(item);
                  setIsEdit(true);
                  setModalVisibility("BRAND_MANAGEMENT_MODAL");
                }}
              />
            </Flex>
          ),
        };
      }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={BRAND_MANAGEMENT_COLUMN}
      dataSource={dataSource}
      rowSelection={rowSelection}
      loading={isPending}
      pagination={{
        showSizeChanger: false,
        total: data?.data?.totals ?? 0,
        onChange: setPage,
      }}
    />
  );
};

export default BrandManagementTable;
