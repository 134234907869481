import { useMemo, useState } from "react";
import { Flex, Table } from "antd";
import { ActionButton } from "ui";
import { useAppStore } from "store";
import {
  useGetTechnicalSpecificationsGrid,
  useDeleteTechnicalSpecification,
} from "../../api";
import { useSpecsListStore } from "../../store";
import { SPECS_LIST_COLUMN } from "../../constant";
import { specType } from "../../type";

const SpecsListTable = () => {
  const [page, setPage] = useState<number>(1);

  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setFormData, setIsEdit, filter } = useSpecsListStore(
    (state) => state
  );

  const { data, isPending } = useGetTechnicalSpecificationsGrid({
    page: page,
    pageSize: 10,
    inputParams: {
      filters: filter,
      sort: {
        propertyName: "id",
        ascending: false,
      },
    },
  });
  const { mutate: deleteSpecMutation } = useDeleteTechnicalSpecification();

  const dataSource = useMemo(() => {
    return (
      (data?.data?.data ?? []).map((item: specType, index: number) => {
        return {
          key: item.id,
          index: (
            <Flex gap={16} align="center" justify="center">
              <label className="color-primary">{item?.id}</label>
            </Flex>
          ),
          name: <strong className="color-link">{item?.name ?? "ندارد"}</strong>,
          description: (
            <label className="color-primary">{item?.description ?? "-"}</label>
          ),
          action: (
            <Flex gap={8} justify="center">
              <ActionButton
                type="remove"
                onClick={() => deleteSpecMutation({ id: item.id })}
              />
              <ActionButton
                type="edit"
                onClick={() => {
                  setFormData(item);
                  setIsEdit(true);
                  setModalVisibility("SPECS_MANAGEMENT_MODAL");
                }}
              />
            </Flex>
          ),
        };
      }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={SPECS_LIST_COLUMN}
      dataSource={dataSource}
      loading={isPending}
      pagination={{
        showSizeChanger: false,
        total: data?.data?.totals ?? 0,
        onChange: (page) => {
          setPage(page);
        },
      }}
    />
  );
};

export default SpecsListTable;
