import { useRef } from "react";
import { Button } from "antd";
import { toast } from "react-toastify";
import { Modal } from "ui";
import { useSectionsStore } from "pages/articles/store";
import { useAddSection, useUpdateSection } from "pages/articles/api";
import SectionForm, { SectionFormRef } from "../form";
import { articleForm } from "pages/articles/type";

const SectionsModal = () => {
  const { setFormData, formData, isEdit } = useSectionsStore((state) => state);
  const updateAdQuery = useUpdateSection();
  const addAdQuery = useAddSection();
  const loading = isEdit ? updateAdQuery.isPending : addAdQuery.isPending;

  const formRef = useRef<SectionFormRef>(null);

  const handleOk = (onSuccess: () => void) => {
    const values = formRef.current?.getFormData();
    setFormData(values as articleForm);

    if (isEdit) {
      updateAdQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    } else {
      if (!values?.uploadImage) {
        toast.error("لطفا تصویر را مشخص کنید");
        return;
      }

      addAdQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    }
  };

  return (
    <Modal
      name="ARTICLE_SECTION_MODAL"
      title={isEdit ? "اصلاح تیتر و تصویر" : "افزودن تیتر و تصویر"}
      width="80%"
      okButtonProps={{
        loading: loading,
      }}
      onClose={() => {
        setFormData(undefined);
        formRef.current?.resetForm();
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {isEdit ? "ویرایش" : "ثبت"}
          </Button>
        </>
      )}
    >
      <SectionForm ref={formRef} formData={formData as any} />
    </Modal>
  );
};
export default SectionsModal;
