export const PRIORITIES_DATA = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
];

export const TEMPLATE_TIME_RANGES = [
  { label: "۱ ساعت", value: 1 },
  { label: "۳ ساعت", value: 3 },
  { label: "۱۲ ساعت", value: 12 },
  { label: "۱ روز", value: 24 },
  { label: "۲ روز", value: 48 },
  { label: "۳ روز", value: 72 },
  { label: "۱ هفته", value: 168 },
  { label: "۲ هفته", value: 336 },
  { label: "۳ هفته", value: 504 },
  { label: "۱ ماه", value: 672 },
];
