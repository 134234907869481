import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";

const updateArticle = async ({ data }: { data: any }) => {
  const update = {
    ...data,
    width: data.image?.width ?? 0,
    height: data.image?.height ?? "0",
    sizePercentage: data.image?.sizePercentage ?? "1",
  };
  const res = await instance.put(ARTICLE_END_POINTS.update, update);

  return res.data;
};

export const useUpdateArticle = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [ARTICLE_END_POINTS.update, ARTICLE_END_POINTS.list],
    mutationFn: updateArticle,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data.Message || "خطا در ویرایش مقاله"}`);
    },
    onSuccess: () => {
      toast.success(`مقاله با موفقیت ویرایش شد`);
      queryClient.invalidateQueries({
        queryKey: [ARTICLE_END_POINTS.list],
      });
    },
  });
};
