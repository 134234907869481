import { useRef } from "react";
import { Button } from "antd";
import { Modal } from "ui";
import { useMagHomeManagementStore } from "pages/mag-home-management/store";
import {
  useAddHomeProduct,
  useUpdateHomeProduct,
} from "pages/mag-home-management/api";
import HomeProductForm, { HomeProductFormRef } from "../form";
import { toast } from "react-toastify";

const HomeProductsModal = () => {
  const { setFormData, formData, isEdit } = useMagHomeManagementStore(
    (state) => state
  );
  const updateHomeProductQuery = useUpdateHomeProduct();
  const addHomeProductQuery = useAddHomeProduct();
  const loading = isEdit
    ? updateHomeProductQuery.isPending
    : addHomeProductQuery.isPending;

  const formRef = useRef<HomeProductFormRef>(null);

  const handleOk = (onSuccess: () => void) => {
    const values = formRef.current?.getFormData();
    setFormData(values);

    if (isEdit) {
      updateHomeProductQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    } else {
      addHomeProductQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    }
  };

  return (
    <Modal
      name="MAG_HOME_PAGE_PRODUCT_MODAL"
      title={isEdit ? "ویرایش محصول صفحه اصلی" : "ایجاد محصول صفحه اصلی"}
      okButtonProps={{
        loading: loading,
      }}
      onClose={() => {
        setFormData(undefined);
        formRef.current?.resetForm();
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {isEdit ? "ویرایش" : "ثبت"}
          </Button>
        </>
      )}
    >
      <HomeProductForm ref={formRef} formData={formData} />
    </Modal>
  );
};
export default HomeProductsModal;
