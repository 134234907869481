import { useRef, useState } from "react";
import { Button, Card, Col, Row } from "antd";
import { Icon } from "ui";
import { BannerImage } from "ui/banner-input";
import { isObjectEmpty } from "core/utils/json-utils";
import { getRand } from "core/utils/common-utils";
import { useAddBestOffer } from "../api";
import ImagePicker from "./image-picker";
import BestOfferForm, { BestOfferFormRef } from "./form";
import BestOfferModal from "./modal";
import OffersTable from "./table";

import styles from "./index.module.scss";
import ReversedRadiusDivider from "components/common/ReversedRadiusDivider";
export const BestOffer = () => {
  const [selectedImage, setSelectedImage] = useState<BannerImage | undefined>();
  const [refreshToken, setRefreshToken] = useState<string>("");

  const { mutate: createBestOffer, isPending: creatingBestOffer } =
    useAddBestOffer();

  const formRef = useRef<BestOfferFormRef>(null);

  const reset = () => {
    formRef?.current?.resetForm();
    setSelectedImage(undefined);
    setRefreshToken(String(getRand()));
  };

  const addOffer = () => {
    if (!formRef.current) return;

    // Get validation and form values
    const { validation, values } = formRef.current.getFormData();
    validation.then((result) => {
      // If no errors, then data is valid
      if (isObjectEmpty(result)) {
        createBestOffer(
          {
            data: { ...values, selectedImage },
          },
          {
            onSuccess: reset,
          }
        );
      }
    });
  };

  return (
    <>
      <ReversedRadiusDivider />
      <div className={styles.best_offer_container}>
        <Row gutter={22}>
          <Col span={7}>
            <ImagePicker
              key={refreshToken}
              currentImage={selectedImage}
              onChange={(image) => setSelectedImage(image)}
            />
          </Col>
          <Col span={17}>
            <Card bordered={false} className="w-100">
              <strong>نوع محصول</strong>
              <div className="mt-7">
                <BestOfferForm ref={formRef} />
                <Button
                  className={styles.form_button}
                  loading={creatingBestOffer}
                  onClick={() => addOffer()}
                >
                  <Icon type="sr-plus" />
                  <span>افزودن</span>
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mt-7">
          <Card bordered={false} className="w-100">
            <strong>لیست محصولات پیشنهاد ویژه</strong>
            <div className="mt-7">
              <OffersTable />
            </div>
          </Card>
        </Row>
      </div>
      <BestOfferModal />
    </>
  );
};
