import { create } from "zustand";

type bestOfferStore = {
  formData?: any;
  setFormData: (formData?: any) => void;
};

export const useBestOfferStore = create<bestOfferStore>((set) => {
  return {
    setFormData: (value: any | undefined) => {
      set({ formData: value });
    },
  };
});
