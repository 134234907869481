import { $createCodeNode } from "@lexical/code";
import {
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text";
import { $wrapNodes } from "@lexical/selection";
import classNames from "classnames";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
} from "lexical";
import { useEffect, useRef } from "react";
import {
  blockTypeToBlockName,
  blockTypeToBlockNamePersian,
} from "ui/lexical-editor/context/ToolbarContext";
import { SHORTCUTS } from "ui/lexical-editor/plugins/ShortcutsPlugin/shortcuts";
import { formatHeading } from "ui/lexical-editor/utils/fontUtils";

function dropDownActiveClass(active) {
  if (active) {
    return "active dropdown-item-active";
  } else {
    return "";
  }
}

const BlockOptionsDropdownList = ({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
}) => {
  const dropDownRef = useRef(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      // dropDown.style.top = `${top + 40}px`;
      dropDown.style.top = "40px";
      dropDown.style.right = `${left}px`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event) => {
        const target = event.target;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener("click", handle);

      return () => {
        document.removeEventListener("click", handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatSelectedHeading = (element = "h1") => {
    formatHeading(editor, blockType, element);
    setShowBlockOptionsDropDown(false);
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <div className="dropdown" ref={dropDownRef}>
      <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "paragraph"),
        ])}
        onClick={formatParagraph}
      >
        <span className="icon paragraph" />
        <span className="text">پاراگراف</span>
        {blockType === "paragraph" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.NORMAL}</span>
      </button>
      <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "h1"),
        ])}
        onClick={() => formatSelectedHeading("h1")}
      >
        <span className="icon h1" />
        <span className="text">{blockTypeToBlockNamePersian.h1}</span>
        {blockType === "h1" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.HEADING1}</span>
      </button>
      <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "h2"),
        ])}
        onClick={() => formatSelectedHeading("h2")}
      >
        <span className="icon h2" />
        <span className="text">{blockTypeToBlockNamePersian.h2}</span>
        {blockType === "h2" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.HEADING2}</span>
      </button>
      <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "h3"),
        ])}
        onClick={() => formatSelectedHeading("h3")}
      >
        <span className="icon h3" />
        <span className="text">{blockTypeToBlockNamePersian.h3}</span>
        {blockType === "h3" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.HEADING3}</span>
      </button>
      <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "h4"),
        ])}
        onClick={() => formatSelectedHeading("h4")}
      >
        <span className="icon h4" />
        <span className="text">{blockTypeToBlockNamePersian.h4}</span>
        {blockType === "h4" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.HEADING4}</span>
      </button>
      <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "h5"),
        ])}
        onClick={() => formatSelectedHeading("h5")}
      >
        <span className="icon h5" />
        <span className="text">{blockTypeToBlockNamePersian.h5}</span>
        {blockType === "h5" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.HEADING5}</span>
      </button>
      <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "h6"),
        ])}
        onClick={() => formatSelectedHeading("h6")}
      >
        <span className="icon h6" />
        <span className="text">{blockTypeToBlockNamePersian.h6}</span>
        {blockType === "h6" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.HEADING6}</span>
      </button>
      <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "ul"),
        ])}
        onClick={formatBulletList}
      >
        <span className="icon bullet-list" />
        <span className="text">لبست بولت</span>
        {blockType === "ul" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.BULLET_LIST}</span>
      </button>
      <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "ol"),
        ])}
        onClick={formatNumberedList}
      >
        <span className="icon numbered-list" />
        <span className="text">لیست عددی</span>
        {blockType === "ol" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.NUMBERED_LIST}</span>
      </button>
      {/* <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "check"),
        ])}
        onClick={formatCheckList}
      >
        <span className="icon check-list" />
        <span className="text">چک لیست</span>
        {blockType === "check" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.CHECK_LIST}</span>
      </button> */}
      <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "quote"),
        ])}
        onClick={formatQuote}
      >
        <span className="icon quote" />
        <span className="text">نقل قول</span>
        {blockType === "quote" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.QUOTE}</span>
      </button>
      <button
        className={classNames([
          "item",
          dropDownActiveClass(blockType === "code"),
        ])}
        onClick={formatCode}
      >
        <span className="icon code" />
        <span className="text">قطعه کد</span>
        {blockType === "code" && <span className="active" />}
        <span className="shortcut">{SHORTCUTS.CODE_BLOCK}</span>
      </button>
    </div>
  );
};

export default BlockOptionsDropdownList;
