import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { articleLineForm } from "../../type";

const updateArticleLine = async ({ data }: { data: articleLineForm }) => {
  const res = await instance.put(MAG_HOME_END_POINTS.update_article_line, data);

  return res.data;
};

export const useUpdateArticleLine = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      MAG_HOME_END_POINTS.update_article_line,
      MAG_HOME_END_POINTS.article_lines_list,
    ],
    mutationFn: updateArticleLine,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data.Message || "خطا در ویرایش لاین مقاله"}`);
    },
    onSuccess: () => {
      toast.success(`لاین مقاله با موفقیت ویرایش شد`);
      queryClient.invalidateQueries({
        queryKey: [MAG_HOME_END_POINTS.article_lines_list],
      });
    },
  });
};
