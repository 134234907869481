import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ConfigProvider, Tabs } from "antd";
import { useAppStore } from "store";
import { useGetArticleDetails } from "pages/articles/api";
import BaseTab from "./tabs/base-tab";
import AdsTab from "./tabs/ads-tab";
import SeoTab from "./tabs/seo-tab";

import styles from "./index.module.scss";

const items = [
  {
    key: "articles_list",
    label: "اطلاعات پایه",
    children: <BaseTab />,
  },
  {
    key: "articles_videos",
    label: "بنر ها",
    children: <AdsTab />,
  },
  {
    key: "articles_ads",
    label: "سئوی مقاله",
    children: <SeoTab />,
  },
];

export const ArticleSections = () => {
  const { id: articleId } = useParams();
  const { data } = useGetArticleDetails({ queryKey: [{ id: articleId }] });

  const { addBreadcrumbId, reset } = useAppStore();

  useEffect(() => {
    addBreadcrumbId({ id: Number(articleId), title: data?.title });

    return () => {
      reset();
    };
  }, [data]);

  return (
    <div className={styles.article_sections_container}>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerSplitColor: "transparent",
            },
          },
        }}
      >
        <Tabs
          defaultActiveKey="articles_content"
          items={items}
          className={styles.articles_tabs}
        />
      </ConfigProvider>
    </div>
  );
};
