import { toast } from "react-toastify";
import { AxiosError } from "axios";
import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { ARTICLE_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { articleSection } from "pages/articles/type";

const addSection = async ({ data }: { data: articleSection }) => {
  const formData = new FormData();
  formData.append("AltText", data.title ?? "");
  formData.append("Title", data.title ?? "");
  formData.append("SubTitle", data.subTitle ?? "");
  formData.append("Content", data.content ?? "");
  formData.append("ArticleId", String(data.articleId ?? 0));
  if (data.uploadImage) {
    formData.append("Width", String(data.uploadImage.width));
    formData.append("Height", String(data.uploadImage.height));
    formData.append("SizePercentage", "100");
    formData.append("File", data.uploadImage.file ?? "");
  }

  const res = await instance.post(ARTICLE_END_POINTS.add_section, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useAddSection = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      ARTICLE_END_POINTS.add_section,
      ARTICLE_END_POINTS.list_sections,
    ],
    mutationFn: addSection,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(`${e.response?.data.Message || "خطا در ایجاد سکشن مقاله"}`);
    },
    onSuccess: () => {
      toast.success(`سکشن مقاله با موفقیت افزوده شد`);
      queryClient.invalidateQueries([
        ARTICLE_END_POINTS.list_sections,
      ] as InvalidateQueryFilters);
    },
  });
};
