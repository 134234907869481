import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MAG_HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { homeProductForm } from "../../type";

const addMagHomeHomeProduct = async ({ data }: { data: homeProductForm }) => {
  const formData = new FormData();
  formData.append("Priority", String(data.priority ?? "0"));
  formData.append("ProductId", String(data.productId ?? 0));
  formData.append("IsActive", String(data.isActive ?? "false"));

  const res = await instance.post(
    MAG_HOME_END_POINTS.add_home_product,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  return res.data;
};

export const useAddHomeProduct = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      MAG_HOME_END_POINTS.add_home_product,
      MAG_HOME_END_POINTS.home_products_list,
    ],
    mutationFn: addMagHomeHomeProduct,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(
        `${e.response?.data.Message || "خطا در ایجاد محصول صفحه اصلی"}`
      );
    },
    onSuccess: () => {
      toast.success(`محصول صفحه اصلی با موفقیت افزوده شد`);
      queryClient.invalidateQueries({
        queryKey: [MAG_HOME_END_POINTS.home_products_list],
      });
    },
  });
};
