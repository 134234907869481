import React from "react";
import { Form, Flex } from "antd";
import { Button, Icon, SearchInput } from "ui";
import { useAppStore } from "store";
import { useAdsStore } from "pages/articles/store";
import { articleManagementFilter } from "pages/articles/type";

interface FormValues {
  search: string;
}
interface AdsActionBar {
  className?: string;
}

export const ActionBar: React.FC<AdsActionBar> = ({ className }) => {
  const { setModalVisibility } = useAppStore((state) => state);
  const { setFilter, setFormData, setIsEdit } = useAdsStore((state) => state);
  const [form] = Form.useForm<FormValues>();
  const onValuesChangeHandler = (
    changedValues: FormValues,
    allValues: FormValues
  ) => {
    setFilter(
      Object.entries(changedValues)
        .filter(([_, value]) => value.length > 0)
        .map(([key, value]) => {
          return {
            propertyName: key as articleManagementFilter,
            operation: "contains",
            value: value as string,
          };
        })
    );
  };
  return (
    <div className={`${className} box w-100`}>
      <strong>لیست بنر های مقاله</strong>
      <Flex gap={10} justify="space-between" className="mt-6">
        <Form
          form={form}
          className="w-100"
          onValuesChange={onValuesChangeHandler}
        >
          <Flex>
            <Form.Item name={"name"}>
              <SearchInput size="large" placeholder="جستجو " />
            </Form.Item>
          </Flex>
        </Form>
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setFormData(undefined);
            setIsEdit(false);
            setModalVisibility("ARTICLE_ADS_MODAL");
          }}
        >
          <Icon type="sr-plus" />
          <span>بنر جدید </span>
        </Button>
      </Flex>
    </div>
  );
};
